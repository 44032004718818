/* eslint-disable max-lines */
import { ONE_SECOND } from 'core/constants/constant';
import {
  EventTraceDto,
  InterlockDetail,
  MissionListItem,
  MissionTraceDto,
  ProcessChainTraceDto,
  StepDto,
  StepTraceDto,
  WaitForEventStepDto,
} from 'core/dtos';
import {
  EventType,
  MissionTrace,
  ProcessChainTraceModel,
  StepModel,
  StepTraceModel,
  StepType,
  StepTypeBackend,
} from 'core/models';
import { getStepType } from 'store-modules/mission-monitoring-store/selectors/mission-trace.selectors.helpers';
import { mapTourStepDtosToModels } from '../tour-step-dto-to-model.helper';

export function convertProcessChainTraceDtoToModel(
  processChainTraceDto: ProcessChainTraceDto
): ProcessChainTraceModel {
  const { eventTraces, ...remainingProperties } = processChainTraceDto;
  return {
    ...remainingProperties,
    missionTraces: mapMissionTraceDtosToModels(processChainTraceDto.missionTraces),
  };
}

export function mapMissionTraceDtosToModels(missionTraceDtos: MissionTraceDto[]): MissionTrace[] {
  return missionTraceDtos.map(convertMissionTraceDtoToModel);
}

export function convertMissionTraceDtoToModel(missionTraceDto: MissionTraceDto): MissionTrace {
  const { eventTraces, interlockDetails, ...remainingProperties } = missionTraceDto;
  const currentStepSequenceNumber = getSequenceNumberConsideringEvents(missionTraceDto);
  const stepTraces = mapStepTraceDtosToModels(missionTraceDto) ?? [];
  return {
    ...remainingProperties,
    provisioningTime: remainingProperties.provisioningTime ?? '',
    createdDateTime: remainingProperties.createdDateTime ?? '',
    forecastedEndTime: remainingProperties.forecastedEndTime ?? null,
    completedDateTime: remainingProperties.createdDateTime ?? '',
    updatedDateTime: remainingProperties.updatedDateTime,
    currentStepSequenceNumber: currentStepSequenceNumber,
    stepTraces: mapStepTraceDtosToModels(missionTraceDto),
    currentStepTypeFrontEnd: getFrontEndStepType(
      missionTraceDto.currentStepType,
      currentStepSequenceNumber,
      stepTraces
    ),
    hasManualSupport: missionTraceDto.hasManualSupport || false,
    status: missionTraceDto.status,
    deliveryStatus: missionTraceDto.deliveryStatus,
    tourSteps: mapTourStepDtosToModels(missionTraceDto.tourSteps),
    failureComment: missionTraceDto.failureComment,
    loadType: missionTraceDto.loadType,
    acknowledgeable: missionTraceDto.acknowledgeable,
  };
}
export function convertMissionListItemToModel(missionListItem: MissionListItem): MissionTrace {
  const { eventTraces, interlockDetails, ...remainingProperties } = missionListItem;
  const currentStepSequenceNumber = getSequenceNumberConsideringEvents(missionListItem);
  const stepTraces = missionListItem.stepTraces ?? []; // mapStepTraceDtosToModels(missionListItem) ?? [];
  return {
    ...remainingProperties,
    provisioningTime: remainingProperties.provisioningTime ?? '',
    createdDateTime: remainingProperties.createdDateTime ?? '',
    forecastedEndTime: remainingProperties.forecastedEndTime ?? null,
    completedDateTime: remainingProperties.createdDateTime ?? '',
    updatedDateTime: remainingProperties.updatedDateTime,
    currentStepSequenceNumber: currentStepSequenceNumber,
    stepTraces,
    currentStepTypeFrontEnd: getFrontEndStepType(
      missionListItem.currentStepType,
      currentStepSequenceNumber,
      stepTraces
    ),
    hasManualSupport: missionListItem.hasManualSupport,
    status: missionListItem.status,
    deliveryStatus: missionListItem.deliveryStatus,
    tourSteps: mapTourStepDtosToModels(missionListItem.tourSteps),
    failureComment: missionListItem.failureComment,
    loadType: missionListItem.loadType,
    tourChainId: missionListItem.tourChainId,
    tourChainName: missionListItem.tourChainName,
    acknowledgeable: missionListItem.acknowledgeable,
  };
}
export function getFrontEndStepType(
  currentStepType: StepTypeBackend | undefined,
  currentStepSequenceNumber: number,
  stepTraces: StepTraceModel[]
): StepType {
  if (currentStepType === StepTypeBackend.RaiseEvent || currentStepType === undefined) {
    return getStepType(currentStepSequenceNumber, stepTraces);
  }

  return convertBackendStepTypeToFrontEndStepType(currentStepType);
}
export function getSequenceNumberConsideringEvents(
  missionTrace: MissionTraceDto | MissionListItem
): number {
  if (missionTrace.currentStepSequenceNumber === -1) {
    return -1;
  }

  let index = -1;
  const sequenceMapping = {};
  if (missionTrace.stepTraces) {
    for (const stepTrace of missionTrace.stepTraces) {
      if (stepTrace.sourceStep.type === StepTypeBackend.RaiseEvent) {
        sequenceMapping[stepTrace.sourceStep.sequenceNumber] = index;
      } else {
        sequenceMapping[stepTrace.sourceStep.sequenceNumber] = ++index;
      }
    }
  }
  return (
    sequenceMapping[missionTrace.currentStepSequenceNumber] ??
    missionTrace.currentStepSequenceNumber
  );
}
export function mapStepTraceDtosToModels(missionTraceDto: MissionTraceDto): StepTraceModel[] {
  const filteredStepTraceDtos = missionTraceDto.stepTraces?.filter(
    stepTraceDto => stepTraceDto.sourceStep.type !== StepTypeBackend.RaiseEvent
  );

  let seqNum = 0;
  const updatedStepTraceDtos = filteredStepTraceDtos?.map(stepTraceDto => {
    return {
      succeeded: stepTraceDto.succeeded,
      sourceStep: {
        ...stepTraceDto.sourceStep,
        sequenceNumber: seqNum++,
      },
      startedDateTime: stepTraceDto.startedDateTime,
      stoppedDateTime: stepTraceDto.stoppedDateTime,
      attributeText: stepTraceDto.attributeText,
    };
  });

  return updatedStepTraceDtos?.map(stepTraceDto =>
    convertStepTraceToStepTraceModel(stepTraceDto, missionTraceDto)
  );
}

export function convertStepTraceToStepTraceModel(
  stepTraceDto: StepTraceDto | StepTraceDto,
  missionTraceDto: MissionTraceDto | MissionListItem
): StepTraceModel {
  return {
    sourceStep: convertStepDtoToStepModel(
      stepTraceDto.sourceStep,
      missionTraceDto.eventTraces,
      missionTraceDto.interlockDetails
    ),
    succeeded: stepTraceDto.succeeded,
    startedDateTime: stepTraceDto.startedDateTime,
    stoppedDateTime: stepTraceDto.stoppedDateTime,
    attributeText: stepTraceDto.attributeText,
  };
}

export function convertBackendStepTypeToFrontEndStepType(stepType: StepTypeBackend): StepType {
  switch (stepType) {
    case StepTypeBackend.Dock:
      return StepType.Dock;
    case StepTypeBackend.DockToCharge:
      return StepType.DockToCharge;
    case StepTypeBackend.DockToMapping:
      return StepType.DockToMapping;
    case StepTypeBackend.Drop:
      return StepType.Drop;
    case StepTypeBackend.GoToMapping:
      return StepType.GoToMapping;
    case StepTypeBackend.Goto:
      return StepType.Goto;
    case StepTypeBackend.Lift:
      return StepType.Lift;
    case StepTypeBackend.StartToCharge:
      return StepType.StartToCharge;
    case StepTypeBackend.StopToCharge:
      return StepType.StopToCharge;
    case StepTypeBackend.WaitForSideButton:
      return StepType.WaitForSideButton;
    case StepTypeBackend.Wait:
      return StepType.Wait;
    case StepTypeBackend.WaitForDevice:
      return StepType.WaitForDevice;
    case StepTypeBackend.WaitForScanDestination:
      return StepType.WaitForScanDestination;
    case StepTypeBackend.WaitForSapAcknowledgement:
      return StepType.WaitForSapAcknowledgement;
    case StepTypeBackend.TurnDolly:
      return StepType.TurnDolly;
    case StepTypeBackend.GotoAndPushSideButton:
      return StepType.GotoAndPushSideButton;
    case StepTypeBackend.GotoPose:
      return StepType.GotoPose;
    case StepTypeBackend.WaitForEvent:
      return StepType.WaitForEndOfStep;
    case StepTypeBackend.Deliver:
      return StepType.Deliver;
    case StepTypeBackend.DeliverToMapping:
      return StepType.DeliverToMapping;
    case StepTypeBackend.PrepareForLift:
      return StepType.PrepareForLift;
    default:
      return StepType.Unknown;
  }
}

export function convertStepDtoToStepModel(
  stepDto: StepDto,
  eventTraces: EventTraceDto[],
  interlockDetails: InterlockDetail[]
): StepModel {
  switch (stepDto.type) {
    case StepTypeBackend.Dock:
      return { ...stepDto, type: StepType.Dock };
    case StepTypeBackend.DockToCharge:
      return { ...stepDto, type: StepType.DockToCharge };

    case StepTypeBackend.DockToMapping:
      return { ...stepDto, type: StepType.DockToMapping };
    case StepTypeBackend.Drop:
      return { ...stepDto, type: StepType.Drop };

    case StepTypeBackend.GoToMapping:
      return { ...stepDto, type: StepType.GoToMapping };

    case StepTypeBackend.Goto:
      return { ...stepDto, type: StepType.Goto };

    case StepTypeBackend.Lift:
      return { ...stepDto, type: StepType.Lift };

    case StepTypeBackend.StartToCharge:
      return { ...stepDto, type: StepType.StartToCharge };
    case StepTypeBackend.StopToCharge:
      return { ...stepDto, type: StepType.StopToCharge };
    case StepTypeBackend.WaitForSideButton:
      return { ...stepDto, type: StepType.WaitForSideButton };
    case StepTypeBackend.Wait:
      return { ...stepDto, type: StepType.Wait, timeSpanS: stepDto.timeSpanMillis / ONE_SECOND };
    case StepTypeBackend.WaitForEvent: {
      if (eventTraces.length === 0) {
        throw new Error(
          `Could not convert step. No event trace with id '${stepDto.eventId}' found`
        );
      }
      const eventTrace = eventTraces?.find(e => e.eventId === stepDto.eventId);
      const detail = interlockDetails?.find(d => d.eventId === eventTrace?.eventId);

      return convertToEventStepModel(stepDto, eventTrace, detail);
    }
    case StepTypeBackend.WaitForDevice:
      return { ...stepDto, type: StepType.WaitForDevice };
    case StepTypeBackend.WaitForScanDestination:
      return { ...stepDto, type: StepType.WaitForScanDestination };
    case StepTypeBackend.WaitForSapAcknowledgement:
      return { ...stepDto, type: StepType.WaitForSapAcknowledgement };
    case StepTypeBackend.TurnDolly:
      return { ...stepDto, type: StepType.TurnDolly };
    case StepTypeBackend.GotoAndPushSideButton:
      return { ...stepDto, type: StepType.GotoAndPushSideButton };
    case StepTypeBackend.GotoPose:
      return { ...stepDto, type: StepType.GotoPose };
    case StepTypeBackend.Deliver:
      return { ...stepDto, type: StepType.Deliver };
    case StepTypeBackend.DeliverToMapping:
      return { ...stepDto, type: StepType.DeliverToMapping };

    default:
      throw new Error('Invalid StepType.');
  }
}

export function convertToEventStepModel(
  step: WaitForEventStepDto,
  event?: EventTraceDto,
  interlockDetail?: InterlockDetail
): StepModel {
  if (event) {
    if (event.type === EventType.Process) {
      return {
        type: StepType.WaitForEndOfStep,
        sequenceNumber: step.sequenceNumber,
        waitForStep: {
          missionIndex: 0,
          sequenceNumber: interlockDetail?.frontendStepSequenceNumber ?? 0,
          missionId: interlockDetail?.raisingMissionId,
          stepAttribute: interlockDetail?.stepAttribute,
          stepMission: interlockDetail?.raisingMissionName,
          stepType: getStepTypeName(interlockDetail?.stepType),
        },
        waitForStepId: `0-${interlockDetail?.frontendStepSequenceNumber ?? 0}`,
      };
    } else {
      return {
        type: StepType.Unknown,
        sequenceNumber: step.sequenceNumber,
      };
    }
  }
  throw new Error('Could not convert wait step. No matching EventTrace.');
}

function getStepTypeName(stepType?: StepTypeBackend): string {
  return stepType === StepTypeBackend.WaitForEvent
    ? StepType[StepType.WaitForEndOfStep]
    : StepType[stepType ?? ''];
}
