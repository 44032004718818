/* eslint-disable max-lines */
import { DropShadowFilter } from '@pixi/filter-drop-shadow';
import { ActionDto, IconBackgroundSize, NodeActionTypeGroup } from 'core/dtos';
import {
  ActionGraphNodeIcon,
  GraphNode,
  GraphParkingChargingType,
  VehicleGroup,
} from 'core/models';
import { Colors } from 'library/styles';
import { IconOptions, cleanAndSortActions, sortNodeActionTypeGroups } from 'modules/maps/helpers';
import { ActionNodeGraphicConfig } from 'modules/maps/models';
import { Graphics, Sprite, Texture } from 'pixi.js';
import { BaseNodeMapItem } from './base-node-graphic';
import {
  ActionNodePositions,
  MAX_ACTION_ICON_LIMIT,
  MORE_OPTIONS_INDEX,
  NodeStyle,
} from './graph-layer.constant';
import { ActionIconOptions, actionTypeToIconMap, textures } from './node-icon.model';

export class ActionNodeMapItem extends BaseNodeMapItem {
  protected icon: Sprite | undefined;

  // #region Create Graphic
  protected getNodeConfig(node: GraphNode): ActionNodeGraphicConfig {
    return {
      ...super.getNodeConfig(node),
      actions: node.actions,
    };
  }

  protected getNodeColor(node: GraphNode, vehicleGroup: VehicleGroup): number {
    if (!this.isRoundGraphic(node)) return Colors.graphLayer[vehicleGroup].ActionNodeColor;

    if (node.isSwitchNode) return Colors.graphLayer[vehicleGroup].NodeColorSwitch;

    return Colors.graphLayer[vehicleGroup].NodeColor;
  }

  protected isRoundGraphic(node: GraphNode): boolean {
    const actions = this.getActionIconsCountIncludingParkingChargingType(
      node.actions,
      node.parkingChargingType
    );
    if (actions) {
      return actions.length === 0;
    }
    return super.isRoundGraphic(node);
  }

  protected createNodePoiBackground(size?: IconBackgroundSize): Graphics {
    const nodeColor = this.config?.color;

    if (size === IconBackgroundSize.SingleIcon) {
      return new Graphics()
        .beginFill(nodeColor)
        .lineStyle(NodeStyle.NodeWidth, nodeColor)
        .drawRoundedRect(
          -NodeStyle.SingleIconBackground / 2,
          -NodeStyle.SingleIconBackground / 2,
          NodeStyle.SingleIconBackground,
          NodeStyle.SingleIconBackground,
          NodeStyle.NodeOutlineRadius
        )
        .endFill();
    } else {
      return new Graphics()
        .beginFill(nodeColor)
        .lineStyle(NodeStyle.NodeWidth, nodeColor)
        .drawRoundedRect(
          -NodeStyle.MultiIconBackground / 2,
          -NodeStyle.MultiIconBackground / 2,
          NodeStyle.MultiIconBackground,
          NodeStyle.MultiIconBackground,
          NodeStyle.NodeOutlineRadius
        )
        .endFill();
    }
  }

  private orderActions(actions: ActionDto[] | undefined): NodeActionTypeGroup[] {
    if (!actions) {
      return [];
    }

    const sortedActions = cleanAndSortActions(actions);
    return [...new Set(sortedActions.map(action => action.group))];
  }

  private createGraphicWithSingleAction(actions: NodeActionTypeGroup[]): Graphics {
    const graphic = this.createNodePoiBackground(IconBackgroundSize.SingleIcon);
    graphic.scale.set(NodeStyle.NodeScale);

    actions.forEach((action, i) => {
      graphic.addChild(this.createIcon(action, i, actions.length));
    });

    return graphic;
  }

  private createGraphicWithMultipleActions(actions: NodeActionTypeGroup[]): Graphics {
    const graphic = this.createNodePoiBackground(IconBackgroundSize.MultiIcon);

    graphic.scale.set(NodeStyle.NodeScale);

    actions.forEach((action, i) => {
      if (actions.length > MAX_ACTION_ICON_LIMIT && i >= MORE_OPTIONS_INDEX) {
        if (i === MORE_OPTIONS_INDEX) {
          graphic.addChild(
            this.createActionIcon(
              textures[ActionGraphNodeIcon.MoreOptions],
              ActionIconOptions[ActionGraphNodeIcon.MoreOptions],
              MORE_OPTIONS_INDEX,
              actions.length
            )
          );
        }
      } else {
        graphic.addChild(this.createIcon(action, i, actions.length));
      }
    });

    return graphic;
  }

  private getActionIconsCountIncludingParkingChargingType(
    actions: ActionDto[] | undefined,
    parkingChargingType: GraphParkingChargingType | undefined
  ): NodeActionTypeGroup[] {
    const groupedActions = this.orderActions(actions);

    if (parkingChargingType === undefined) return groupedActions;

    switch (parkingChargingType) {
      case GraphParkingChargingType.Charging:
        if (!groupedActions.includes(NodeActionTypeGroup.StartStopCharging)) {
          groupedActions.push(NodeActionTypeGroup.StartStopCharging);
        }
        break;
      case GraphParkingChargingType.Fueling:
        groupedActions.push(NodeActionTypeGroup.Fueling);
        this.replaceStartStopCharging(groupedActions);
        break;
      case GraphParkingChargingType.Parking:
        groupedActions.push(NodeActionTypeGroup.Parking);
        this.replaceStartStopCharging(groupedActions);
        break;
      case GraphParkingChargingType.None:
        this.replaceStartStopCharging(groupedActions);
        break;
    }

    return sortNodeActionTypeGroups(groupedActions);
  }

  private replaceStartStopCharging(groupedActions: NodeActionTypeGroup[]): void {
    const index = groupedActions.indexOf(NodeActionTypeGroup.StartStopCharging);
    if (index > -1) {
      groupedActions[index] = NodeActionTypeGroup.NotStartStopCharging;
    }
  }

  protected createSquareGraphic(config: ActionNodeGraphicConfig): Graphics {
    const actions = this.getActionIconsCountIncludingParkingChargingType(
      config.actions,
      config.parkingChargingType
    );

    if (actions.length > 1) {
      return this.createGraphicWithMultipleActions(actions);
    } else {
      return this.createGraphicWithSingleAction(actions);
    }
  }

  protected createIcon(action: NodeActionTypeGroup, index: number, totalActions: number): Sprite {
    const actionGraphNodeIcon = this.mapNodeActionTypeToIcon(action);

    return this.createActionIcon(
      textures[actionGraphNodeIcon],
      ActionIconOptions[actionGraphNodeIcon],
      index,
      totalActions
    );
  }

  private createActionIcon(
    texture: Texture,
    options: IconOptions,
    index: number,
    totalActions: number
  ): Sprite {
    const sprite = new Sprite(texture);

    const twoActionNodesPositions = [
      { x: ActionNodePositions.TwoActionsX1, y: ActionNodePositions.CenterPosition },
      { x: ActionNodePositions.TwoActionsX2, y: ActionNodePositions.CenterPosition },
    ];

    const fourActionNodesPositions = [
      { x: ActionNodePositions.FourActionsX1, y: ActionNodePositions.FourActionsY1 },
      { x: ActionNodePositions.FourActionsX2, y: ActionNodePositions.FourActionsY2 },
      { x: ActionNodePositions.FourActionsX3, y: ActionNodePositions.FourActionsY3 },
      { x: ActionNodePositions.FourActionsX4, y: ActionNodePositions.FourActionsY4 },
    ];

    let x = 0;
    let y = 0;

    if (totalActions === 1) {
      x = ActionNodePositions.CenterPosition;
      y = ActionNodePositions.CenterPosition;
    } else if (totalActions === 2) {
      x = twoActionNodesPositions[index].x;
      y = twoActionNodesPositions[index].y;
    } else if (totalActions > 2) {
      x = fourActionNodesPositions[index].x;
      y = fourActionNodesPositions[index].y;
    }

    sprite.pivot.set(x, y);
    sprite.scale.set(1 / options.scale);

    return sprite;
  }

  private mapNodeActionTypeToIcon(actionTypeGroup: NodeActionTypeGroup): ActionGraphNodeIcon {
    return actionTypeToIconMap[actionTypeGroup] ?? actionTypeToIconMap.custom;
  }

  protected createSquareGraphicSelected(): Graphics {
    if (!this.nodeGraphic) return new Graphics();

    const actions = this.getActionIconsCountIncludingParkingChargingType(
      this.node.actions,
      this.config?.parkingChargingType
    );

    const isMultipleActions = actions.length > 1;
    const selection = this.createSelectionGraphic(isMultipleActions);

    selection.name = 'selection';
    selection.filters = [new DropShadowFilter()];
    selection.visible = this.selection?.visible ?? false;

    return selection;
  }

  private createSelectionGraphic(isMultipleActions: boolean): Graphics {
    const selection = new Graphics().lineStyle(
      NodeStyle.NodeBorderWidth,
      NodeStyle.NodeBorderColorSelected
    );

    if (isMultipleActions) {
      selection.drawRoundedRect(
        NodeStyle.NodeActionOutlineX,
        NodeStyle.NodeActionOutlineY,
        NodeStyle.NodeActionOutlineLength,
        NodeStyle.NodeActionOutlineLength,
        NodeStyle.NodeOutlineRadius
      );
    } else {
      selection.drawRoundedRect(
        NodeStyle.NodeSingularActionOutline,
        NodeStyle.NodeSingularActionOutline,
        NodeStyle.SingleIconBackground,
        NodeStyle.SingleIconBackground,
        NodeStyle.NodeOutlineRadius
      );
    }

    return selection;
  }
  // #endregion

  // #region Rotate

  onMapRotation(mapRotation: number): void {
    if (this.config?.isRound) {
      if (this.icon) {
        this.icon.rotation = mapRotation;
      }
    } else {
      this.container.rotation = -mapRotation;
      return;
    }

    if (this.labelGraphic) {
      this.labelGraphic.rotation = mapRotation;
    }
  }

  // #endregion
}
