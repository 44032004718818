/* eslint-disable @typescript-eslint/no-magic-numbers */
import { DEGREE_SIGN } from 'core/constants';
import { MapItem, MapItemType, Pose2D } from 'core/models';
import { IconOptions, MapPixiHelper } from 'modules/maps/helpers';
import { Circle, DisplayObject, Sprite } from 'pixi.js';
import { GeometryConverter, formatRadiansToDegrees, round } from 'shared/helpers';
import { MapItemContainer, RotateMapItem } from './map-item-container';
import { MapImages } from './map-layer-images.constant';
import { MapElements } from './map-layer.constant';
import { ContainerEx } from './pixi/container-extended';
import { MapLabelGraphic } from './shared/map-label.graphic';

export function isMapItemRotationContainer(
  item: DisplayObject | MapItem | MapItemContainer | MapItemRotationContainer
): item is MapItemRotationContainer {
  return 'id' in item && 'rotationContainer' in item;
}

export function isRotateMapItem(
  item: DisplayObject | MapItem | RotateMapItem | undefined
): item is RotateMapItem {
  return item !== undefined && 'toggleRotationMode' in item;
}

export interface RotationStyle {
  offset: number;
  labelYOffset: number;
  iconScale: number;
  textSize: number;
  labelCorner?: number;
  opacity: number;
}

const RotationIconOptions: IconOptions = {
  resourceOptions: { scale: 5 },
  resolution: window.devicePixelRatio,
  scale: 0,
};

const texture = MapPixiHelper.createTexture({
  ...RotationIconOptions,
  path: MapImages.Rotation_Icon,
});

export class MapItemRotationContainer extends MapItemContainer {
  private rotationContainer: ContainerEx | undefined;
  private labelGraphic: MapLabelGraphic | undefined;
  private orientationIndicator: DisplayObject | undefined;
  private mapOrientation = 0;

  private style: RotationStyle = {
    iconScale: 150,
    offset: 0.9,
    labelYOffset: -0.17,
    textSize: 0.005,
    opacity: 0.95,
  };

  get itemPosition(): Pose2D {
    return {
      x: round(this.position.x),
      y: round(this.position.y),
      orientation: this.rotation,
    };
  }

  get isRotationDestroyed(): boolean {
    return this.rotationContainer?.destroyed ?? false;
  }

  get isArrowMode(): boolean {
    return this.orientationIndicator !== undefined;
  }

  constructor(dtoId: string, type: MapItemType, style?: RotationStyle) {
    super(dtoId, type);

    if (style) this.setRotationStyle(style);
  }

  setRotationStyle(style: RotationStyle): void {
    this.style = style;
  }

  clearRotation(): void {
    if (this.rotationContainer?.destroyed || this.labelGraphic?.destroyed) return;

    this.rotationContainer?.destroy();
    this.labelGraphic?.destroy();
    this.labelGraphic = undefined;
    this.orientationIndicator = undefined;
  }

  showRotation(radian: number, arrowGraphic?: DisplayObject): void {
    if (this.rotationContainer) this.clearRotation();

    const rotationHandle = new ContainerEx();
    rotationHandle.buttonMode = true;
    rotationHandle.interactive = true;
    rotationHandle.name = MapElements.Rotation;
    rotationHandle.hitArea = new Circle(0, 0, 50);

    this.rotationContainer = new ContainerEx();
    this.rotationContainer.addChild(rotationHandle);
    this.addChild(this.rotationContainer);

    rotationHandle.addChild(this.createRotationIcon());

    rotationHandle.addChild(
      (this.labelGraphic = new MapLabelGraphic({
        label: `${GeometryConverter.radiansToDegrees(radian).toFixed(0)}${DEGREE_SIGN}`,
        scale: this.style.textSize,
        radius: this.style.labelCorner,
        alpha: this.style.opacity,
        shadow: true,
      }))
    );

    this.rotationContainer.pivot.set(0, this.style.offset);

    this.labelGraphic.pivot.set(0, this.style.labelYOffset);
    this.labelGraphic.position.set(0, 0);

    const rad = GeometryConverter.radianToPositiveValue(radian) || 0;

    this.labelGraphic.rotation = -rad;
    this.orientationIndicator = arrowGraphic;

    this.setRotationContainerRotation(rad ?? 0);
    this.setChildOnTop(this.rotationContainer);
  }

  private createRotationIcon(): Sprite {
    return MapPixiHelper.createIcon(texture, {
      ...RotationIconOptions,
      scale: this.style.iconScale,
    });
  }

  setItemAngle(degree: number): void {
    const radian = GeometryConverter.degreesToPositiveRadians(degree);

    this.setLabelText(degree);
    this.setGraphicsRotation(radian);
  }

  setItemRotation(radian: number): void {
    const degree = formatRadiansToDegrees(radian);

    this.setLabelText(degree);
    this.setGraphicsRotation(GeometryConverter.radianToPositiveValue(radian));
  }

  setMapRotation(mapRotation: number): void {
    this.mapOrientation = mapRotation;
  }

  // When dragged on the map, update text and rotation of label & arrow
  setMoveRotation(radian: number): void {
    this.setLabelText(
      GeometryConverter.radiansToDegrees(GeometryConverter.radianToPositiveValue(radian))
    );
    this.setGraphicsRotation(radian);
  }

  private setLabelText(degree: number): void {
    if (this.labelGraphic && !isNaN(degree)) {
      this.labelGraphic.updateLabelText(`${degree.toFixed(0)}${DEGREE_SIGN}`);
    }
  }

  private setGraphicsRotation(radian: number) {
    if (this.labelGraphic) this.labelGraphic.rotation = -radian + this.mapOrientation;

    if (this.orientationIndicator) {
      this.orientationIndicator.rotation = radian;

      this.setRotationContainerRotation(radian);
    }
  }

  private setRotationContainerRotation(radian: number) {
    if (this.rotationContainer) {
      this.rotationContainer.rotation = this.isArrowMode
        ? radian // Only rotate for arrow
        : GeometryConverter.degreesToPositiveRadians(0); // For other graphics the icon stays on 0 deg
    }
  }
}
