/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  output,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  AlertNowGroupDto,
  AlertNowVehicleDto,
  AlertNowVehicleGroupDto,
  FleetDto,
  VehicleDto,
  WorkAreaSettingRecipientKeyDto,
} from 'core/dtos';
import { GuidString, VehicleType, Zone } from 'core/models';
import { AtsTranslationService, EditBarService } from 'core/services';
import { ModalDialogService } from 'library/components';
import { clone, isEqual } from 'lodash';
import { filter, Subject, take, takeUntil } from 'rxjs';

type VehicleAlertNowForm = {
  id: FormControl<string>;
  name: FormControl<string>;
  recipientKeys: FormArray<FormGroup<string>>;
  strVehicles: FormArray<FormGroup<string>>;
  forkliftVehicles: FormArray<FormGroup<string>>;
  tuggerTrainVehicles: FormArray<FormGroup<string>>;
  uAgvVehicles: FormArray<FormGroup<string>>;
};

type VehicleAlertNow = {
  name: string;
  recipientKeys: string[];
  strVehicles: GuidString[];
  forkliftVehicles: GuidString[];
  tuggerTrainVehicles: GuidString[];
  uAgvVehicles: GuidString[];
};

type ZoneGroupForm = {
  recipientKeys: FormArray<FormGroup<string>>;
  vehicleAlertNowGroup: FormArray<FormGroup<VehicleAlertNowForm>>;
  alertNowZone: FormGroup<string>;
};

type VehicleAlertNowZoneGroupForm = {
  alertNowZoneGroups: FormArray<FormGroup<ZoneGroupForm>>;
};
@Component({
  selector: 'app-ipst-alertnow-group-workarea-edit',
  templateUrl: './ipst-alertnow-group-workarea-edit.component.html',
  styleUrls: ['./ipst-alertnow-group-workarea-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpstAlertNowGroupWorkAreaEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input() workAreaId?: GuidString;
  @Input() alertNowGroups: AlertNowGroupDto[] = [];
  @Input() zones: Zone[] = [];
  @Input() allRecipientKeys: WorkAreaSettingRecipientKeyDto[] = [];
  @Input() vehicles: VehicleDto[] = [];
  @Input() allFleets: FleetDto[] = [];

  @Output() readonly deleteAlertNowGroup = new EventEmitter<AlertNowGroupDto>();
  @Output() readonly saveAlertNowGroup = new EventEmitter<AlertNowGroupDto[]>();
  @Output() readonly cancelAlertNowGroup = new EventEmitter();
  setAlertNowGroupValidity = output<boolean>();

  ngUnsubscribe = new Subject<void>();

  zoneGroupFormGroup: FormGroup<VehicleAlertNowZoneGroupForm>;
  initialFormGroup?: FormGroup<VehicleAlertNowZoneGroupForm>;

  alertNowGroupsCopy: AlertNowGroupDto[] = [];

  constructor(
    protected cdRef: ChangeDetectorRef,
    protected readonly translate: AtsTranslationService,
    private readonly modalService: ModalDialogService,
    private readonly editBarService: EditBarService,
    private readonly formBuilder: UntypedFormBuilder
  ) {
    this.zoneGroupFormGroup = this.createFormGroup();
  }

  get alertNowZoneGroupFormArray(): FormArray<FormGroup<ZoneGroupForm>> {
    const { alertNowZoneGroups } = this.zoneGroupFormGroup.controls;
    return alertNowZoneGroups;
  }

  ngOnInit(): void {
    this.subscribeToFormStatusChanges();

    this.editBarService.onSave = this.onSave.bind(this);
    this.editBarService.onCancel$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onCancel.bind(this));
    this.setAlertNowGroupValidity.emit(false);
  }

  ngOnChanges({
    alertNowGroups,
    vehicles,
    zones,
    allRecipientKeys,
  }: TypedChanges<IpstAlertNowGroupWorkAreaEditComponent>): void {
    if (alertNowGroups) {
      this.alertNowGroups = alertNowGroups.currentValue;
      this.addVehicleGroupConfig();
    }

    if (vehicles?.currentValue) {
      this.vehicles = vehicles.currentValue;
      this.cdRef.markForCheck();
    }

    if (zones?.currentValue) {
      this.zones = zones.currentValue;
      this.cdRef.markForCheck();
    }

    if (allRecipientKeys?.currentValue) {
      this.allRecipientKeys = allRecipientKeys.currentValue;
      this.cdRef.markForCheck();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  subscribeToFormStatusChanges(): void {
    this.zoneGroupFormGroup.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(status => {
      this.editBarService.setIsFormValid(status === 'VALID');
      this.setAlertNowGroupValidity.emit(status === 'VALID');
    });

    this.zoneGroupFormGroup.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(formValue => {
        this.editBarService.setHasChanges(!isEqual(formValue, this.initialFormGroup?.value));
      });
  }

  getVehicleList(vehicles: AlertNowVehicleDto[], vehicleType: VehicleType): GuidString[] {
    const vehiclesFromType = this.vehicles.filter(i => i.vehicleType === vehicleType);
    const vehiclesToReturn: VehicleDto[] = [];

    vehicles.forEach(i => {
      const vehicle = vehiclesFromType.find(v => v.id === i.id);

      if (vehicle) vehiclesToReturn.push(vehicle);
    });

    return vehiclesToReturn.map(i => i.id);
  }

  createZoneGroupFormGroup(alertNowGroupDto: AlertNowGroupDto): FormGroup<ZoneGroupForm> {
    const vehicleAlertNowGroup: VehicleAlertNow[] = [];

    if (alertNowGroupDto.alertNowVehicleGroups.length > 0) {
      alertNowGroupDto.alertNowVehicleGroups.forEach(i => {
        const control = {
          strVehicles: this.getVehicleList(i.alertNowVehicles, VehicleType.UnitLoad),
          forkliftVehicles: this.getVehicleList(i.alertNowVehicles, VehicleType.Forklift),
          tuggerTrainVehicles: this.getVehicleList(i.alertNowVehicles, VehicleType.TuggerTrain),
          uAgvVehicles: this.getVehicleList(i.alertNowVehicles, VehicleType.U_AGV),
          recipientKeys: i.recipientList,
          name: i.name,
        };

        vehicleAlertNowGroup.push(control);
      });
    }

    return this.formBuilder.group({
      recipientKeys: [alertNowGroupDto.recipientList, []],
      alertNowZone: [alertNowGroupDto.masterZoneId, [Validators.required]],
      vehicleAlertNowGroup: [vehicleAlertNowGroup, []],
    });
  }

  createFormGroup(): FormGroup<VehicleAlertNowZoneGroupForm> {
    return this.formBuilder.group({
      alertNowZoneGroups: new FormArray<FormGroup<ZoneGroupForm>>([], {
        validators: Validators.required,
      }),
    });
  }

  addVehicleGroupConfig(): void {
    const previousValue = this.alertNowGroupsCopy;
    const currentValue = this.alertNowGroups;
    const difference = currentValue.filter(
      group => !previousValue.some(prevGroup => prevGroup.id === group.id)
    );

    difference.forEach(i => {
      const newVehicleAlertNowForm = this.createZoneGroupFormGroup(i);
      this.alertNowZoneGroupFormArray.push(newVehicleAlertNowForm);
    });

    this.initialFormGroup = clone(this.zoneGroupFormGroup);
    this.zoneGroupFormGroup.controls.alertNowZoneGroups.setErrors({ invalid: true });
    this.cdRef.markForCheck();

    this.alertNowGroupsCopy = clone(this.alertNowGroups);
  }

  isAllNonFleetVehiclesSelected(
    vehicleIds: GuidString[],
    alertNowVehicleIds: GuidString[]
  ): boolean {
    if (alertNowVehicleIds?.length < 1) return false;
    return vehicleIds.every(id => alertNowVehicleIds?.includes(id));
  }

  convertToAlertNowVehicle(
    form: VehicleAlertNow,
    id: GuidString,
    nonFleetVehicleTypes: VehicleType[]
  ): AlertNowVehicleDto[] {
    const vehicles = [
      ...(form.forkliftVehicles ?? []),
      ...(form.strVehicles ?? []),
      ...(form.tuggerTrainVehicles ?? []),
      ...(form.uAgvVehicles ?? []),
    ];

    const nonFleetVehicles: GuidString[] = [];

    nonFleetVehicleTypes.forEach(i => {
      nonFleetVehicles.push(...this.getNonFleetVehiclesFromType(i));
    });

    const difference = vehicles.filter(
      group => !nonFleetVehicles.some(prevGroup => prevGroup === group)
    );

    return difference.map(i => ({
      id: i,
      vehicleGroupId: id,
    }));
  }

  getNonFleetVehiclesFromType(vehicleType: VehicleType): GuidString[] {
    return this.vehicles.filter(i => i.vehicleType === vehicleType && !i.fleetId).map(i => i.id);
  }

  getAlertNowVehicleGroupTypes(vehicleAlertNow: VehicleAlertNow): VehicleType[] {
    const vehicleTypes: VehicleType[] = [];

    const vehicleTypeMappings: [VehicleType, GuidString[]][] = [
      [VehicleType.UnitLoad, vehicleAlertNow.strVehicles],
      [VehicleType.Forklift, vehicleAlertNow.forkliftVehicles],
      [VehicleType.TuggerTrain, vehicleAlertNow.tuggerTrainVehicles],
      [VehicleType.U_AGV, vehicleAlertNow.uAgvVehicles],
    ];

    vehicleTypeMappings.forEach(([vehicleType, vehicleIds]) => {
      const nonFleetVehicles = this.getNonFleetVehiclesFromType(vehicleType);

      if (this.isAllNonFleetVehiclesSelected(nonFleetVehicles, vehicleIds)) {
        vehicleTypes.push(vehicleType);
      }
    });

    return vehicleTypes;
  }

  getVehicles(
    vehicleAlertNow: VehicleAlertNow[],
    alertNowGroupDto: AlertNowGroupDto
  ): AlertNowVehicleGroupDto[] {
    const alertNowVehicles: AlertNowVehicleGroupDto[] = [];

    vehicleAlertNow?.forEach((i, index) => {
      const id = alertNowGroupDto.alertNowVehicleGroups[index]?.id ?? crypto.randomUUID();
      const nonFleetVehicleTypes = this.getAlertNowVehicleGroupTypes(i);

      alertNowVehicles.push({
        id: id,
        name: i.name,
        alertNowGroupId: alertNowGroupDto.id,
        recipientList: i.recipientKeys,
        nonFleetVehicleTypes: nonFleetVehicleTypes,
        alertNowVehicles: this.convertToAlertNowVehicle(i, id, nonFleetVehicleTypes),
        alertNowFleets: [],
      });
    });

    return alertNowVehicles;
  }

  onDelete(index: number, name: string): void {
    const groupToDelete = {
      ...this.alertNowGroups[index],
      name: name,
    };

    const translateParamsObj = {
      groupName: groupToDelete.name,
    };

    this.modalService
      .createDeleteModal(
        'settings.ipstAlertNowSettings.alertNowService.deleteGroupConfiguration',
        translateParamsObj
      )
      .pipe(take(1), filter(Boolean))
      .subscribe(() => {
        if (groupToDelete.workAreaId === '') {
          this.alertNowZoneGroupFormArray.removeAt(index);
          if (this.alertNowZoneGroupFormArray.length === 0)
            this.setAlertNowGroupValidity.emit(true);
          this.cdRef.markForCheck();
        }

        this.deleteAlertNowGroup.emit(groupToDelete);
      });
  }

  onCancel(): void {
    this.cancelAlertNowGroup.emit();
  }

  onSave(): void {
    const controls = this.alertNowZoneGroupFormArray.controls.map(i => ({
      name: i.get('name')?.value,
      recipientKeys: i.get('recipientKeys')?.value,
      alertNowZone: i.get('alertNowZone')?.value,
      vehicleAlertNowGroup: i.get('vehicleAlertNowGroup')?.value,
    }));
    const alertNowGroups: AlertNowGroupDto[] = [];

    controls.forEach((c, index) => {
      const alertNowGroup = this.alertNowGroups[index];

      alertNowGroups.push({
        id: this.alertNowGroups[index]?.id ?? crypto.randomUUID(),
        workAreaId: this.workAreaId ?? '',
        masterZoneId: c.alertNowZone?.toString() ?? '',
        name: c.name ?? '',
        recipientList: c.recipientKeys ?? [],
        alertNowVehicleGroups: this.getVehicles(c.vehicleAlertNowGroup ?? [], alertNowGroup),
      });
    });

    this.saveAlertNowGroup.emit(alertNowGroups);
  }
}
