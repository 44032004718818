<div class="title">
  <h5>
    {{ 'settings.functions.shutdownMode.shutdownType.' + toggle.vehicleShutdownType | translate }}
  </h5>
  <ds-switch
    data-cy="toggle"
    *ngIf="isLoaded; else error"
    [(ngModel)]="toggle.isToggledOn"
    [disabled]="disabled || doesNotHaveRequiredPermission"
    (ngModelChange)="onToggle($event)"
    class="ms-2x"
    ds-tooltip="{{
      ('settings.roles.permissions.Tooltip_Pretext' | translate) +
        ('settings.roles.permissions.ToggleMassShutdown' | translate) +
        ('settings.roles.permissions.Tooltip_Posttext' | translate)
    }}"
    [dsTooltipConfig]="{ disabled: !doesNotHaveRequiredPermission }"
    >{{
      toggle.isToggledOn
        ? ('settings.featureToggles.on' | translate)
        : ('settings.featureToggles.off' | translate)
    }}</ds-switch
  >
  <ng-template #error>
    <div class="error-background">
      <label data-cy="errorMessage" class="label-value error-text"
        ><ds-icon class="ms-2x icon" icon="error" tone="critical"></ds-icon>
        {{ 'settings.functions.unknownValue' | translate }}
      </label>
    </div> </ng-template
  ><app-last-changed-date dateUpdated="{{ toggle.dateUpdated }}"></app-last-changed-date>
</div>
<div class="col-9 position-description">
  <span data-cy="description">{{
    'settings.featureToggles.endOfShiftSection.shutdownModeDescription.' +
      toggle.vehicleShutdownType | translate : { time: toggle.shutdownDelayInMinutes }
  }}</span>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col me-2x">
      <app-labeled-input
        data-cy="endShutdownAndWakeUpVehiclesTimeInterval"
        label="{{
          'settings.featureToggles.endOfShiftSection.endShutdownAndWakeUpVehicles' | translate
        }}">
        {{
          toggle.isToggledOn && toggle.endShutdownAndWakeUpVehiclesDateTime
            ? (toggle.endShutdownAndWakeUpVehiclesDateTime.toString()
              | date : 'dd.MM.yyyy, H:mm:ss')
            : ('settings.featureToggles.endOfShiftSection.neverWakeVehicles' | translate)
        }}
      </app-labeled-input>
    </div>

    <div class="col me-2x" style="display: flex">
      <div *ngIf="toggle.isToggledOn && toggle.numberOfVehiclesToExclude">
        <app-labeled-input
          data-cy="numberOfVehiclesToExclude"
          label="{{
            'settings.featureToggles.endOfShiftSection.numberOfVehiclesToExclude' | translate
          }}">
          {{ toggle.numberOfVehiclesToExclude | notApplicable }}
        </app-labeled-input>
      </div>

      <div *ngIf="toggle.isToggledOn" class="ms-2x">
        <span data-cy="editButton" (click)="onEdit()"><i class="pi pi-pencil"></i></span>
      </div>
    </div>
  </div>
</div>
<app-shutdown-mode-modal
  [shutdownModeInputData]="shutdownModeInputData"
  [isModalOpen]="isModalOpen"
  [showVehiclesToExclude]="showVehiclesToExclude"
  [allVehicles]="allVehicles"
  (confirmToggleShutdown)="onConfirmShutDownToggle($event)"
  (cancelShutdownToggle)="onCancel()">
</app-shutdown-mode-modal>
