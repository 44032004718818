<ds-modal
  [dimensionsConfig]="{ width: '630px' }"
  #shutdownModeModal
  [ds-modal-trigger-for]="shutdownModeModal"
  [accentBarTone]="'caution'"
  [(isModalOpen)]="isModalOpen"
  (modalDismiss)="onClose()">
  <ds-box-header data-cy="dialogHeader">{{
    'settings.functions.shutdownMode.' + vm.titleKey | translate
  }}</ds-box-header>
  <ds-box-content divider="full" data-cy="dialog-content" style="min-height: 260px">
    <div class="modalMessageText1" *ngIf="vm.showHeading">
      <span data-cy="heading">{{ vm.headingKey | translate }}?</span>
    </div>
    <div class="modalMessageText2">
      <span data-cy="description">
        {{
          'settings.featureToggles.endOfShiftSection.shutdownModeDescription.' +
            vm.dto.vehicleShutdownType | translate : { time: vm.dto.shutdownDelayInMinutes }
        }}
      </span>
    </div>

    <div *ngIf="vm.toggle">
      <div class="p-field-checkbox">
        <ds-checkbox-group>
          <input
            ds-input
            type="checkbox"
            id="endShutdownAndWakeUpVehicles"
            data-cy="endShutdownAndWakeUpVehicles"
            [(ngModel)]="wakeOtherVehicles"
            (ngModelChange)="onChangeWakeUp($event)" />
          <label ds-label for="endShutdownAndWakeUpVehicles" class="font-weight-bold">{{
            'settings.featureToggles.endOfShiftSection.wakeOtherVehicles' | translate
          }}</label>
        </ds-checkbox-group>
      </div>

      <div *ngIf="wakeOtherVehicles" class="container-fluid g-0">
        <div class="row">
          <div class="col me-4x">
            <div class="row">
              <app-labeled-input
                data-cy="endShutdownAndWakeUpVehiclesTimeInterval"
                label="{{ 'settings.functions.shutdownMode.wakeUpTime' | translate }}"
                [required]="true">
              </app-labeled-input>
            </div>

            <div class="row">
              <div class="col-sm-6 col-lg-8">
                <ds-datepicker
                  [viewDate]="selectedWakeupDate"
                  [config]="datePickerConfig"
                  data-cy="endShutdownAndWakeUpVehiclesDatePicker">
                  <input
                    id="date"
                    data-cy="endShutdownAndWakeUpVehiclesDatePickerInput"
                    ds-date
                    ds-form-validation
                    required
                    id="date"
                    [(ngModel)]="selectedWakeupDate"
                    (ngModelChange)="validateShutdownForm()" />
                </ds-datepicker>
                <div *ngIf="isWakeUpTimeValid === false" class="invalid-input-text">
                  <span data-cy="shutdownDateInvalid">
                    {{ 'validation.minimumDate' | translate }}
                  </span>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4">
                <ds-timepicker [(config)]="config" data-cy="endShutdownAndWakeUpVehiclesTimePicker">
                  <input
                    data-cy="endShutdownAndWakeUpVehiclesTimePickerInput"
                    ds-time
                    id="time"
                    [(ngModel)]="viewTime"
                    (ngModelChange)="validateShutdownForm()" />
                </ds-timepicker>
              </div>
            </div>
          </div>
          <div class="col-lg-5" *ngIf="showVehiclesToExclude">
            <app-labeled-input
              label="{{
                'settings.featureToggles.endOfShiftSection.numberOfVehiclesToExclude' | translate
              }}"
              [required]="true">
            </app-labeled-input>

            <ds-form-field>
              <input
                #numberOfVehiclesToExcludeInput
                ds-input
                type="number"
                data-cy="numberOfVehiclesToExcludeInput"
                placeholder="{{ 'placeholders.define' | translate }}"
                [min]="1"
                [step]="1"
                [(ngModel)]="vm.dto.numberOfVehiclesToExclude"
                (ngModelChange)="validateShutdownForm()" />
            </ds-form-field>
            <div *ngIf="isVehiclesToExcludeValid === false" class="invalid-input-text">
              <span data-cy="shutdownDateInvalid">
                {{ 'validation.numberOfVehiclesToExclude' | translate }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ds-box-content>
  <ds-box-footer data-cy="dialog-footer">
    <button ds-button [variant]="'outline'" (click)="onClose()" data-cy="cancelButton">
      {{ 'shared.modalButtons.CancelButton' | translate }}
    </button>
    <button
      ds-button
      class="ms-2x"
      (click)="onConfirm()"
      [disabled]="!isShutdownValid"
      data-cy="saveButton">
      {{ vm.confirmBtnKey | translate }}
    </button>
  </ds-box-footer>
</ds-modal>

<ds-modal
  [dimensionsConfig]="{ width: '535px' }"
  #shutdownModeVerificationModal
  [ds-modal-trigger-for]="shutdownModeVerificationModal"
  [accentBarTone]="'default'"
  [(isModalOpen)]="isVerificationModalOpen"
  (modalDismiss)="onVerificationModalClose()"
  data-cy="verificationModal">
  <ds-box-header data-cy="dialogHeader">{{
    'settings.functions.shutdownMode.verificationTitle' | translate
  }}</ds-box-header>
  <ds-box-content divider="full" data-cy="dialog-content">
    <div class="modalMessageText1">
      <span data-cy="heading">
        {{ 'settings.functions.shutdownMode.verificationHeading' | translate }}</span
      >
      <div class="modalMessageText2">
        <span data-cy="description1">
          {{ 'settings.functions.shutdownMode.verificationMessagePart1' | translate }}</span
        >
      </div>
    </div>
    <div class="modalMessageText2 spaceBetweenProperties">
      <span data-cy="description2">
        {{ 'settings.functions.shutdownMode.verificationMessagePart2' | translate }}</span
      >
    </div>
  </ds-box-content>
  <ds-box-footer data-cy="dialog-footer">
    <button
      ds-button
      [variant]="'outline'"
      (click)="onVerificationConfirm()"
      data-cy="modalConfirm">
      {{ 'shared.modalButtons.ConfirmButton' | translate }}
    </button>
    <button ds-button class="ms-2x" (click)="onVerificationModalClose()" data-cy="cancelButton">
      {{ 'shared.modalButtons.CancelButton' | translate }}
    </button>
  </ds-box-footer>
</ds-modal>
