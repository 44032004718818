import { FeatureToggle } from 'core/dtos';
import { DateString, GuidString, TIME_TO_SHUTDOWN, VehiclePowerSavingType } from 'core/models';

export interface FleetManagerFeatures {
  evacuationModeToggle: EvacuationModeDto;
  shutdownModeToggle: ShutdownModeDto[];
  collectivePauseToggle: CollectivePauseDto;
  firefighterEvacuationModeToggle: EvacuationModeToggleFireFighter;
  allowLoadedBreakTest: FeatureToggle;
}

export type EvacuationModeDto = FeatureToggle;

export interface EvacuationModeToggleFireFighterUpdateRequest {
  workAreaId: GuidString;
  organizationId: GuidString;
  isToggledOn: boolean;
}

export interface EvacModeToggleFireFighterPutResponse {
  workAreaId: GuidString;
  organizationId: GuidString;
  isToggledOn: boolean;
  dateUpdated: DateString;
}
export interface EvacModeToggleFireFighterGetResponse {
  key: 'FireFighterEvacuationMode';
  workAreaId: GuidString;
  isToggledOn: boolean;
  createdUtc?: DateString | null;
  modifiedUtc?: DateString | null;
}

export type EvacuationModeToggleFireFighter = Omit<
  EvacModeToggleFireFighterPutResponse,
  'organizationId' | 'workAreaId'
>;

export interface ShutdownModeDto extends FeatureToggle {
  endShutdownAndWakeUpVehiclesDateTime: DateString | null;
  shutdownDelayInMinutes: number;
  numberOfVehiclesToExclude: number;
  latestWakeup: DateString | null;
  vehicleShutdownType: VehiclePowerSavingType;
}

export interface CollectivePauseDto<T = FeatureToggle> {
  forklift: T;
  tuggerTrain: T;
  unitLoad: T;
  uagv: T;
}

export type CollectivePauseUpdateDto = CollectivePauseDto<Omit<FeatureToggle, 'dateUpdated'>>;

export function getDefaultShutdownModeDto(): ShutdownModeDto[] {
  return Object.values(VehiclePowerSavingType)
    .filter(x => isNaN(Number(x))) // filter for string representation
    .map(vehicleShutdownType => {
      const type = VehiclePowerSavingType[vehicleShutdownType];
      const setting = {
        isToggledOn: false,
        shutdownDelayInMinutes: TIME_TO_SHUTDOWN,
        numberOfVehiclesToExclude: 0,
        endShutdownAndWakeUpVehiclesDateTime: null,
        latestWakeup: null,
        dateUpdated: null,
        vehicleShutdownType: type,
      };
      switch (type) {
        case VehiclePowerSavingType.Str:
          setting.numberOfVehiclesToExclude = 2;
          break;
        case VehiclePowerSavingType.dsTuggertrain:
        case VehiclePowerSavingType.Uagv:
        case VehiclePowerSavingType.fourAmForklist:
          break;
      }
      return setting;
    });
}

export function getDefaultCollectivePauseDto(): CollectivePauseDto {
  return {
    unitLoad: {
      isToggledOn: false,
      dateUpdated: null,
    },
    tuggerTrain: {
      isToggledOn: false,
      dateUpdated: null,
    },
    forklift: {
      isToggledOn: false,
      dateUpdated: null,
    },
    uagv: {
      isToggledOn: false,
      dateUpdated: null,
    },
  };
}

export function getDefaultFleetManagerFeatures(): FleetManagerFeatures {
  return {
    evacuationModeToggle: {
      isToggledOn: false,
      dateUpdated: null,
    },
    shutdownModeToggle: getDefaultShutdownModeDto(),
    collectivePauseToggle: getDefaultCollectivePauseDto(),
    firefighterEvacuationModeToggle: {
      isToggledOn: false,
      dateUpdated: '',
    },
    allowLoadedBreakTest: {
      isToggledOn: false,
      dateUpdated: '',
    },
  };
}
