import {
  SoftwareUpdateVersionStatus,
  VehicleMapDataStatus,
  VehicleOptionStatus,
} from 'core/models';
import { Icons } from 'library/constants';

const softwareUpdateStatus = {
  [SoftwareUpdateVersionStatus.Unknown]: Icons.Question,
  [SoftwareUpdateVersionStatus.Outdated]: Icons.Warning,
  [SoftwareUpdateVersionStatus.Approved]: Icons.Check,
  [SoftwareUpdateVersionStatus.Test]: Icons.Exchange,
};

const vehicleMapDataStatus = {
  [VehicleMapDataStatus.InProcessing]: Icons.Loading,
  [VehicleMapDataStatus.NotValidated]: Icons.Warning,
  [VehicleMapDataStatus.Validated]: Icons.Check,
  [VehicleMapDataStatus.ValidationFailed]: Icons.Error,
};

const manageMapData = {
  [VehicleOptionStatus.Offline]: Icons.Offline,
  [VehicleOptionStatus.Available]: Icons.CheckDouble,
  [VehicleOptionStatus.CurrentActiveMap]: Icons.CheckDouble,
  [VehicleOptionStatus.EnabledMap]: Icons.Alert,
  [VehicleOptionStatus.MapNotAvailableOnVehicle]: Icons.Information,
  [VehicleOptionStatus.Busy]: Icons.Alert,
  [VehicleOptionStatus.AlreadySentMap]: Icons.CheckDouble,
};

export const IconCollection = {
  softwareUpdateStatus,
  manageMapData,
  vehicleMapDataStatus,
};
