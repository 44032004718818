import { BasicVehicleDimensions, LoadDimensions } from 'core/dtos';

export type VehicleDimensions = UnderrideDimensions | TuggerDimensions | ForkliftDimensions;

export interface VehicleLoadDimensions {
  load: LoadDimensions;
  loadPosition?: number; // Middle point of load from rear of the vehicle
}

export interface UnderrideDimensions extends BasicVehicleDimensions, VehicleLoadDimensions {}

export interface ForkliftDimensions extends BasicVehicleDimensions, VehicleLoadDimensions {
  fork: {
    width: number;
    length: number; // Default value, overridden by forkLength
    loadOffset: number; // Offset from vehicle rear
  };
}

export interface TuggerDimensions extends BasicVehicleDimensions {
  trailer: TrailerDimensions;
}

export interface TrailerDimensions {
  width: number;
  length: number;
  origin?: number; // From rear of the trailer
  barLength: number;
}

export function isLoadedDimensions(
  dimensions: VehicleDimensions | VehicleLoadDimensions
): dimensions is VehicleLoadDimensions {
  return 'load' in dimensions;
}

export function isForkliftDimensions(
  dimensions: VehicleDimensions
): dimensions is ForkliftDimensions {
  return 'fork' in dimensions;
}

export function isTuggerDimensions(dimensions: VehicleDimensions): dimensions is TuggerDimensions {
  return 'trailer' in dimensions;
}

export function isUnderrideDimensions(
  dimensions: VehicleDimensions
): dimensions is UnderrideDimensions {
  return 'load' in dimensions;
}
