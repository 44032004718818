<app-tabs-content-noscroll-layout
  [navigateOnSelect]="false"
  [useContentStyling]="false"
  [hasToolbar]="false"
  [contentTemplate]="contentTemplate"
  [tabTemplate]="tabHeaderTemplate"
  [tabs]="tabs"
  [selectedKey]="selectedTabId"
  [guardSelectionChange]="true"
  (selectedTabIdChanged)="onSelectedTabChanged($event)"
  (tabSelectionChangeRequest)="onTabSelectionChangeRequest($event)">
</app-tabs-content-noscroll-layout>

<ng-template #tabHeaderTemplate let-tab="tab">
  <ng-container layout-content>
    <span class="tabRow">
      <div class="tabColumn">
        <span data-cy="tabs" class="tabHeading">{{ tab.heading | translate }}</span>
      </div>
    </span>
  </ng-container>
</ng-template>

<ng-template #contentTemplate>
  <ng-container *ngTemplateOutlet="settingsPageTemplate"></ng-container>
</ng-template>
<ng-template #ErrorForwardingServiceTemplate>
  <app-error-forwarding-list
    [tableErrorForwarding]="tableErrorForwarding"
    [searchTerm]="searchTerm$ | async"
    [workingAreaSetting]="ipstWorkingAreaSettingV2Dto"
    (saveErrorForwarding)="onSaveErrorForwarding($event)"></app-error-forwarding-list>
</ng-template>
<ng-template #IpstServiceTemplate>
  <app-ipst-alertnow-general-info-view
    *ngIf="(isEditMode$ | async) === false"
    [generalInformation]="generalInformation">
  </app-ipst-alertnow-general-info-view>
  <app-ipst-alertnow-settings-view
    *ngIf="(isEditMode$ | async) === false"
    [selectedWorkingAreaId]="selectedWorkingAreaId$ | async"
    [ipstWorkingAreaSettingV2Dto]="ipstWorkingAreaSettingV2Dto"
    [lastIpstMessageResolved]="ipstLoadLastMessageResolve$ | async"
    (resolveAllIpstMessages)="resolveAllIpstMessages($event)"></app-ipst-alertnow-settings-view>
  <app-ipst-alertnow-error-messages-view
    *ngIf="(isEditMode$ | async) === false"
    [ipstServiceFeatures]="ipstSettings$ | async"
    [ipstServiceFeaturesLoaded]="ipstSettingsLoaded$ | async">
  </app-ipst-alertnow-error-messages-view>
  <app-ipst-alertnow-general-info-edit
    *ngIf="isEditMode$ | async"
    [ipstEnabled]="ipstEnabled"
    [formControl]="$any(mainForm.get('generalInformation'))"
    ngDefaultControl>
  </app-ipst-alertnow-general-info-edit>
  <app-ipst-alertnow-settings-edit
    *ngIf="isEditMode$ | async"
    [formControl]="$any(mainForm.get('ipstSettings'))"
    (ipstEnabled)="ipstHasBeenToggled($event)"></app-ipst-alertnow-settings-edit>
  <app-ipst-alertnow-error-messages-edit
    *ngIf="isEditMode$ | async"
    [ipstServiceFeatures]="ipstSettings$ | async"
    [ipstServiceFeaturesLoaded]="ipstSettingsLoaded$ | async"
    (saveIncludeTuggerTrainErrors)="
      onSaveIncludeTuggerTrainErrors($event)
    "></app-ipst-alertnow-error-messages-edit>
</ng-template>

<ng-template #AlertNowServiceTemplate>
  <app-ipst-alertnow-settings-service
    data-cy="alertNowServiceTab"
    [vehicles]="vehicles"
    [alertNowGroups]="alertNowGroups"
    [allFleets]="allFleets"
    [workingAreaSetting]="ipstWorkingAreaSettingV2Dto"
    [alertNowUrl]="generalInformation.alertNowUrl"
    (save)="onSaveAlertNowSettings($event)"
    (saveAlertNowGroups)="onSaveAlertNowGroups($event)"
    (deleteAlertNowGroup)="onDeleteAlertNowGroup($event)"></app-ipst-alertnow-settings-service>
</ng-template>

<ng-template #HelpToolTemplate>
  <app-ipst-alert-now-help-tool
    [errorLanguage]="ipstWorkingAreaSettingV2Dto.errorLanguage"></app-ipst-alert-now-help-tool>
</ng-template>

<ng-template #IpstAlertNowAuditLogTemplate>
  <app-ipst-alert-now-audit-log></app-ipst-alert-now-audit-log>
</ng-template>
