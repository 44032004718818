import { Injectable } from '@angular/core';
import { AtsTranslationService } from 'core/services/ats-translation.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private readonly atsTranslateService: AtsTranslationService) {}

  downloadJson<Type>(arg: Type, fileName: string, translationParams: object = {}): void {
    const jsonData = JSON.stringify(arg, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = this.atsTranslateService.get(fileName, translationParams) + '.json';
    anchor.click();
    window.URL.revokeObjectURL(url);
  }
}
