import { createSelector } from '@ngrx/store';
import { EdgeDto, LayoutDto, NodeDto } from 'core/dtos';

import { EMPTY_GUID } from 'core/constants';
import { selectMapId } from './maps.selectors';

import * as reducers from '../reducers';
import * as fromGraphLayers from '../reducers/graph-layer.reducer';

export function mapLayouts(layouts?: LayoutDto[]): LayoutDto[] {
  return layouts?.map(l => mapLayoutValues(l)) || [];
}

export function mapLayoutsNodes(layouts?: LayoutDto[]): NodeDto[] {
  return layouts?.flatMap(l => mapLayoutValues(l).nodes) || [];
}

export function mapLayoutsEdges(layouts?: LayoutDto[]): EdgeDto[] {
  return layouts?.flatMap(l => mapLayoutValues(l).edges) || [];
}

export function mapLayoutValues(layout?: LayoutDto): LayoutDto {
  if (layout && layout.nodes) {
    const nodeIds = layout.nodes.map(n => n.nodeId);

    const edges = layout.edges.filter(
      e => nodeIds.some(n => n === e.startNodeId) || nodeIds.some(n => n === e.endNodeId)
    );

    const result: LayoutDto = {
      ...layout,
      edges,
    };

    return result;
  }

  return {
    id: EMPTY_GUID,
    mapId: EMPTY_GUID,
    navigationLayerId: EMPTY_GUID,
    nodes: [],
    edges: [],
  };
}

const selectGraphLayerState = createSelector(
  reducers.getMapsFeatureState,
  reducers.getGraphLayersState
);

export const selectAllGraphLayers = createSelector(
  selectGraphLayerState,
  fromGraphLayers.getAllGraphLayers
);

export const selectGraphLayersActionStatus = createSelector(
  selectGraphLayerState,
  fromGraphLayers.getActionStatus
);

export const selectGraphLayersBySelectedMapId = createSelector(
  selectMapId,
  selectAllGraphLayers,
  (selectedMapId, layouts) => {
    return mapLayouts(layouts.filter(l => l.mapId === selectedMapId));
  }
);

export const selectGraphNodesBySelectedMapId = createSelector(
  selectMapId,
  selectAllGraphLayers,
  (selectedMapId, layout) => {
    return mapLayoutsNodes(layout.filter(l => l.mapId === selectedMapId));
  }
);

export const selectGraphEdgesBySelectedMapId = createSelector(
  selectMapId,
  selectAllGraphLayers,
  (selectedMapId, layout) => {
    return mapLayoutsEdges(layout.filter(l => l.mapId === selectedMapId));
  }
);
