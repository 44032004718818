/* eslint-disable max-lines */
import { Action, createFeatureSelector, createReducer, on } from '@ngrx/store';
import {
  EmulatorHostFeaturesDto,
  EvacModeToggleFireFighterGetResponse,
  EvacuationDeviceDto,
  FleetManagerFeatures,
  GmParkingAndChargingSettingsDto,
  GraphManagerSettingsDto,
  InfobarMessageResponse,
  IpstServiceFeatures,
  JobManagerFeatures,
  LoadTypeSettingsState,
  MapManagerFeatures,
  ShutdownRequestDto,
  TrafficManagerFeatures,
  ZoneManagementSettings,
  getDefaultFleetManagerFeatures,
  getDefaultGraphManagerFeatures,
  getDefaultGraphManagerParkingAndChargingSettings,
  getDefaultIpstServiceFeatures,
  getDefaultJobManagerFeatures,
  getDefaultMapManagerFeatures,
  getDefaultTrafficManagerFeatures,
  getDefaultZoneManagementFeatures,
  getLoadTypeSettingsStateDefault,
} from 'core/dtos';
import {
  OrderGatewayFeatures,
  getDefaultOrderGatewayFeatures,
} from 'core/dtos/settings/order-gateway-features.dto';

import * as SignalRActionTypes from 'core/signalR/store/actions/signalr.actions';

import { DateString } from 'core/models';
import { mergeOrAppend } from 'shared/helpers/unique-values-by-key-of-object-array';
import * as LoadTypeSettingsActions from '../actions/load-type-settings.actions';
import * as SettingsActions from '../actions/settings.actions';

export const featureKey = 'settings';

export interface SettingsState {
  emulatorHostFeatures: EmulatorHostFeaturesDto;
  evacuationDeviceSettings: EvacuationDeviceDto[];
  fleetManagerFeatures: FleetManagerFeatures;
  jobManagerFeatures: JobManagerFeatures;
  mapManagerFeatures: MapManagerFeatures;
  shutdownRequest: ShutdownRequestDto[];
  trafficManagerFeatures: TrafficManagerFeatures;
  zoneManagerSettings: ZoneManagementSettings;
  ipstServiceFeatures: IpstServiceFeatures;
  orderGatewayFeatures: OrderGatewayFeatures;
  infobarMessage: InfobarMessageResponse | null;
  ipstLastMessageResolved: DateString;
  resolveAllIpstMessages: boolean;
  errorMessage: string;
  trafficLoaded: boolean;
  zoneSettingsLoaded: boolean;
  fleetLoaded: boolean;
  emulatorLoaded: boolean;
  evacuationDevicesLoaded: boolean;
  jobManagerLoaded: boolean;
  mapManagerLoaded: boolean;
  shutdownRequestLoaded: boolean;
  isShutdownModeFailed: boolean;
  isJobSettingsFailed: boolean;
  isMapSettingsFailed: boolean;
  isTrafficSettingsFailed: boolean;
  isFleetSettingsFailed: boolean;
  infobarMessageLoaded: boolean;
  ipstLoaded: boolean;
  orderGatewayLoaded: boolean;
  graphManagerFeatureSettings: GraphManagerSettingsDto;
  graphManagerFeatureSettingsLoaded: boolean;
  graphManagerParkingAndChargingSettings: GmParkingAndChargingSettingsDto;
  graphManagerParkingAndChargingSettingsLoaded: boolean;
  evacuationModeFireFighterToggles: EvacModeToggleFireFighterGetResponse[];
  evacuationModeFireFighterTogglesLoaded: boolean;
  loadTypeSettings: LoadTypeSettingsState;
}

export const initialState: SettingsState = {
  fleetManagerFeatures: getDefaultFleetManagerFeatures(),
  trafficManagerFeatures: getDefaultTrafficManagerFeatures(),
  zoneManagerSettings: getDefaultZoneManagementFeatures(),
  jobManagerFeatures: getDefaultJobManagerFeatures(),
  mapManagerFeatures: getDefaultMapManagerFeatures(),
  ipstServiceFeatures: getDefaultIpstServiceFeatures(),
  orderGatewayFeatures: getDefaultOrderGatewayFeatures(),
  emulatorHostFeatures: { speedFactor: 5, enableCompletePath: false, enableAwarenessField: false },
  evacuationDeviceSettings: [],
  evacuationModeFireFighterToggles: [],
  shutdownRequest: [],
  infobarMessage: {
    id: '',
    infoType: 'information',
    message: '',
  },
  graphManagerFeatureSettings: getDefaultGraphManagerFeatures(),
  graphManagerParkingAndChargingSettings: getDefaultGraphManagerParkingAndChargingSettings(),
  ipstLastMessageResolved: '',
  resolveAllIpstMessages: false,
  errorMessage: '',
  trafficLoaded: false,
  zoneSettingsLoaded: false,
  fleetLoaded: false,
  emulatorLoaded: false,
  evacuationDevicesLoaded: false,
  jobManagerLoaded: false,
  mapManagerLoaded: false,
  shutdownRequestLoaded: false,
  isShutdownModeFailed: false,
  isJobSettingsFailed: false,
  isMapSettingsFailed: false,
  isTrafficSettingsFailed: false,
  isFleetSettingsFailed: false,
  infobarMessageLoaded: false,
  ipstLoaded: false,
  orderGatewayLoaded: false,
  graphManagerFeatureSettingsLoaded: false,
  graphManagerParkingAndChargingSettingsLoaded: false,
  evacuationModeFireFighterTogglesLoaded: false,
  loadTypeSettings: getLoadTypeSettingsStateDefault(),
};

const settingsReducer = createReducer(
  initialState,

  on(SettingsActions.loadFleetSettings, state => ({
    ...state,
    errorMessage: '',
    fleetLoaded: false,
  })),

  //TODO: Make sure that the SignalR message here becomes the new Dto
  on(SignalRActionTypes.fleetManagerFeaturesMessageReceived, (state, { fleetManagerFeatures }) => ({
    ...state,
    fleetManagerFeatures,
    fleetLoaded: true,
  })),

  on(SettingsActions.loadFleetSettingsSuccess, (state, { fleetManagerFeatures }) => {
    const fmSettings: FleetManagerFeatures = {
      ...state.fleetManagerFeatures,
      ...fleetManagerFeatures,
    };

    return {
      ...state,
      fleetManagerFeatures: fmSettings,
      fleetLoaded: true,
    };
  }),

  on(SettingsActions.loadFleetSettingsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    fleetLoaded: false,
  })),

  on(SettingsActions.loadShutdownRequest, state => ({
    ...state,
    errorMessage: '',
    shutdownRequestLoaded: false,
  })),

  on(SettingsActions.loadShutdownRequestSuccess, (state, { shutdownRequest }) => ({
    ...state,
    shutdownRequest,
    shutdownRequestLoaded: true,
  })),
  on(SignalRActionTypes.shutdownModeMessageReceived, (state, { shutdownRequest }) => ({
    ...state,
    shutdownRequest: state.shutdownRequest.map(s => {
      if (s.vehicleShutdownType === shutdownRequest.vehicleShutdownType) {
        return shutdownRequest;
      }
      return s;
    }),
    shutdownRequestLoaded: true,
  })),

  on(SettingsActions.loadShutdownRequestFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    shutdownRequestLoaded: false,
  })),

  on(SettingsActions.loadTrafficSettingsSuccess, (state, { trafficManagementSettings }) => ({
    ...state,
    trafficManagerFeatures: {
      avoidConflictsAtPoisToggle: {
        isToggledOn: trafficManagementSettings.settings.considerIdleUnitLoad ?? false,
        dateUpdated: trafficManagementSettings.avoidConflictsAtPoisUpdatedUtc,
      },
      trafficManagementToggle: {
        ...trafficManagementSettings.settings,
        isToggledOn:
          trafficManagementSettings.settings.isEnabled ??
          state.trafficManagerFeatures.trafficManagementToggle.isToggledOn,
        dateUpdated: trafficManagementSettings.trafficManagementEnabledUpdatedTimeUtc,
      },
    },
    trafficLoaded: true,
  })),

  on(SettingsActions.loadZoneSettingsSuccess, (state, { zoneSettings }) => ({
    ...state,
    zoneManagerSettings: { ...zoneSettings },
    zoneSettingsLoaded: true,
  })),

  //TODO: Make sure that this signalr message is updated on the backend
  on(
    SignalRActionTypes.trafficManagerTrafficModeMessageReceived,
    (state, { trafficManagerFeatures }) => ({
      ...state,
      trafficManagerFeatures: {
        ...state.trafficManagerFeatures,
        trafficManagementToggle: {
          ...trafficManagerFeatures.trafficManagementSettings,
          isToggledOn:
            trafficManagerFeatures.trafficManagementSettings.isEnabled ??
            state.trafficManagerFeatures.trafficManagementToggle.isToggledOn,
        },
      },
      trafficLoaded: true,
    })
  ),

  on(SettingsActions.loadTrafficSettingsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    trafficLoaded: false,
  })),

  on(SettingsActions.loadZoneSettingsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    zoneSettingsLoaded: false,
  })),

  on(SettingsActions.loadEmulatorSettings, state => ({
    ...state,
    errorMessage: '',
    emulatorLoaded: false,
  })),

  on(SettingsActions.loadEmulatorSettingsSuccess, (state, { emulatorManagerFeatures }) => ({
    ...state,
    emulatorHostFeatures: emulatorManagerFeatures,
    emulatorLoaded: true,
  })),

  on(SettingsActions.loadEmulatorSettingsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    emulatorLoaded: false,
  })),

  on(SettingsActions.loadEvacuationDeviceSettings, state => ({
    ...state,
    errorMessage: '',
    evacuationDevicesLoaded: false,
  })),

  on(SettingsActions.loadEvacuationDeviceSettingsSuccess, (state, { devices }) => ({
    ...state,
    evacuationDeviceSettings: devices,
    evacuationDevicesLoaded: true,
  })),

  on(SettingsActions.loadEvacuationDeviceSettingsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    evacuationDevicesLoaded: false,
  })),

  on(SettingsActions.loadJobSettings, state => ({
    ...state,
    errorMessage: '',
    jobManagerLoaded: false,
  })),

  on(SignalRActionTypes.jobManagerFeaturesMessageReceived, (state, { jobManagerFeatures }) => ({
    ...state,
    jobManagerFeatures,
    jobManagerLoaded: true,
  })),

  on(SettingsActions.loadJobSettingsSuccess, (state, { jobManagerFeatures }) => {
    const jmSettings: JobManagerFeatures = {
      ...state.jobManagerFeatures,
      ...jobManagerFeatures,
    };

    return {
      ...state,
      jobManagerFeatures: jmSettings,
      jobManagerLoaded: true,
    };
  }),

  on(SettingsActions.loadJobSettingsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    jobManagerLoaded: false,
  })),

  on(LoadTypeSettingsActions.loadLoadTypesSuccess, (state, { configurations }) => {
    return {
      ...state,
      loadTypeSettings: {
        configurations,
        loaded: true,
      },
    };
  }),

  on(LoadTypeSettingsActions.loadLoadTypesFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadTypeSettings: {
      configurations: [],
      loaded: false,
    },
  })),

  on(SettingsActions.loadIpstSettings, state => ({
    ...state,
    errorMessage: '',
    ipstLoaded: false,
  })),

  on(SettingsActions.loadIpstSettingsSuccess, (state, { ipstServiceFeatures }) => {
    return {
      ...state,
      ipstServiceFeatures: ipstServiceFeatures,
      ipstLoaded: true,
    };
  }),

  on(SettingsActions.loadIpstSettingsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    ipstLoaded: false,
  })),

  on(SettingsActions.resolveAllIpstMessages, state => ({
    ...state,
    errorMessage: '',
  })),

  on(SettingsActions.resolveAllIpstMessagesSuccess, state => {
    return {
      ...state,
      resolveAllIpstMessages: true,
    };
  }),

  on(SettingsActions.resolveAllIpstMessagesFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  })),

  on(SettingsActions.loadLastIpstMessageResolve, state => ({
    ...state,
    errorMessage: '',
  })),

  on(SettingsActions.loadLastIpstMessageResolveSuccess, (state, { lastMessageResolve }) => {
    return {
      ...state,
      ipstLastMessageResolved: lastMessageResolve,
    };
  }),

  on(SettingsActions.loadLastIpstMessageResolveFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
  })),

  on(SettingsActions.loadOrderGatewaySettings, state => ({
    ...state,
    errorMessage: '',
    orderGatewayLoaded: false,
  })),

  on(SettingsActions.loadOrderGatewaySettingsSuccess, (state, { orderGatewayFeatures }) => {
    return {
      ...state,
      orderGatewayFeatures,
      orderGatewayLoaded: true,
    };
  }),

  on(SettingsActions.loadOrderGatewaySettingsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    orderGatewayLoaded: false,
  })),

  on(SettingsActions.loadMapSettings, state => ({
    ...state,
    errorMessage: '',
    mapManagerLoaded: false,
  })),

  on(
    SettingsActions.loadMapSettingsSuccess,
    SignalRActionTypes.mapManagerFeaturesMessageReceived,
    (state, { mapManagerFeatures }) => {
      const mapSettings: MapManagerFeatures = {
        ...state.mapManagerFeatures,
        ...mapManagerFeatures,
      };

      return {
        ...state,
        mapManagerFeatures: mapSettings,
        mapManagerLoaded: true,
      };
    }
  ),

  on(SettingsActions.loadMapSettingsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    mapManagerLoaded: false,
  })),

  on(SettingsActions.loadInfobarMessage, state => ({
    ...state,
    errorMessage: '',
    infobarMessageLoaded: false,
  })),

  on(
    SettingsActions.loadInfobarMessageSuccess,
    SignalRActionTypes.infobarMessageReceived,
    (state, { infobarMessage }) => ({
      ...state,
      infobarMessage,
      infobarMessageLoaded: true,
    })
  ),

  on(SettingsActions.updateEmulatorSettingsSuccess, (state, { emulatorFeature }) => {
    const emulatorSettings = {
      ...state.emulatorHostFeatures,
      enableAddingEmulators: emulatorFeature.enableAddingEmulators,
      speedFactor: emulatorFeature.speedFactor,
      enableAwarenessField: emulatorFeature.enableAwarenessField,
      enableCompletePath: emulatorFeature.enableCompletePath,
    };

    return {
      ...state,
      emulatorHostFeatures: emulatorSettings,
    };
  }),

  on(SettingsActions.updateEvacuationDeviceSettingsSuccess, (state, { device }) => {
    const evacuationDeviceSettings = {
      ...state.evacuationDeviceSettings,
      device: device,
    };

    return {
      ...state,
      evacuationDeviceSettings: evacuationDeviceSettings,
    };
  }),

  on(SettingsActions.loadInfobarMessageFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    infobarMessageLoaded: false,
  })),

  on(SettingsActions.retrieveAllFireFighterSettings, state => ({
    ...state,
    errorMessage: '',
    evacuationModeFireFighterTogglesLoaded: false,
  })),

  on(SettingsActions.retrieveAllFireFighterSettingsSuccess, (state, { result }) => ({
    ...state,
    evacuationModeFireFighterToggles: result,
    evacuationModeFireFighterTogglesLoaded: true,
  })),

  on(SettingsActions.retrieveAllFireFighterSettingsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    evacuationModeFireFighterTogglesLoaded: false,
  })),

  on(
    SettingsActions.updateEmulatorSettingsFailure,
    SettingsActions.updateEvacuationDeviceSettingsFailure,
    SettingsActions.toggleParkingAndChargingFailure,
    SettingsActions.toggleWaitOnPoiFailure,
    SettingsActions.toggleServiceZonesFailure,
    SettingsActions.toggleAssignmentTriggerFailure,
    SettingsActions.toggleEndOfShiftModeFailure,
    SettingsActions.toggleShutDownModeFailure,
    SettingsActions.toggleCollectivePauseFailure,
    SettingsActions.toggleEvacuationModeWorkingAreaFailure,
    SettingsActions.toggleEvacuationModeFireFighterFailure,
    SettingsActions.retrieveAllFireFighterSettingsFailure,
    SettingsActions.toggleAmaSettingsFailure,
    SettingsActions.toggleWaitingQueueSettingsFailure,
    SettingsActions.toggleTrafficSettingsFailure,
    SettingsActions.toggleAvoidConflictAtPoisFailure,
    SettingsActions.toggleIncludeTuggerTrainErrorsFailure,
    SettingsActions.togglePktInterfaceFailure,
    SettingsActions.updateMissionErrorHandlingSettingsFailure,
    SettingsActions.toggleEnableGraphManagerFailure,
    SettingsActions.updateGraphManagerParkingAndChargingSettingsFailure,
    SettingsActions.restartGraphManagerFailure,
    SettingsActions.toggleBeginShiftModeFailure,
    LoadTypeSettingsActions.loadLoadTypesFailure,
    LoadTypeSettingsActions.updateLoadTypeFailure,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
    })
  ),

  on(SettingsActions.toggleEndOfShiftModeSuccess, (state, { result }) => {
    const jmSettings: JobManagerFeatures = {
      ...state.jobManagerFeatures,
      endOfShiftModeToggle: result,
    };

    return {
      ...state,
      jobManagerFeatures: jmSettings,
    };
  }),

  on(SettingsActions.toggleMatrixOptimizationSuccess, (state, { result }) => {
    const jmSettings: JobManagerFeatures = {
      ...state.jobManagerFeatures,
      matrixOptimizationToggle: result,
    };

    return {
      ...state,
      jobManagerFeatures: jmSettings,
    };
  }),

  on(SettingsActions.toggleBrakeTestSuccess, (state, { result }) => {
    const jmSettings: JobManagerFeatures = {
      ...state.jobManagerFeatures,
      brakeTestToggle: result,
    };

    return {
      ...state,
      jobManagerFeatures: jmSettings,
    };
  }),

  on(SettingsActions.toggleEvacuationModeWorkingAreaSuccess, (state, { result }) => {
    const fmSettings: FleetManagerFeatures = {
      ...state.fleetManagerFeatures,
      evacuationModeToggle: result,
    };

    return {
      ...state,
      fleetManagerFeatures: fmSettings,
    };
  }),

  on(SettingsActions.toggleEvacuationModeFireFighterSuccess, (state, { result }) => {
    const update: EvacModeToggleFireFighterGetResponse = {
      key: 'FireFighterEvacuationMode',
      isToggledOn: result.isToggledOn,
      workAreaId: result.workAreaId,
      modifiedUtc: result.dateUpdated,
    };
    const ffToggles = mergeOrAppend(
      state.evacuationModeFireFighterToggles,
      update,
      it => it.workAreaId,
      (existing, update) => ({
        ...existing,
        ...update,
      })
    );
    return {
      ...state,
      evacuationModeFireFighterToggles: [...ffToggles],
    };
  }),
  on(SettingsActions.toggleShutDownModeSuccess, (state, { result }) => {
    const fmSettings: FleetManagerFeatures = {
      ...state.fleetManagerFeatures,
      shutdownModeToggle: state.fleetManagerFeatures.shutdownModeToggle.map(s => {
        if (s.vehicleShutdownType === result.vehicleShutdownType) {
          return result;
        }
        return s;
      }),
    };

    return {
      ...state,
      fleetManagerFeatures: fmSettings,
    };
  }),

  on(SettingsActions.toggleCollectivePauseSuccess, (state, { result }) => {
    const fmSettings: FleetManagerFeatures = {
      ...state.fleetManagerFeatures,
      collectivePauseToggle: result,
    };

    return {
      ...state,
      fleetManagerFeatures: fmSettings,
    };
  }),

  on(SettingsActions.toggleParkingAndChargingSuccess, (state, { result }) => {
    const jmSettings: JobManagerFeatures = {
      ...state.jobManagerFeatures,
      parkingAndChargingModeToggle: result,
    };

    return {
      ...state,
      jobManagerFeatures: jmSettings,
    };
  }),

  on(
    SettingsActions.toggleLatestDeliveryTimeAssignmentSuccess,
    (state, { graphManagerFeatures }) => {
      const settings = {
        ...state.graphManagerFeatureSettings.settings,
        enableLatestDeliveryTimeAssignment: graphManagerFeatures.isToggledOn,
      };
      const graphManagerFeatureSettings = {
        ...state.graphManagerFeatureSettings,
        settings,
        latestDeliveryTimeAssignmentEnabledModifiedUtc: graphManagerFeatures.dateUpdated,
      };
      return {
        ...state,
        graphManagerFeatureSettingsLoaded: true,
        graphManagerFeatureSettings,
      };
    }
  ),

  on(SettingsActions.toggleWaitOnPoiSuccess, (state, { result }) => {
    const jmSettings: JobManagerFeatures = {
      ...state.jobManagerFeatures,
      waitOnPoiToggle: result,
    };

    return {
      ...state,
      jobManagerFeatures: jmSettings,
    };
  }),

  on(SettingsActions.toggleServiceZonesSuccess, (state, { result }) => {
    const jmSettings: JobManagerFeatures = {
      ...state.jobManagerFeatures,
      serviceZonesToggle: result,
    };

    return {
      ...state,
      jobManagerFeatures: jmSettings,
    };
  }),

  on(SettingsActions.toggleAssignmentTriggerSuccess, (state, { result }) => {
    const jmSettings: JobManagerFeatures = {
      ...state.jobManagerFeatures,
      assignmentTriggerToggle: result,
    };

    return {
      ...state,
      jobManagerFeatures: jmSettings,
    };
  }),

  on(LoadTypeSettingsActions.updateLoadTypeSuccess, (state, { configuration }) => {
    const configurations = state.loadTypeSettings.configurations;
    const updatedConfigurations = configurations.map(lt => {
      if (lt.id === configuration.id) {
        return configuration;
      }

      return lt;
    });

    return {
      ...state,
      loadTypeSettings: {
        configurations: updatedConfigurations,
        loaded: true,
      },
    };
  }),

  on(SettingsActions.toggleAmaSettingsSuccess, (state, { result }) => {
    const jmSettings: JobManagerFeatures = {
      ...state.jobManagerFeatures,
      amaSettingModeToggle: result,
    };

    return {
      ...state,
      jobManagerFeatures: jmSettings,
    };
  }),

  on(SettingsActions.toggleWaitingQueueSettingsSuccess, (state, { result }) => {
    const jmSettings: JobManagerFeatures = {
      ...state.jobManagerFeatures,
      waitingQueueModeToggle: result,
    };

    return {
      ...state,
      jobManagerFeatures: jmSettings,
    };
  }),

  on(SettingsActions.toggleTrafficSettingsSuccess, (state, { result }) => {
    const tmSettings: TrafficManagerFeatures = {
      ...state.trafficManagerFeatures,
      trafficManagementToggle: {
        ...state.trafficManagerFeatures.trafficManagementToggle,
        dateUpdated: result.dateUpdated,
        isToggledOn: result.isToggledOn,
      },
    };

    return {
      ...state,
      trafficManagerFeatures: tmSettings,
    };
  }),

  on(SettingsActions.toggleAvoidConflictAtPoisSuccess, (state, { result }) => {
    const tmSettings: TrafficManagerFeatures = {
      ...state.trafficManagerFeatures,
      avoidConflictsAtPoisToggle: result,
    };

    return {
      ...state,
      trafficManagerFeatures: tmSettings,
    };
  }),

  on(SettingsActions.togglePktInterfaceSuccess, (state, { result }) => {
    const orderGatewaySettings: OrderGatewayFeatures = {
      ...state.orderGatewayFeatures,
      pktInterfaceToggle: result,
    };

    return {
      ...state,
      orderGatewayFeatures: orderGatewaySettings,
    };
  }),

  on(SettingsActions.toggleIncludeTuggerTrainErrorsSuccess, (state, { result }) => {
    const ipstSettings: IpstServiceFeatures = {
      ...state.ipstServiceFeatures,
      includeTuggerTrainErrorsToggle: result,
    };

    return {
      ...state,
      ipstServiceFeatures: ipstSettings,
    };
  }),

  on(SettingsActions.setToggleIncludeTuggerTrainErrors, (state, { result }) => {
    const ipstSettings: IpstServiceFeatures = {
      ...state.ipstServiceFeatures,
      includeTuggerTrainErrorsToggle: result,
    };

    return {
      ...state,
      ipstServiceFeatures: ipstSettings,
    };
  }),

  on(SettingsActions.toggleEnableVehicleSmoothingSuccess, (state, { result }) => {
    const mapSettings: MapManagerFeatures = {
      ...state.mapManagerFeatures,
      enableVehicleSmoothingToggle: result,
    };

    return {
      ...state,
      mapManagerFeatures: mapSettings,
    };
  }),

  on(SettingsActions.updateMissionErrorHandlingSettingsSuccess, (state, { result }) => {
    const jobSettings: JobManagerFeatures = {
      ...state.jobManagerFeatures,
      missionErrorHandlingDefaultsToggle: result,
    };

    return {
      ...state,
      jobManagerFeatures: jobSettings,
    };
  }),
  on(SettingsActions.loadGraphManagerSettings, state => ({
    ...state,
    errorMessage: '',
    graphManagerFeatureSettingsLoaded: false,
  })),

  on(SettingsActions.loadGraphManagerSettingsSuccess, (state, { graphManagerFeatures }) => {
    return {
      ...state,
      graphManagerFeatureSettingsLoaded: true,
      graphManagerFeatureSettings: graphManagerFeatures,
    };
  }),

  on(SettingsActions.toggleEnableGraphManagerSuccess, (state, { graphManagerFeatures }) => {
    const settings = {
      ...state.graphManagerFeatureSettings.settings,
      enableGraphManager: graphManagerFeatures.isToggledOn,
    };
    const graphManagerFeatureSettings = {
      ...state.graphManagerFeatureSettings,
      settings,
      graphManagerEnabledUpdatedTimeUtc: graphManagerFeatures.dateUpdated,
    };
    return {
      ...state,
      graphManagerFeatureSettingsLoaded: true,
      graphManagerFeatureSettings,
    };
  }),

  on(SettingsActions.toggleBeginShiftModeSuccess, (state, { graphManagerFeatures }) => {
    const settings = {
      ...state.graphManagerFeatureSettings.settings,
      isBeginShiftModeEnabled: graphManagerFeatures.isToggledOn,
    };
    const graphManagerFeatureSettings = {
      ...state.graphManagerFeatureSettings,
      settings,
      isBeginShiftModeEnabledUpdatedTimeUtc: graphManagerFeatures.dateUpdated,
    };
    return {
      ...state,
      graphManagerFeatureSettingsLoaded: true,
      graphManagerFeatureSettings,
    };
  }),

  on(SettingsActions.loadGraphManagerSettingsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    graphManagerFeatureSettingsLoaded: false,
  })),

  on(SettingsActions.loadGraphManagerParkingAndChargingSettings, state => ({
    ...state,
    errorMessage: '',
    graphManagerParkingAndChargingSettingsLoaded: false,
  })),

  on(
    SettingsActions.loadGraphManagerParkingAndChargingSettingsSuccess,
    (state, { parkingAndChargingSettings }) => {
      return {
        ...state,
        graphManagerParkingAndChargingSettingsLoaded: true,
        graphManagerParkingAndChargingSettings: parkingAndChargingSettings,
      };
    }
  ),

  on(SettingsActions.loadGraphManagerSettingsFailure, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    graphManagerParkingAndChargingSettingsLoaded: false,
  })),

  on(
    SettingsActions.updateGraphManagerParkingAndChargingSettingsSuccess,
    (state, { parkingAndChargingSettings }) => {
      return {
        ...state,
        graphManagerParkingAndChargingSettingsLoaded: true,
        graphManagerParkingAndChargingSettings: parkingAndChargingSettings,
      };
    }
  )
);

export function reducer(state: SettingsState | undefined, action: Action): SettingsState {
  return settingsReducer(state, action);
}

export const getSettingsState = createFeatureSelector<SettingsState>(featureKey);

export const getErrorMessage = (state: SettingsState): string => state.errorMessage;
export const getFleetManagerFeatures = (state: SettingsState): FleetManagerFeatures =>
  state.fleetManagerFeatures;
export const getJobFeatures = (state: SettingsState): JobManagerFeatures =>
  state.jobManagerFeatures;
export const getLoadTypeSettings = (state: SettingsState): LoadTypeSettingsState =>
  state.loadTypeSettings;
export const getTrafficManagerFeatures = (state: SettingsState): TrafficManagerFeatures =>
  state.trafficManagerFeatures;
export const getZoneManagementSettings = (state: SettingsState): ZoneManagementSettings =>
  state.zoneManagerSettings;
export const getEmulatorManagerFeatures = (state: SettingsState): EmulatorHostFeaturesDto =>
  state.emulatorHostFeatures;
export const getEvacuationDeviceSettings = (state: SettingsState): EvacuationDeviceDto[] =>
  state.evacuationDeviceSettings;
export const getMapFeatures = (state: SettingsState): MapManagerFeatures =>
  state.mapManagerFeatures;
export const getShutdownRequest = (state: SettingsState): ShutdownRequestDto[] =>
  state.shutdownRequest;
export const getInfobarMessage = (state: SettingsState): InfobarMessageResponse | null =>
  state.infobarMessage;
export const getIpstServiceFeatures = (state: SettingsState): IpstServiceFeatures =>
  state.ipstServiceFeatures;
export const getIpstLastMessageResolved = (state: SettingsState): DateString =>
  state.ipstLastMessageResolved;
export const getOrderGatewayFeatures = (state: SettingsState): OrderGatewayFeatures =>
  state.orderGatewayFeatures;
export const getFleetLoaded = (state: SettingsState): boolean => state.fleetLoaded;
export const getTrafficLoaded = (state: SettingsState): boolean => state.trafficLoaded;
export const getZoneManagementLoaded = (state: SettingsState): boolean => state.zoneSettingsLoaded;
export const getEmulatorLoaded = (state: SettingsState): boolean => state.emulatorLoaded;
export const getEvacuationDeviceSettingsLoaded = (state: SettingsState): boolean =>
  state.evacuationDevicesLoaded;
export const getIpstLoaded = (state: SettingsState): boolean => state.ipstLoaded;
export const getOrderGatewayFeaturesLoaded = (state: SettingsState): boolean =>
  state.orderGatewayLoaded;

export const getJobManagerLoaded = (state: SettingsState): boolean => state.jobManagerLoaded;
export const getLoadTypesLoaded = (state: SettingsState): boolean => state.loadTypeSettings.loaded;
export const getMapManagerLoaded = (state: SettingsState): boolean => state.mapManagerLoaded;
export const getShutdownRequestLoaded = (state: SettingsState): boolean =>
  state.shutdownRequestLoaded;
export const getInfobarMessageLoaded = (state: SettingsState): boolean =>
  state.infobarMessageLoaded;
export const getIsFleetSettingsUpdateFailed = (state: SettingsState): boolean =>
  state.isFleetSettingsFailed;
export const getIsJobSettingsUpdateFailed = (state: SettingsState): boolean =>
  state.isJobSettingsFailed;
export const getIsMapSettingsUpdateFailed = (state: SettingsState): boolean =>
  state.isMapSettingsFailed;
export const getIsTrafficSettingsUpdateFailed = (state: SettingsState): boolean =>
  state.isTrafficSettingsFailed;
export const getIsShutdownModeUpdateFailed = (state: SettingsState): boolean =>
  state.isShutdownModeFailed;
export const getGraphManagerFeatureSettingsLoaded = (state: SettingsState): boolean =>
  state.graphManagerFeatureSettingsLoaded;
export const getGraphManagerFeatureSettings = (state: SettingsState): GraphManagerSettingsDto =>
  state.graphManagerFeatureSettings;
export const getGraphManagerParkingAndChargingSettingsLoaded = (state: SettingsState): boolean =>
  state.graphManagerParkingAndChargingSettingsLoaded;
export const getGraphManagerParkingAndChargingSettings = (
  state: SettingsState
): GmParkingAndChargingSettingsDto => state.graphManagerParkingAndChargingSettings;
export const getEvacuationModeFireFighter = (
  state: SettingsState
): EvacModeToggleFireFighterGetResponse[] => state.evacuationModeFireFighterToggles;
export const getEvacuationModeFireFighterLoaded = (state: SettingsState): boolean =>
  state.evacuationModeFireFighterTogglesLoaded;
