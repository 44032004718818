import { Dictionary } from '@ngrx/entity';
import { DATE_FORMAT } from 'core/constants';
import {
  MapDto,
  NavigationLayerResponseModel,
  VehicleDto,
  VehicleMap,
  VehicleMapDataDto,
  VehicleMapDataListItemModel,
} from 'core/dtos';
import { GuidString, MapStatus, VehicleGroup } from 'core/models';
import { isEmpty } from 'lodash';
import { formatDate } from 'shared/helpers';

const DELETED_DATA = 'mapData.list.deletedData';

export const convertToVehicleDataListItem = (
  vehicleMapData: VehicleMapDataDto[],
  maps: MapDto[],
  navigationLayerEntities: Dictionary<NavigationLayerResponseModel>,
  vehicleEntities: Dictionary<VehicleDto>
): VehicleMapDataListItemModel[] => {
  if (!isEmpty(vehicleMapData) && !isEmpty(maps) && !isEmpty(navigationLayerEntities))
    return vehicleMapData.map(vmd =>
      setVehicleMapData(vmd, maps, navigationLayerEntities, vehicleEntities)
    );
  return [];
};

export const setVehicleMapData = (
  vehicleMapData: VehicleMapDataDto,
  maps: MapDto[],
  navigationLayerEntities: Dictionary<NavigationLayerResponseModel>,
  vehicleEntities: Dictionary<VehicleDto>
): VehicleMapDataListItemModel => {
  const mapName = getMapName(vehicleMapData.navigationLayerId, navigationLayerEntities, maps);
  const mapId = getMapId(vehicleMapData.navigationLayerId, navigationLayerEntities, maps);

  const navigationLayerName = getNavigationLayerName(
    vehicleMapData.navigationLayerId,
    navigationLayerEntities
  );

  const vehicleGroup = getVehicleGroup(vehicleMapData.navigationLayerId, navigationLayerEntities);

  return {
    id: vehicleMapData.id,
    name: vehicleMapData.name,
    vehicleGroup,
    fileName: vehicleMapData.fileName,
    blobUrl: vehicleMapData.blobUrl,
    mapId,
    mapName,
    navigationLayerName: navigationLayerName,
    navigationLayerId: vehicleMapData.navigationLayerId,
    vehicleId: vehicleMapData.vehicleId,
    vehicleName: getVehicleName(vehicleMapData.vehicleId, vehicleEntities),
    createdOnUtc: formatDate(vehicleMapData.createdOnUtc, DATE_FORMAT.FULL_DATE_TIME),
    hasDeletedData: mapName === DELETED_DATA || navigationLayerName === DELETED_DATA ? true : false,
    mapVersion: vehicleMapData.mapVersion,
    status: vehicleMapData.status,
    hardwareVersion: vehicleMapData.hardwareVersion,
  };
};

export function isSameMap(
  dataItem: VehicleMapDataListItemModel | undefined,
  map: VehicleMap
): boolean {
  const mapVersion = map.mapVersion;
  const vehicleMapId = map.mapId?.toString().split(';')[0];

  return `${mapVersion};${vehicleMapId}` === `${dataItem?.mapVersion};${dataItem?.mapId}`;
}

export function isCurrentActiveMap(
  mapId: GuidString | undefined,
  vehicleCompositeKey: GuidString | string | undefined,
  mapStatus: MapStatus
): boolean {
  if (!mapId || !vehicleCompositeKey) return false;
  let vehicleMapId: string;
  if (vehicleCompositeKey.includes(';')) {
    vehicleMapId = vehicleCompositeKey.split(';')[0];
  } else {
    vehicleMapId = vehicleCompositeKey.toString();
  }

  return mapId.toString() === vehicleMapId && mapStatus === MapStatus.ENABLED;
}

const getMapName = (
  navigationLayerId: GuidString = '',
  navigationLayerEntities: Dictionary<NavigationLayerResponseModel>,
  maps: MapDto[]
): string => {
  const navigationLayer = navigationLayerEntities[String(navigationLayerId)];
  const map = maps.find(i => i.id === navigationLayer?.mapId);
  return map?.name ?? DELETED_DATA;
};

const getMapId = (
  navigationLayerId: GuidString = '',
  navigationLayerEntities: Dictionary<NavigationLayerResponseModel>,
  maps: MapDto[]
): GuidString => {
  const navigationLayer = navigationLayerEntities[String(navigationLayerId)];
  const map = maps.find(i => i.id === navigationLayer?.mapId);
  return map?.id ?? DELETED_DATA;
};

const getNavigationLayerName = (
  navigationLayerId: GuidString = '',
  navigationLayerEntities: Dictionary<NavigationLayerResponseModel>
): string => {
  return navigationLayerEntities[String(navigationLayerId)]?.name ?? DELETED_DATA;
};

const getVehicleName = (
  vehicleId: GuidString = '',
  vehicleEntities: Dictionary<VehicleDto>
): string => {
  return vehicleEntities[String(vehicleId)]?.name ?? DELETED_DATA;
};

const getVehicleGroup = (
  navigationLayerId: GuidString = '',
  navigationLayerEntities: Dictionary<NavigationLayerResponseModel>
): VehicleGroup | undefined => {
  return navigationLayerEntities[String(navigationLayerId)]?.vehicleGroup;
};
