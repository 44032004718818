/* eslint-disable max-lines */
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DsSelectLabellingConfiguration } from '@bmw-ds/components/ds-interfaces/select/config.interface';
import { select, Store } from '@ngrx/store';
import { TourTriggerType } from 'core/dtos';
import { HardwareVersion, ProcessChainType, StepType, VehicleType } from 'core/models';
import { AtsTranslationService } from 'core/services';
import { isNumber } from 'lodash';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import * as fromSettings from 'store-modules/settings-store';

@Component({
  selector: 'app-step-type-selection',
  templateUrl: './step-type-selection.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StepTypeSelectionComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepTypeSelectionComponent
  implements ControlValueAccessor, OnInit, OnDestroy, OnChanges
{
  @Input() chainType?: ProcessChainType = undefined;
  @Input() tourTrigger?: TourTriggerType = undefined;
  @Input() isSapStepTypeSet = false;
  @Input() disabled? = false;
  @Input() vehicleType: VehicleType = VehicleType.UnitLoad;
  @Input() hardwareVersion?: HardwareVersion;
  @Input() disabledOptions: StepType[] = [];

  selectedStepType: string | undefined;
  removableItems: StepType[] = [];
  enableAmaSettings = false;
  enablePktSettings = false;
  ngUnsubscribe = new Subject<void>();
  labellingConfig: DsSelectLabellingConfiguration = {
    noResultsMessage: this.translate.get('placeholders.selectEmpty'),
    placeholder: this.translate.get('placeholders.select'),
    selectAll: this.translate.get('placeholders.selectAll'),
  };
  private readonly removableItemsForStepsWithoutPC: StepType[] = [
    StepType.GoToMapping,
    StepType.DockToMapping,
    StepType.DeliverToMapping,
    StepType.Unknown,
    StepType.WaitForDevice,
    StepType.WaitForScanDestination,
    StepType.WaitForTrigger,
    StepType.WaitForSapAcknowledgement,
    StepType.ReleaseForTrigger,
    StepType.FinePositioning,
    StepType.WaitForEndOfStep,
    StepType.GotoPose,
    StepType.SAP,
    StepType.GotoAndDrop,
    StepType.GotoAndLift,
    StepType.PrepareForLift,
  ];

  private readonly removableItemsForStepsWithPCWithAma: StepType[] = [
    StepType.GoToMapping,
    StepType.DockToMapping,
    StepType.DeliverToMapping,
    StepType.Unknown,
    StepType.GotoPose,
    StepType.SAP,
    StepType.StopToCharge,
    StepType.FinePositioning,
    StepType.WaitForTrigger,
    StepType.ReleaseForTrigger,
    StepType.GotoAndDrop,
    StepType.GotoAndLift,
    StepType.PrepareForLift,
  ];

  private readonly removableItemsForStepsWithPCWithAmaAndWithPkt: StepType[] = [
    StepType.GoToMapping,
    StepType.DockToMapping,
    StepType.DeliverToMapping,
    StepType.Unknown,
    StepType.GotoPose,
    StepType.SAP,
    StepType.WaitForSapAcknowledgement,
    StepType.StopToCharge,
    StepType.FinePositioning,
    StepType.WaitForTrigger,
    StepType.ReleaseForTrigger,
    StepType.GotoAndDrop,
    StepType.GotoAndLift,
    StepType.PrepareForLift,
  ];

  private readonly removableItemsForStepsWithPCWithoutAma: StepType[] = [
    StepType.GoToMapping,
    StepType.DockToMapping,
    StepType.DeliverToMapping,
    StepType.Unknown,
    StepType.WaitForDevice,
    StepType.WaitForScanDestination,
    StepType.WaitForSapAcknowledgement,
    StepType.GotoPose,
    StepType.SAP,
    StepType.StopToCharge,
    StepType.FinePositioning,
    StepType.WaitForTrigger,
    StepType.ReleaseForTrigger,
    StepType.GotoAndDrop,
    StepType.GotoAndLift,
    StepType.PrepareForLift,
  ];

  private readonly tuggerTrainManualStepTypes = [StepType.Goto, StepType.GotoAndPushSideButton];

  private readonly tuggerTrainStepTypes = [...this.tuggerTrainManualStepTypes, StepType.SAP];

  private readonly dsUgvManualStepTypes = [
    StepType.Goto,
    StepType.GotoAndLift,
    StepType.GotoAndDrop,
  ];

  private readonly dsUgvTourConfigStepTypes = [
    StepType.Lift,
    StepType.Goto,
    StepType.Drop,
    StepType.WaitForEndOfStep,
    StepType.WaitForDevice,
    StepType.WaitForSapAcknowledgement,
    StepType.PrepareForLift,
  ];

  private readonly dsUgvStepTypes = [
    StepType.Lift,
    StepType.Goto,
    StepType.Drop,
    StepType.WaitForDevice,
    StepType.WaitForSapAcknowledgement,
    StepType.WaitForEndOfStep,
    StepType.PrepareForLift,
  ];

  private readonly forkliftManualStepTypes = [StepType.Goto];

  private readonly forkliftTourConfigStepTypes = [
    StepType.Lift,
    StepType.Goto,
    StepType.Drop,
    StepType.WaitForEndOfStep,
    StepType.WaitForDevice,
    StepType.WaitForSapAcknowledgement,
  ];

  private readonly forkliftStepTypes = [
    StepType.Lift,
    StepType.Goto,
    StepType.Drop,
    StepType.WaitForDevice,
    StepType.WaitForSapAcknowledgement,
    StepType.WaitForEndOfStep,
  ];

  constructor(
    private readonly settingsStore: Store<fromSettings.SettingsFeatureState>,
    private readonly translate: AtsTranslationService
  ) {}

  ngOnChanges(): void {
    this.setRemovableItems();
  }

  ngOnInit(): void {
    this.settingsStore
      .pipe(
        select(fromSettings.selectJobSettings),
        map(settings => settings.amaSettingModeToggle.isToggledOn),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(enableAmaSettings => {
        if (enableAmaSettings !== this.enableAmaSettings) {
          this.enableAmaSettings = enableAmaSettings;
          this.setRemovableItems();
        }
      });
    this.settingsStore
      .pipe(
        select(fromSettings.selectOrderGatewayFeatures),
        map(settings => settings.pktInterfaceToggle.isToggledOn),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(enablePktSettings => {
        if (enablePktSettings !== this.enablePktSettings) {
          this.enablePktSettings = enablePktSettings;
          this.setRemovableItems();
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  writeValue(displayStepType: StepType | undefined): void {
    this.selectedStepType = displayStepType?.toString();
    this.setRemovableItems();
  }

  registerOnChange(fn: () => {}): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  onChanged = (_value: StepType): void => {};
  onTouched = (_value: StepType): void => {};

  onChangeStepType(): void {
    const stepType = Number(this.selectedStepType);
    if (stepType !== undefined) {
      this.onTouched(stepType);
      this.setRemovableItems();
      this.onChanged(stepType);
    }
  }

  setRemovableItems(): void {
    switch (this.chainType) {
      case undefined:
        if (
          (this.vehicleType === VehicleType.TuggerTrain &&
            this.hardwareVersion === HardwareVersion.TuggerTrainDsV1) ||
          this.vehicleType === VehicleType.Forklift ||
          this.vehicleType === VehicleType.U_AGV
        ) {
          this.removableItems = this.getAllStepTypesForManualTourTriggers();
          break;
        }
        this.removableItems = this.removableItemsForStepsWithoutPC;
        break;
      case ProcessChainType.WaitingQueue:
        this.removableItems = this.getAllStepTypesExceptGoTo();
        break;
      case ProcessChainType.TourConfig:
        this.removableItems = this.getAllStepTypesForTours();
        if (
          this.tourTrigger === TourTriggerType.AdHocFromTourConfiguration ||
          this.isSapStepTypeSet ||
          this.tourTrigger === TourTriggerType.BeginShiftTour ||
          (this.tourTrigger === TourTriggerType.CallOff &&
            this.vehicleType === VehicleType.TuggerTrain)
        ) {
          this.removableItems = this.getAllStepTypesForTourConfigTriggers();
        }
        break;
      case ProcessChainType.Basic:
      default:
        if (this.enableAmaSettings && this.enablePktSettings) {
          this.removableItems = this.removableItemsForStepsWithPCWithAmaAndWithPkt;
        } else if (this.enableAmaSettings && !this.enablePktSettings) {
          this.removableItems = this.removableItemsForStepsWithPCWithAma;
        } else {
          this.removableItems = this.removableItemsForStepsWithPCWithoutAma;
        }
        break;
    }
    this.displayInitiallySelectedStepType();
  }

  getAllStepTypesExceptGoTo(stepType: StepType = StepType.Goto): StepType[] {
    return Object.values(StepType)
      .filter(isNumber)
      .filter(t => t !== stepType);
  }

  getAllStepTypesForTours(): StepType[] {
    return Object.values(StepType)
      .filter(isNumber)
      .filter(
        t =>
          (this.vehicleType === VehicleType.TuggerTrain &&
            !this.tuggerTrainStepTypes.includes(t)) ||
          (this.vehicleType === VehicleType.Forklift && !this.forkliftStepTypes.includes(t)) ||
          (this.vehicleType === VehicleType.U_AGV && !this.dsUgvStepTypes.includes(t))
      );
  }

  getAllStepTypesForManualTourTriggers(): StepType[] {
    return Object.values(StepType)
      .filter(isNumber)
      .filter(
        t =>
          (this.vehicleType === VehicleType.TuggerTrain &&
            !this.tuggerTrainManualStepTypes.includes(t)) ||
          (this.vehicleType === VehicleType.Forklift &&
            !this.forkliftManualStepTypes.includes(t)) ||
          (this.vehicleType === VehicleType.U_AGV && !this.dsUgvManualStepTypes.includes(t))
      );
  }

  getAllStepTypesForTourConfigTriggers(): StepType[] {
    return Object.values(StepType)
      .filter(isNumber)
      .filter(
        t =>
          (this.vehicleType === VehicleType.TuggerTrain &&
            !this.tuggerTrainManualStepTypes.includes(t)) ||
          (this.vehicleType === VehicleType.Forklift &&
            !this.forkliftTourConfigStepTypes.includes(t)) ||
          (this.vehicleType === VehicleType.U_AGV && !this.dsUgvTourConfigStepTypes.includes(t))
      );
  }

  displayInitiallySelectedStepType(): void {
    this.removableItems = this.removableItems.filter(
      stepType => stepType !== Number(this.selectedStepType)
    );
  }
}
