import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { SignalRNextService } from '../signalr-next.service';
import { SignalrRoutes } from '../signalr-routes';
import { Subject } from 'rxjs';
import { AlertNowTabDto, GuidString, SignalRNextMessage } from 'core/models';
import {
  AlertNowGroupMessage,
  ErrorForwardingMessage,
  IncludeTuggerTrainErrorsToggleEventDto,
  IpstAlertNowConnectionSettingsDto,
  LastResolveMessage,
} from 'core/dtos';
import { Store } from '@ngrx/store';
import * as fromRoot from 'store/index';
import * as fromSettings from 'store-modules/settings-store';

@Injectable({
  providedIn: 'root',
})
export class IpstSignalRService {
  errorForwardingMessageReceivedNext = new Subject<SignalRNextMessage<ErrorForwardingMessage>>();
  ipstSettingNext = new Subject<SignalRNextMessage<IpstAlertNowConnectionSettingsDto>>();
  includeTuggerTrainToggleNext = new Subject<
    SignalRNextMessage<IncludeTuggerTrainErrorsToggleEventDto>
  >();
  alertNowSettingNext = new Subject<SignalRNextMessage<AlertNowTabDto>>();
  alertNowGroupNext = new Subject<SignalRNextMessage<AlertNowGroupMessage>>();
  lastResolveNext = new Subject<SignalRNextMessage<LastResolveMessage>>();

  private readonly changedErrorForwardings: WritableSignal<ErrorForwardingMessage | undefined> =
    signal(undefined);
  private readonly changedIpstSetting: WritableSignal<
    IpstAlertNowConnectionSettingsDto | undefined
  > = signal(undefined);

  private readonly changedAlertNowSetting: WritableSignal<AlertNowTabDto | undefined> =
    signal(undefined);

  private readonly changedAlertNowGroups: WritableSignal<AlertNowGroupMessage | undefined> =
    signal(undefined);

  constructor(
    private readonly signalRNextService: SignalRNextService,
    private readonly rootStore: Store<fromRoot.RootState>
  ) {
    this.registerConnections();
  }

  signalrSubscriberFactory(componentName: string): IpstSignalrSubscriber {
    const joinErrorForwarding = (workAreaId: GuidString): Promise<void> => {
      return this.signalRNextService.joinRoute(
        SignalrRoutes.ErrorForwarding,
        workAreaId,
        componentName
      );
    };

    const leaveErrorForwarding = async (workAreaId: GuidString): Promise<void> => {
      await this.signalRNextService.leaveRoute(
        SignalrRoutes.ErrorForwarding,
        workAreaId,
        componentName
      );
    };

    const getChangedErrorForwardingData = (): Signal<ErrorForwardingMessage | undefined> => {
      return computed(() => {
        return this.changedErrorForwardings();
      });
    };

    const joinIncludeTuggerTrainError = (workAreaId: GuidString): Promise<void> => {
      return this.signalRNextService.joinRoute(
        SignalrRoutes.IncludeTuggerTrainError,
        workAreaId,
        componentName
      );
    };

    const leaveIncludeTuggerTrainError = async (workAreaId: GuidString): Promise<void> => {
      await this.signalRNextService.leaveRoute(
        SignalrRoutes.IncludeTuggerTrainError,
        workAreaId,
        componentName
      );
    };

    const joinIpstSetting = (workAreaId: GuidString): Promise<void> => {
      return this.signalRNextService.joinRoute(
        SignalrRoutes.IpstSettings,
        workAreaId,
        componentName
      );
    };

    const leaveIpstSetting = async (workAreaId: GuidString): Promise<void> => {
      await this.signalRNextService.leaveRoute(
        SignalrRoutes.IpstSettings,
        workAreaId,
        componentName
      );
    };

    const getIpstSettingData = (): Signal<IpstAlertNowConnectionSettingsDto | undefined> => {
      return computed(() => {
        return this.changedIpstSetting();
      });
    };

    const joinAlertNowSetting = (workAreaId: GuidString): Promise<void> => {
      return this.signalRNextService.joinRoute(
        SignalrRoutes.AlertNowSettings,
        workAreaId,
        componentName
      );
    };

    const leaveAlertNowSetting = async (workAreaId: GuidString): Promise<void> => {
      await this.signalRNextService.leaveRoute(
        SignalrRoutes.AlertNowSettings,
        workAreaId,
        componentName
      );
    };

    const getAlertNowSettingData = (): Signal<AlertNowTabDto | undefined> => {
      return computed(() => {
        return this.changedAlertNowSetting();
      });
    };

    const joinAlertNowGroup = (workAreaId: GuidString): Promise<void> => {
      return this.signalRNextService.joinRoute(
        SignalrRoutes.AlertNowGroup,
        workAreaId,
        componentName
      );
    };

    const leaveAlertNowGroup = async (workAreaId: GuidString): Promise<void> => {
      await this.signalRNextService.leaveRoute(
        SignalrRoutes.AlertNowGroup,
        workAreaId,
        componentName
      );
    };

    const getAlertNowGroupData = (): Signal<AlertNowGroupMessage | undefined> => {
      return computed(() => {
        return this.changedAlertNowGroups();
      });
    };

    const joinLastResolve = (workAreaId: GuidString): Promise<void> => {
      return this.signalRNextService.joinRoute(
        SignalrRoutes.lastResolve,
        workAreaId,
        componentName
      );
    };

    const leaveLastResolve = async (workAreaId: GuidString): Promise<void> => {
      await this.signalRNextService.leaveRoute(
        SignalrRoutes.lastResolve,
        workAreaId,
        componentName
      );
    };

    return {
      joinIncludeTuggerTrainError,
      leaveIncludeTuggerTrainError,
      joinErrorForwarding,
      leaveErrorForwarding,
      getChangedErrorForwardingData,
      joinIpstSetting,
      leaveIpstSetting,
      getIpstSettingData,
      joinAlertNowSetting,
      leaveAlertNowSetting,
      getAlertNowSettingData,
      joinAlertNowGroup,
      leaveAlertNowGroup,
      getAlertNowGroupData,

      joinLastResolve,
      leaveLastResolve,
    };
  }

  protected registerConnections(): void {
    this.signalRNextService.registerConnectionNext(
      SignalrRoutes.ErrorForwarding,
      this.errorForwardingMessageReceivedNext
    );

    this.signalRNextService.registerConnectionNext(
      SignalrRoutes.IncludeTuggerTrainError,
      this.includeTuggerTrainToggleNext
    );

    this.signalRNextService.registerConnectionNext(
      SignalrRoutes.IpstSettings,
      this.ipstSettingNext
    );

    this.signalRNextService.registerConnectionNext(
      SignalrRoutes.AlertNowSettings,
      this.alertNowSettingNext
    );

    this.signalRNextService.registerConnectionNext(
      SignalrRoutes.AlertNowGroup,
      this.alertNowGroupNext
    );

    this.signalRNextService.registerConnectionNext(SignalrRoutes.lastResolve, this.lastResolveNext);

    this.errorForwardingMessageReceivedNext.subscribe(errorForwardingMessage => {
      this.changedErrorForwardings.set(errorForwardingMessage.payload);
    });

    this.includeTuggerTrainToggleNext.subscribe(includeTrainToggleMessage => {
      this.rootStore.dispatch(
        fromSettings.setToggleIncludeTuggerTrainErrors({
          result: {
            isToggledOn: includeTrainToggleMessage.payload.isToggledOn,
            dateUpdated: includeTrainToggleMessage.payload.dateUpdated,
          },
        })
      );
    });

    this.ipstSettingNext.subscribe(ipstSettingMessage => {
      this.changedIpstSetting.set(ipstSettingMessage.payload);
    });

    this.alertNowSettingNext.subscribe(ipstSettingMessage => {
      this.changedAlertNowSetting.set(ipstSettingMessage.payload);
    });

    this.alertNowGroupNext.subscribe(ipstSettingMessage => {
      this.changedAlertNowGroups.set(ipstSettingMessage.payload);
    });

    this.lastResolveNext.subscribe(lastResolveMessage => {
      this.rootStore.dispatch(
        fromSettings.loadLastIpstMessageResolveSuccess({
          lastMessageResolve: lastResolveMessage.payload.lastResolve,
        })
      );
    });
  }
}

export interface IpstSignalrSubscriber {
  joinErrorForwarding(workAreaId: GuidString): Promise<void>;
  leaveErrorForwarding(workAreaId: GuidString): Promise<void>;
  getChangedErrorForwardingData(): Signal<ErrorForwardingMessage | undefined>;
  joinIncludeTuggerTrainError(workAreaId: GuidString): Promise<void>;
  leaveIncludeTuggerTrainError(workAreaId: GuidString): Promise<void>;
  joinIpstSetting(workAreaId: GuidString): Promise<void>;
  leaveIpstSetting(workAreaId: GuidString): Promise<void>;
  getIpstSettingData(): Signal<IpstAlertNowConnectionSettingsDto | undefined>;
  joinAlertNowSetting(workAreaId: GuidString): Promise<void>;
  leaveAlertNowSetting(workAreaId: GuidString): Promise<void>;
  getAlertNowSettingData(): Signal<AlertNowTabDto | undefined>;
  joinAlertNowGroup(workAreaId: GuidString): Promise<void>;
  leaveAlertNowGroup(workAreaId: GuidString): Promise<void>;
  getAlertNowGroupData(): Signal<AlertNowGroupMessage | undefined>;
  joinLastResolve(workAreaId: GuidString): Promise<void>;
  leaveLastResolve(workAreaId: GuidString): Promise<void>;
}
