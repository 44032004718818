import { VehicleMapDataStatus, VehicleOptionStatus } from 'core/models';

export const ManageMapDataTones = {
  [VehicleOptionStatus.CurrentActiveMap]: 'positive',
  [VehicleOptionStatus.EnabledMap]: 'caution',
  [VehicleOptionStatus.Available]: 'positive',
  [VehicleOptionStatus.Busy]: 'caution',
  [VehicleOptionStatus.Offline]: 'caution',
  [VehicleOptionStatus.MapNotAvailableOnVehicle]: 'info',
  [VehicleOptionStatus.AlreadySentMap]: 'positive',
};

const dsColorYellow100 = '--ds-color-yellow-100';
const dsColorGreen100 = '--ds-color-green-100';
const dsColorBlue100 = '--ds-color-blue-100';

export const ManageMapDataColors = {
  [VehicleOptionStatus.CurrentActiveMap]: dsColorGreen100,
  [VehicleOptionStatus.EnabledMap]: dsColorYellow100,
  [VehicleOptionStatus.Available]: '',
  [VehicleOptionStatus.Busy]: dsColorYellow100,
  [VehicleOptionStatus.Offline]: dsColorYellow100,
  [VehicleOptionStatus.MapNotAvailableOnVehicle]: dsColorBlue100,
  [VehicleOptionStatus.AlreadySentMap]: dsColorGreen100,
};

export const VehicleMapDataStatusTones = {
  [VehicleMapDataStatus.InProcessing]: 'caution',
  [VehicleMapDataStatus.NotValidated]: 'info',
  [VehicleMapDataStatus.Validated]: 'positive',
  [VehicleMapDataStatus.ValidationFailed]: 'critical',
};
