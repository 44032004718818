import { GraphEdge, GraphLayout, VehicleGroup } from 'core/models';
import { Colors } from 'library/styles';
import { LineArrowOptions, LineOptions, MapPixiHelper } from 'modules/maps/helpers';
import { Graphics } from 'pixi.js';
import { MapLayerDrawing } from '../map-layer-drawing';
import { EdgeContainerBase } from './base-edge-container.graphic';
import { EdgeStyle } from './graph-layer.constant';

export class EdgeContainer extends EdgeContainerBase {
  readonly vehicleGroup: VehicleGroup;
  constructor(drawing: MapLayerDrawing, protected layout: GraphLayout, vehicleGroup: VehicleGroup) {
    super();

    drawing.addChild(this);
    this.interactive = false;
    this.vehicleGroup = vehicleGroup;

    layout.edges.forEach(e => this.createEdgeGraphics(e));
  }

  createEdgeGraphics(edge: GraphEdge): void {
    const options: LineOptions = {
      size: EdgeStyle.EdgeSize,
      color: Colors.graphLayer[this.vehicleGroup].Edges,
      alpha: EdgeStyle.EdgeAlpha,
    };

    const arrow: LineArrowOptions = {
      color: Colors.graphLayer[this.vehicleGroup].Arrow,
      alpha: EdgeStyle.ArrowAlpha,
      size: EdgeStyle.ArrowSize,
      smallSize: EdgeStyle.ArrowSmallSize,
      distance: EdgeStyle.ArrowDistance,
    };

    const lineArrow =
      edge.nurb && edge.nurb.isValid
        ? MapPixiHelper.createNurbLineWithDirectionArrow(
            options,
            arrow,
            edge.startPosition,
            edge.endPosition,
            edge.nurb
          )
        : MapPixiHelper.createLineWithDirectionArrow(
            options,
            arrow,
            edge.startPosition,
            edge.endPosition
          );

    const arrowGraphic = lineArrow.getChildAt(0);

    if (arrowGraphic instanceof Graphics) {
      this.arrows.push(arrowGraphic);
    }

    this.addChild(lineArrow);
  }
}
