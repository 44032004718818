import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import {
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
  GridReadyEvent,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community';
import { TreeTableComponent } from 'components/tree-table/tree-table.component';
import { AuthGql } from 'core/api-services';
import { EMPTY_GUID } from 'core/constants';
import { ChangeLogTableRow } from 'core/dtos';
import { GuidString } from 'core/models';
import { AtsTranslationService, EditBarService, ToolbarService } from 'core/services';
import { Icons } from 'library/constants';
import { DateFormatEnum } from 'library/helpers/standard-date-formatter.helper';
import { compact } from 'lodash';
import { firstValueFrom, takeUntil } from 'rxjs';
import { BaseAgGridTableDirective, GridValueFormatters } from 'shared/components';
import { RootState, selectSelectedWorkingArea } from 'store/index';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangelogComponent extends BaseAgGridTableDirective implements OnInit, OnDestroy {
  @ViewChild(TreeTableComponent) child!: TreeTableComponent;
  readonly DEFAULT_PAGE_SIZE = 25;
  pageSize = this.DEFAULT_PAGE_SIZE;
  resetIcon = Icons.Reset;

  workAreaId: GuidString = EMPTY_GUID;
  filtering: AuthGql.AuditLogFilterInput | null = null;
  first = 0;
  dateFormat = DateFormatEnum;

  readonly columns: ColDef[] = [
    {
      field: 'createdUtc',
      sortable: false,
      headerName: 'settings.changelog.list.createdUtc',
      valueFormatter: GridValueFormatters.standardDateTimeFormatterFn(
        this.translationService,
        this.dateFormat.LongDateTimeMillisec
      ),
      filter: 'agDateColumnFilter',
      type: 'dateColumn',
      filterParams: {
        buttons: ['reset'],
        filterOptions: ['inRange'],
        suppressAndOrCondition: true,
      },
    },
    {
      field: 'text',
      sortable: false,
      headerName: 'settings.changelog.list.action',
      wrapText: true,
      flex: 4,
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ['reset'],
        filterOptions: ['contains'],
        suppressAndOrCondition: true,
      },
    },
  ];

  constructor(
    private readonly editBarService: EditBarService,
    protected readonly toolbarService: ToolbarService,
    protected readonly cdRef: ChangeDetectorRef,
    protected readonly query: AuthGql.ChangeLogRequestGQL,
    readonly store: Store<RootState>,
    protected readonly translationService: AtsTranslationService
  ) {
    super(translationService, toolbarService, cdRef);
    this.gridOptions.serverSideSortOnServer = true;
    this.gridOptions.serverSideFilterOnServer = true;
    this.gridOptions.rowModelType = 'serverSide';
    this.gridOptions.serverSideInfiniteScroll = true;
    this.store
      .pipe(select(selectSelectedWorkingArea), takeUntil(this.ngUnsubscribe))
      .subscribe(workArea => {
        this.workAreaId = workArea?.id ?? EMPTY_GUID;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.editBarService.setIsNewObject(false);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.translationService.onLangChange.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.gridApi?.refreshServerSide();
    });
  }

  getRowIdForChangeDetection: GetRowIdFunc = (params: GetRowIdParams) => params.data.id.toString();

  onGridReady(params: GridReadyEvent): void {
    super.onGridReady(params);
    this.translateHeader();
    this.gridApi.setServerSideDatasource(this.dataSource);
  }

  dataSource: IServerSideDatasource = {
    getRows: (params: IServerSideGetRowsParams) => {
      const pageSize =
        params.request.endRow != null && params.request.startRow != null
          ? params.request.endRow - params.request.startRow
          : this.pageSize;

      const criteria = [
        this.criteriaLanguage(params.request),
        ...this.criteriaCreatedUtcDate(params.request),
      ];
      this.filtering = { and: compact(criteria) };
      if (this.filtering.and?.length === 0) {
        this.filtering = null;
      }

      firstValueFrom(
        this.query.fetch({
          workAreaId: this.workAreaId,
          offset: params.request.startRow ?? 0,
          pageSize: pageSize,
          filter: this.filtering,
        })
      )
        .then(result => {
          const langProp = this.translationService.currentLang === 'de' ? 'textDe' : 'textEn';
          const nodes = result.data.changeLog?.items ?? [];
          const rows: ChangeLogTableRow[] = nodes.map(it => ({
            id: it.id,
            text: it[langProp],
            createdUtc: it.createdUtc,
          }));

          params.success({
            rowData: rows,
            rowCount: result.data.changeLog?.totalCount ?? -1,
          });
        })
        .catch(err => {
          params.fail();
          console.error(err);
        });
    },
  };

  criteriaLanguage(filters: IServerSideGetRowsRequest): AuthGql.AuditLogFilterInput | null {
    if (filters?.filterModel?.text?.filter != null) {
      if (this.translationService.currentLang === 'de') {
        return { textDe: { contains: filters?.filterModel.text?.filter ?? '' } };
      } else {
        return { textEn: { contains: filters?.filterModel.text?.filter ?? '' } };
      }
    }
    return null;
  }

  criteriaCreatedUtcDate(filters: IServerSideGetRowsRequest): AuthGql.AuditLogFilterInput[] {
    if (filters?.filterModel?.createdUtc != null) {
      const dateFrom = filters.filterModel?.createdUtc.dateFrom && {
        createdUtc: { gte: filters.filterModel?.createdUtc.dateFrom },
      };
      const dateTo = filters.filterModel?.createdUtc.dateTo && {
        createdUtc: { lte: filters.filterModel?.createdUtc.dateTo },
      };
      return [dateFrom, dateTo];
    }
    return [];
  }
}
