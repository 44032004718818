export enum CATEGORY {
  Device = 'DEVICE',
  EnvironmentStructure = 'ENVIRONMENT_STRUCTURE',
  EvacuationDevice = 'EVACUATION_DEVICE',
  FeatureToggleChange = 'FEATURE_TOGGLE_CHANGE',
  Map = 'MAP',
  Mapping = 'MAPPING',
  Mission = 'MISSION',
  NavigationLayer = 'NAVIGATION_LAYER',
  Operation = 'OPERATION',
  Poi = 'POI',
  Processchain = 'PROCESSCHAIN',
  RolePermissions = 'ROLE_PERMISSIONS',
  TourConfiguration = 'TOUR_CONFIGURATION',
  Unknown = 'UNKNOWN',
  Vehicle = 'VEHICLE',
  Zone = 'ZONE',
  ZoneSet = 'ZONE_SET',
  IpstAlertNowSettings = 'IPST_ALERT_NOW_SETTINGS',
}
