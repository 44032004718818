<app-ag-grid-layout>
  <div filter-content>
    <div class="d-flex justify-content-end">
      <button
        ds-button
        [variant]="'ghost'"
        [icon]="resetIcon"
        class="ms-4x"
        (click)="resetPersistedFilterAndColumnState()">
        {{ 'shared.tableColumnActions.reset' | translate }}
      </button>
    </div>
  </div>

  <div table-content>
    <ag-grid-angular
      class="ag-theme-density zebra"
      style="width: 100%; height: 100%"
      data-cy="auditLogListTable"
      [appPersistTableFilterAndColumnState]="{
        persistKey: 'auditlog-list',
        gridOptions,
        rowData: undefined,
      }"
      [columnDefs]="translatedColumnDefs"
      [cacheBlockSize]="pageSize"
      [getRowId]="getRowIdForChangeDetection"
      [gridOptions]="gridOptions"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [rowSelection]="'single'"
      [rowModelType]="'serverSide'"
      [pagination]="true"
      [paginationPageSize]="pageSize"
      [animateRows]="true"
      (gridReady)="onGridReady($event)">
    </ag-grid-angular>
  </div>
</app-ag-grid-layout>
