import { NavigationLayerResponseModel, VehicleDto } from 'core/dtos';
import {
  GuidString,
  LoadType,
  ReducedVehicle,
  VehicleGroup,
  VehicleTrafficLightStatus,
} from 'core/models';
import { reduceNested } from './object.helper';

export function roundBatteryLevel(batteryLevel: number): number {
  return Math.ceil(batteryLevel);
}

export function roundBatteryLevelOfVehicle(vehicle: VehicleDto): VehicleDto {
  vehicle.batteryLevel = roundBatteryLevel(vehicle.batteryLevel);
  return vehicle;
}

export function roundBatteryLevelOfVehicles(vehicles: VehicleDto[]): VehicleDto[] {
  return vehicles.map(vehicle => roundBatteryLevelOfVehicle(vehicle));
}

export function getTrafficLightStatus(
  vehicle: VehicleDto | ReducedVehicle
): VehicleTrafficLightStatus {
  if (
    !vehicle.softwareDetails?.softwareVersion ||
    vehicle.softwareDetails?.softwareVersion === ''
  ) {
    return VehicleTrafficLightStatus.NotInitialized;
  }

  if (vehicle.isSwitchedOff) {
    return VehicleTrafficLightStatus.SwitchedOff;
  }

  if (!vehicle.isConnected) {
    return VehicleTrafficLightStatus.LostConnection;
  }

  if (vehicle.isConnected && vehicle.hasError) {
    return VehicleTrafficLightStatus.ConnectedWithError;
  }

  if (vehicle.isConnected) {
    return VehicleTrafficLightStatus.Connected;
  }

  return VehicleTrafficLightStatus.NotInitialized;
}

export function getMapDataTrafficLightStatus(
  vehicle: VehicleDto | ReducedVehicle
): VehicleTrafficLightStatus {
  if (vehicle.isBusy || !vehicle.isConnected) {
    return VehicleTrafficLightStatus.NotInitialized;
  } else {
    return VehicleTrafficLightStatus.Connected;
  }
}

export function getSupportedLoadTypes(vehicles: VehicleDto[]): Set<LoadType> {
  return reduceNested(
    vehicles,
    it => it.supportedLoadTypes,
    new Set<LoadType>(),
    (set, item) => set.add(item)
  );
}

export function isVehicleGroupInMap(
  allNavigationLayers: NavigationLayerResponseModel[],
  vehicleGroup: VehicleGroup,
  mapId: GuidString
): boolean {
  return allNavigationLayers
    .filter(layer => layer.mapId === mapId)
    .some(layer => layer.vehicleGroup === vehicleGroup);
}
