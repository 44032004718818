import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import {
  ArchiveMissionHistoryResponse,
  ArchivedTourDto,
  CommentSettingsDto,
  ErrorCodeCountForLast24HoursDto,
  FailureCategoryDto,
  FailureCommentDto,
  FailureMissionTrace,
  MassCommentFailureCommentDto,
  MissionFailureReasonDto,
  MissionTraceDto,
  ODataDto,
  ShiftGroupDto,
  TuggerTrainMissionDto,
} from 'core/dtos';
import { convertMissionTraceDtoToModel } from 'core/helpers';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString, MissionFailureReasonStatus, MissionTrace, VehicleType } from 'core/models';
import { Observable, map, shareReplay } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class FailuresService extends TenantHttpClient {
  protected apiUrl = environment.Services.MissionArchive;
  private readonly locationServicePath = API_SERVICES.MissionArchiveFailuresLocation;
  private readonly servicePath = API_SERVICES.MissionArchiveFailures;
  private readonly tuggerServicePath = API_SERVICES.TuggerMissions;
  private readonly oDataMissionTraceHistoryPath =
    '/odata/FailureComment?expand=missionFailureLocation,FailureVehicles,tuggerMissions';
  private readonly missionTraceArchivePath = API_SERVICES.MissionTraceArchive;
  private readonly missionTraceFailureArchivePath = API_SERVICES.MissionTraceFailureArchive;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  updateMissionTraceArchive(
    missionTraceId: GuidString,
    newMissionComment: FailureCommentDto
  ): Observable<FailureCommentDto | undefined> {
    return this.put<FailureCommentDto | undefined>(
      `${this.missionTraceArchivePath}/update/` + missionTraceId.toString(),
      newMissionComment
    );
  }

  getMissionTraceById(id: GuidString): Observable<MissionTrace> {
    return this.get<MissionTraceDto>(`${this.missionTraceArchivePath}${id}`).pipe(
      map(missionTraceDto => convertMissionTraceDtoToModel(missionTraceDto))
    );
  }

  getMissionTraceHistoryViaOdata(
    params: Params
  ): Observable<ODataDto<ArchiveMissionHistoryResponse>> {
    return this.get<ODataDto<ArchiveMissionHistoryResponse>>(
      `${this.oDataMissionTraceHistoryPath}`,
      params
    );
  }

  getAllMissionFailureReasons(): Observable<MissionFailureReasonDto[]> {
    return this.get<MissionFailureReasonDto[]>(`${this.servicePath}/missionFailureReasons`);
  }

  getLinkedFailureComments(vehicleId: GuidString): Observable<ErrorCodeCountForLast24HoursDto[]> {
    return this.get<ErrorCodeCountForLast24HoursDto[]>(
      `${this.servicePath}/commentErrorCodeEntryForLast24Hours/?vehicleId=${vehicleId}`
    );
  }

  getFailureSettingsWithFavoriteInfo(
    vehicleType: VehicleType,
    status: MissionFailureReasonStatus | null
  ): Observable<FailureCategoryDto[]> {
    return this.get<FailureCategoryDto[]>(
      `${this.servicePath}/failureSettings/favorites/vehicleType/${vehicleType}` +
        (status ? `?status=${status}` : ``)
    );
  }

  getFailureCategories(
    vehicleType: VehicleType,
    status: MissionFailureReasonStatus | null
  ): Observable<FailureCategoryDto[]> {
    return this.get<FailureCategoryDto[]>(
      `${this.servicePath}/failureSettings/vehicleType/${vehicleType}?status=${status}`
    );
  }

  updateFailureCategories(failureSetting: FailureCategoryDto): Observable<void> {
    return this.put<void>(`${this.servicePath}/failureSettings/update/`, failureSetting);
  }

  updateFailureSettings(failureSettings: FailureCategoryDto[]): Observable<void> {
    return this.put<void>(`${this.servicePath}/failureSettings/`, failureSettings);
  }

  saveFailureCategories(failureSettings: FailureCategoryDto): Observable<void> {
    return this.post<void>(`${this.servicePath}/failureSettings/`, failureSettings);
  }

  deleteFailureCategories(id: GuidString): Observable<void> {
    return this.delete<void>(`${this.servicePath}/failureSettings/delete/${id}`);
  }

  getFailureComments(): Observable<ArchiveMissionHistoryResponse[]> {
    return this.get<ArchiveMissionHistoryResponse[]>(`${this.servicePath}/failureComments`);
  }

  getFailureCommentByArchivedMissionTraceId(id?: GuidString): Observable<FailureCommentDto> {
    return this.get<FailureCommentDto>(
      `${this.servicePath}/failureComments/missionTraceArchive/?id=${id}`
    );
  }

  getFailureCommentByArchivedTourId(id?: GuidString): Observable<FailureCommentDto> {
    return this.get<FailureCommentDto>(`${this.servicePath}/failureComments/tourArchive/?id=${id}`);
  }

  getFailureCommentsByTraceId(id?: GuidString): Observable<FailureCommentDto> {
    return this.get<FailureCommentDto>(
      `${this.servicePath}/failureComments/missionTrace/?id=${id}`
    );
  }

  getFailureCommentsByTraceIdList(idList: GuidString[]): Observable<FailureCommentDto[]> {
    return this.post<FailureCommentDto[]>(`${this.servicePath}/failureComments/idList`, idList);
  }

  createFailureComment(failureComment: FailureCommentDto): Observable<void> {
    return this.post<void>(`${this.servicePath}/failureComments/`, failureComment);
  }

  updateFailureComment(failureComment: FailureCommentDto): Observable<void> {
    return this.put<void>(`${this.servicePath}/failureComments/`, failureComment);
  }

  getAllShiftGroups(): Observable<ShiftGroupDto[]> {
    return this.get<ShiftGroupDto[]>(`${this.servicePath}/shiftGroups/`);
  }

  updateShiftGroup(shiftGroup: ShiftGroupDto): Observable<void> {
    return this.put<void>(`${this.servicePath}/shiftGroups/`, shiftGroup);
  }

  createShiftGroup(shiftGroup: ShiftGroupDto): Observable<void> {
    return this.post<void>(`${this.servicePath}/shiftGroup/`, shiftGroup);
  }

  createShiftGroups(shiftGroups: ShiftGroupDto[]): Observable<ShiftGroupDto[]> {
    return this.post<ShiftGroupDto[]>(`${this.servicePath}/shiftGroups/`, shiftGroups);
  }

  deleteShiftGroup(id: GuidString): Observable<void> {
    return this.delete<void>(`${this.servicePath}/shiftGroups/${id}`);
  }

  getTuggerTrainMission(): Observable<TuggerTrainMissionDto[]> {
    return this.get<TuggerTrainMissionDto[]>(`${this.tuggerServicePath}`);
  }

  getCommentLocations(): Observable<CommentSettingsDto[]> {
    return this.get<CommentSettingsDto[]>(`${this.locationServicePath}`);
  }

  getTuggerTrainMissionWithFavoriteInfo(): Observable<TuggerTrainMissionDto[]> {
    return this.get<TuggerTrainMissionDto[]>(
      `${this.tuggerServicePath}/failureSettingsWithFavorite`
    );
  }

  updateTuggerTrainMission(tuggerTrainMission: TuggerTrainMissionDto): Observable<void> {
    return this.put<void>(`${this.tuggerServicePath}`, tuggerTrainMission);
  }

  updateCommentLocation(commentLocations: CommentSettingsDto): Observable<void> {
    return this.put<void>(`${this.locationServicePath}`, commentLocations);
  }

  createTuggerTrainMission(tuggerTrainMission: TuggerTrainMissionDto): Observable<void> {
    return this.post<void>(`${this.tuggerServicePath}`, tuggerTrainMission);
  }

  createCommentLocation(commentLocations: CommentSettingsDto): Observable<void> {
    return this.post<void>(`${this.locationServicePath}`, commentLocations);
  }

  saveTuggerMissions(
    tuggerTrainMissions: TuggerTrainMissionDto[]
  ): Observable<TuggerTrainMissionDto[]> {
    return this.post<TuggerTrainMissionDto[]>(
      `${this.tuggerServicePath}/save`,
      tuggerTrainMissions
    );
  }

  saveCommentLocation(commentLocations: CommentSettingsDto[]): Observable<CommentSettingsDto[]> {
    return this.post<CommentSettingsDto[]>(`${this.locationServicePath}/save`, commentLocations);
  }

  deleteTuggerTrainMission(id: GuidString): Observable<void> {
    return this.delete<void>(`${this.tuggerServicePath}/${id}`);
  }

  deleteCommentLocation(id: GuidString): Observable<void> {
    return this.delete<void>(`${this.locationServicePath}/${id}`);
  }

  createCommentForMission(
    missionTraceId: GuidString,
    newMissionComment: FailureCommentDto
  ): Observable<void> {
    return this.post<void>(
      `${this.missionTraceFailureArchivePath}/missionTrace?traceId=${missionTraceId.toString()}`,
      newMissionComment
    );
  }

  getAllActiveFailureMissionTraces(): Observable<FailureMissionTrace[]> {
    return this.get<FailureMissionTrace[]>(`${this.missionTraceFailureArchivePath}/missionTrace`);
  }

  massComment(payload: MassCommentFailureCommentDto): Observable<void> {
    return this.post<void>(`${this.servicePath}/massCommentMissionFailureValues`, payload);
  }

  getTourTraceHistoryViaOdata(params: Params): Observable<ODataDto<ArchivedTourDto>> {
    return this.get<ODataDto<ArchivedTourDto>>(
      `/odata/ArchivedTourHistoryOData?expand=failureComment`,
      params
    ).pipe(shareReplay({ bufferSize: 1, refCount: true })); //avoid duplicate & cancelled api calls https://rxjs.dev/ api /operators/shareReplay
  }
}
