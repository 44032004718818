import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { LayoutDto } from 'core/dtos';

import { isEqual, omit } from 'lodash';
import * as GraphLayerActions from '../actions';

export const featureKey = 'graphLayer';

export interface GraphLayersState extends EntityState<LayoutDto> {
  errorMessage: string;
  actionStatus: GraphLayerActions.GraphLayerTypes | null;
}

// eslint-disable-next-line max-len
export const graphLayersAdapter: EntityAdapter<LayoutDto> = createEntityAdapter<LayoutDto>();

export const initialState: GraphLayersState = graphLayersAdapter.getInitialState({
  errorMessage: '',
  actionStatus: null,
});

const graphReducer = createReducer(
  initialState,

  on(GraphLayerActions.loadGraphLayers, state => ({
    ...state,
    errorMessage: '',
  })),

  on(GraphLayerActions.loadGraphLayerSuccess, (state, { graphLayers }) =>
    graphLayersAdapter.setAll(
      graphLayers.map(layer => ({ ...layer })),
      {
        ...state,
        nodes: graphLayers.flatMap(layer => layer.nodes),
        actionStatus: GraphLayerActions.GraphLayerTypes.LoadGraphLayersSuccess,
      }
    )
  ),

  on(GraphLayerActions.updateGraphLayerNodeSuccess, (state, { layout }) =>
    graphLayersAdapter.updateOne(
      { id: layout.id.toString(), changes: layout },
      { ...state, actionStatus: GraphLayerActions.GraphLayerTypes.UpdateGraphLayerNodeSuccess }
    )
  ),

  on(GraphLayerActions.updateGraphLayerNodeFailure, (state, { errorMessage }) => ({
    ...state,
    actionStatus: GraphLayerActions.GraphLayerTypes.UpdateGraphLayerNodeFailure,
    errorMessage,
  })),

  on(GraphLayerActions.resetGraphLayerActionStatus, state => ({
    ...state,
    actionStatus: null,
  })),

  on(
    GraphLayerActions.updateGraphLayerNodeOccupancyStatus,
    (state, { nodeOccupancyStatusMessage }) => {
      let isChanged = false;
      const layoutId = Object.keys(state.entities).find(id =>
        state.entities[id]?.nodes.some(node => node.nodeId === nodeOccupancyStatusMessage.nodeId)
      );

      if (!layoutId) {
        return state;
      }

      const updatedLayout = state.entities[layoutId];
      if (!updatedLayout) {
        return state;
      }

      const updatedNodes = updatedLayout.nodes.map(node => {
        if (
          node.nodeId === nodeOccupancyStatusMessage.nodeId &&
          !isEqual(
            omit(node.occupancy, ['statusUpdateTime']),
            omit(nodeOccupancyStatusMessage, ['statusUpdatedOnUtc', 'workAreaId', 'nodeId'])
          )
        ) {
          isChanged = true;
          return {
            ...node,
            occupancy: {
              status: nodeOccupancyStatusMessage.status,
              vehicleName: nodeOccupancyStatusMessage.vehicleName,
              statusUpdateTime: nodeOccupancyStatusMessage.statusUpdatedOnUtc,
              vehicleId: nodeOccupancyStatusMessage.vehicleId,
              managementType: nodeOccupancyStatusMessage.managementType,
            },
          };
        }
        return node;
      });

      if (isChanged)
        return graphLayersAdapter.updateOne(
          {
            id: layoutId,
            changes: {
              nodes: updatedNodes,
            },
          },
          state
        );

      return state;
    }
  )
);

export function reducer(state: GraphLayersState | undefined, action: Action): GraphLayersState {
  return graphReducer(state, action);
}
export const { selectEntities, selectAll } = graphLayersAdapter.getSelectors();

export const getGraphLayerEntities = selectEntities;
export const getAllGraphLayers = selectAll;
export const getActionStatus = (
  state: GraphLayersState
): GraphLayerActions.GraphLayerTypes | null => state.actionStatus;
