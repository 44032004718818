/* eslint-disable @typescript-eslint/no-magic-numbers */
import { MapVehicle } from 'core/dtos';
import { VehicleColors } from 'library/styles';
import { IconOptions } from 'modules/maps/helpers';
import { Graphics, Sprite, Texture } from 'pixi.js';
import { VehicleGraphicHelper } from '../helpers/vehicle-graphic.helper';
import { ForkliftDimensions } from '../vehicle-dimensions.model';
import { VehicleTextures } from '../vehicle-layer-texture.constant';
import { VehicleArrowSize } from '../vehicle-layer.constant';
import { ForkliftMapItem } from '../vehicle-types/forklift.graphic';

/*
  Total Length incl Forks: 1511mm
  Body Length: 287mm
  Body Width: 810mm
  Fork Length: 1224mm
  Fork Width: 577mm 
  Pivot: 680mm from Fork end (1511mm-287mm-680mm)
*/

export const dimensions: ForkliftDimensions = {
  length: 29,
  width: 81,
  origin: -54.4,
  fork: {
    width: 57.7,
    length: 122.4,
    loadOffset: 0,
  },
  load: {
    width: 90,
    length: 140,
  },
};

export const ArrowIconStyle: IconOptions = {
  resourceOptions: { scale: 1 },
  scale: 7,
};

export class ForkliftAgiloxOneMapItem extends ForkliftMapItem {
  protected getDimensions(): ForkliftDimensions {
    return dimensions;
  }

  protected getArrowPosition(): number | undefined {
    return 0;
  }

  protected getArrowSize(vehicle: MapVehicle): IconOptions {
    return {
      ...ArrowIconStyle,
      scale: vehicle.isLoaded ? VehicleArrowSize.Large : VehicleArrowSize.Small,
    };
  }

  protected getArrowColor(vehicle: MapVehicle): number {
    return vehicle.isLoaded ? VehicleColors.Base : VehicleColors.ArrowGrey;
  }

  protected getBodyTexture(): Texture {
    return VehicleTextures.forkliftAgiloxOne;
  }

  protected createVehicleBase(): Graphics | Sprite {
    return VehicleGraphicHelper.createVehicleBaseFromTexture(
      this.getBodyTexture(),
      this.dimensions
    );
  }

  protected createForks(): Graphics | Sprite {
    return VehicleGraphicHelper.createForksFromTexture(
      VehicleTextures.forkliftAgiloxForks,
      this.dimensions
    );
  }
}
