//Hash Color Keys Are Density Colors: https://density.bmwgroup.net/v8/foundations/color/color-palette
export enum BMWColors {
  Accent1 = '#ffe38e',
  HintBlue = '#0071c5',
}

export const BMWColorsNumber = {
  HintBlue: 0x0071c5,
  SignalBlue: 0xcbdfff,
};

export const StandardColors = {
  CardBorder: '#035970',
  CardBackground: '#ecf3ff',

  CardIndicator1: '#8d9129',
  CardIndicator2: '#62acff',
  CardIndicator3: '#aa0014',
};

// https://density.bmwgroup.net/v7/foundations/color/color-palette
export const DensityColors = {
  Turquoise: 0x037493,
  Red400: 0xff8485,
  Avocado300: 0xabd350,
  Avocado400: 0x8cb735,
  Avocado500: 0x6e9917,
  Avocado600: 0x517b00,
  Fuchsia400: 0xea7eec,
  Fuchsia500: 0xe63be3,
  Fuchsia600: 0xc117b9,
  Fuchsia700: 0x97008c,
};

export const OtherColorsNumber = {
  OceanBlue200: 0xa8dfff,
  OceanBlue250: 0x7cd9fb,
  OceanBlue300: 0x3ccaff,
  OceanBlue400: 0x00aede,
  LightBlue: 0x4a90e2,
  GreyBlue: 0x98a4bb,
  Turquoise: 0x037493,
  Green: 0x93a53b,
  RedBrown: 0xa91b0d,
  Red: 0xff0000,
  Orange: 0xf1a43a,
};
