/* eslint-disable max-lines */
import { IconBackgroundSize, NodeActionTypeGroup } from 'core/dtos';
import {
  GraphNode,
  GraphNodeIcon,
  GraphNodeShape,
  GraphParkingChargingType,
  MapItemType,
  VehicleGroup,
} from 'core/models';
import { Colors } from 'library/styles';
import { MapPixiHelper, getMinMaxValue } from 'modules/maps/helpers';
import { NodeGraphicConfig } from 'modules/maps/models';
import { BitmapText, Container, Graphics, Sprite } from 'pixi.js';
import { GeometryConverter } from 'shared/helpers';
import { MapItemBase, RotateMapItem } from '../map-item-container';
import { MapItemRotationContainer, RotationStyle } from '../map-item-rotation-container.graphic';
import { MapLayerDrawing } from '../map-layer-drawing';
import { FontName, MapFonts } from '../map-layer-fonts.constant';
import { GraphicsEx } from '../pixi';
import {
  NodeArrowStyle,
  NodeName,
  NodeRotation,
  NodeRotationStyle,
  NodeShape,
  NodeStyle,
  SHOW_WAYPOINT_LABELS,
} from './graph-layer.constant';

export abstract class BaseNodeMapItem implements MapItemBase, RotateMapItem {
  container: MapItemRotationContainer;
  nodeGraphic: Graphics | GraphicsEx | undefined;

  protected readonly node: GraphNode;

  protected labelGraphic: Container | undefined;
  protected icon: Sprite | undefined;
  protected arrow: Graphics | undefined;
  protected selection: Graphics | undefined;
  protected config: NodeGraphicConfig | undefined;
  vehicleGroup: VehicleGroup | undefined;

  constructor(
    drawing: MapLayerDrawing,
    node: GraphNode,
    vehicleGroup?: VehicleGroup | undefined,
    type = MapItemType.Node
  ) {
    this.vehicleGroup = vehicleGroup;

    this.node = node;
    this.container = drawing.addChild(
      new MapItemRotationContainer(node.nodeId.toString(), type, this.getRotationStyle(node))
    );

    this.container.position.copyFrom(node.nodePosition);
    this.container.setScaleReverse();

    this.createGraphic(node);
  }

  // #region Create Graphic
  protected getNodeConfig(node: GraphNode): NodeGraphicConfig {
    return {
      color: this.getNodeColor(node, this.vehicleGroup ?? VehicleGroup.TuggerTrainDs),
      size: this.getNodeSize(node),
      icon: undefined,
      selection: this.getEnableSelection(node),
      outline: node.hasRule,
      showLabel: node.parkingChargingType !== GraphParkingChargingType.None || SHOW_WAYPOINT_LABELS,
      hasArrow: node.isReTrackingPoint,
      isRound: this.isRoundGraphic(node),
      rotation: node.reTrackingOrientation || 0,
      parkingChargingType: node.parkingChargingType,
    };
  }

  protected isRoundGraphic(node: GraphNode): boolean {
    const shape = this.getNodeIconShape(node);
    return shape === GraphNodeShape.SmallRound || shape === GraphNodeShape.MediumRound;
  }

  protected getNodeIconShape(node: GraphNode): GraphNodeShape {
    return NodeShape[node.parkingChargingType];
  }

  protected getNodeSize(node: GraphNode): number {
    if (
      node.parkingChargingType === GraphParkingChargingType.None &&
      !(node.isReTrackingPoint || node.isSwitchNode)
    )
      return NodeStyle.WaypointSize;

    return NodeStyle.NodeSize;
  }

  protected getEnableSelection(_node: GraphNode): boolean {
    return true;
  }

  protected createGraphic(node: GraphNode): void {
    this.container.remove();
    this.config = this.getNodeConfig(node);

    this.container.interactive = this.config.selection;
    this.container.buttonMode = this.config.selection;

    this.container.addChild((this.nodeGraphic = this.createNodeGraphic(this.config)));
    this.nodeGraphic.addChild((this.selection = this.createSelection()));

    if (this.config.hasArrow) {
      this.container.addChild((this.arrow = this.createArrowGraphic(this.config)));
    }

    if (this.config.showLabel) {
      const labelGraphic = this.createNodeLabelGraphic(node.nodeName);

      if (labelGraphic) {
        this.container.addChild((this.labelGraphic = labelGraphic));
      }
    }
  }

  setScale(scale: number): void {
    const maxScale = NodeArrowStyle.Scale * 2;
    const minScale = maxScale * NodeStyle.MinScaleFactor;
    const scaleFactor = getMinMaxValue(scale, maxScale, minScale);

    this.nodeGraphic?.scale.set(1 / scaleFactor);
    this.arrow?.scale.set(1 / (scaleFactor / maxScale));
  }

  protected createSelection(): Graphics {
    if (this.config?.isRound) {
      return this.createRoundGraphicSelected();
    } else {
      return this.createSquareGraphicSelected();
    }
  }

  private createNodeGraphic(config: NodeGraphicConfig): Graphics | GraphicsEx {
    return config.isRound ? this.createRoundGraphic(config) : this.createSquareGraphic(config);
  }

  protected createRoundGraphic(config: NodeGraphicConfig): GraphicsEx {
    const graphic = new GraphicsEx();

    if (config.outline) {
      graphic.lineStyle(
        NodeStyle.NodeOutlineWidth,
        Colors.graphLayer[this.vehicleGroup ?? VehicleGroup.TuggerTrainDs].NodeColor,
        NodeStyle.NodeOutlineAlpha,
        NodeStyle.NodeOutlineAlignment
      );
    }

    graphic.beginFill(config.color, NodeStyle.NodeAlpha).drawCircle(0, 0, config.size).endFill();
    graphic.scale.set(NodeStyle.NodeScale);

    if (config.icon !== undefined) {
      graphic.addChild((this.icon = this.createIcon(config.icon)));
    }

    return graphic;
  }

  private createArrowGraphic(config: NodeGraphicConfig): Graphics {
    const graphic = MapPixiHelper.createArrow({
      x: 0,
      y: 0,
      orientation: 90,
      color: NodeArrowStyle.Color,
      size: NodeArrowStyle.Size,
    });

    graphic.pivot.set(
      0,
      this.config?.isRound ? NodeArrowStyle.Offset : NodeArrowStyle.SquareOffset
    );
    graphic.rotation = GeometryConverter.radianToPositiveValue(config.rotation ?? 0);

    return graphic;
  }

  private createNodeLabelGraphic(name: string): Graphics | undefined {
    const background = new Graphics();
    const text = new BitmapText(` ${name} `, MapFonts[FontName.Default]);

    if (!name) {
      return undefined;
    }

    text.align = 'center';
    text.scale.set(NodeName.scale);

    background.addChild(text);

    background
      .beginFill(NodeName.background, NodeName.alpha)
      .drawRoundedRect(
        0,
        -NodeName.padding / 2,
        text.width,
        text.height + NodeName.padding,
        NodeName.cornerRadius
      )
      .endFill();

    if (this.config?.isRound) {
      background.pivot.set(text.width / 2, text.height + NodeName.yOffset);
    } else {
      background.pivot.set(text.width / 2, text.height + NodeStyle.NodeLabelOffset);
    }

    return background;
  }

  protected createRoundGraphicSelected(): Graphics {
    if (!this.nodeGraphic) return new Graphics();

    const selection = MapPixiHelper.createSelection({
      width: NodeStyle.NodeOutlineWidth,
      size: this.config?.size ?? NodeStyle.WaypointSize,
      color: NodeStyle.NodeBorderColorSelected,
    });

    selection.visible = this.selection?.visible ?? false;

    return selection;
  }

  // #region Update
  toggleSelection(isSelected = false): void {
    if (this.selection) {
      this.selection.visible = isSelected;
    }
  }

  revertGraphic(node: GraphNode): void {
    this.createGraphic(node);
  }

  updateGraphic(node: GraphNode): void {
    this.toggleSelection(true);
    this.updateRetrackingPoint(node);
    this.createGraphic(node);

    if (this.selection?.visible) {
      this.toggleRotationMode(node.isReTrackingPoint);
    }
  }

  updateRetrackingPoint(node: GraphNode): void {
    this.node.isReTrackingPoint = node.isReTrackingPoint;
  }
  // #endregion

  // #region Rotate
  get allowRotation(): boolean {
    return this.node.isReTrackingPoint;
  }

  toggleRotationMode(enable: boolean): void {
    if (enable) this.container.showRotation(this.config?.rotation ?? 0, this.arrow);
    else this.container.clearRotation();
  }

  rotate(degrees: number): void {
    this.container.setItemAngle(degrees);
  }

  protected getRotationStyle(node: GraphNode): RotationStyle {
    return {
      ...NodeRotationStyle,
      offset: this.isRoundGraphic(node) ? NodeRotationStyle.offset : NodeRotation.SquareOffset,
    };
  }
  // #endregion
  protected abstract createSquareGraphicSelected(): Graphics;

  protected abstract createSquareGraphic(config: NodeGraphicConfig): Graphics;

  protected abstract createIcon(
    action: NodeActionTypeGroup | GraphNodeIcon,
    index?: number,
    totalActions?: number
  ): Sprite;

  protected abstract getNodeColor(node: GraphNode, vehicleGroup: VehicleGroup): number;

  protected abstract createNodePoiBackground(size?: IconBackgroundSize): Graphics;

  abstract onMapRotation(mapRotation: number): void;
}
