/* eslint-disable max-lines */
import { DateString, GuidString } from 'core/models';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: DateString;
  UUID: GuidString;
};

export const enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export type AuditLog = {
  readonly __typename?: 'AuditLog';
  readonly auditTableEventType: AuditTableEventType;
  readonly correlationIds: Scalars['String'];
  readonly createdUtc: Scalars['DateTime'];
  readonly id: Scalars['Int'];
  readonly textDe: Scalars['String'];
  readonly textEn: Scalars['String'];
  readonly userId: Scalars['String'];
  readonly workArea?: Maybe<WorkArea>;
  readonly workAreaId?: Maybe<Scalars['UUID']>;
};

/** A segment of a collection. */
export type AuditLogCollectionSegment = {
  readonly __typename?: 'AuditLogCollectionSegment';
  /** A flattened list of the items. */
  readonly items?: Maybe<ReadonlyArray<AuditLog>>;
  /** Information to aid in pagination. */
  readonly pageInfo: CollectionSegmentInfo;
  readonly totalCount: Scalars['Int'];
};

export type AuditLogFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<AuditLogFilterInput>>;
  readonly auditTableEventType?: InputMaybe<AuditTableEventTypeOperationFilterInput>;
  readonly correlationIds?: InputMaybe<StringOperationFilterInput>;
  readonly createdUtc?: InputMaybe<DateTimeOperationFilterInput>;
  readonly id?: InputMaybe<IntOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<AuditLogFilterInput>>;
  readonly textDe?: InputMaybe<StringOperationFilterInput>;
  readonly textEn?: InputMaybe<StringOperationFilterInput>;
  readonly userId?: InputMaybe<StringOperationFilterInput>;
  readonly workArea?: InputMaybe<WorkAreaFilterInput>;
  readonly workAreaId?: InputMaybe<UuidOperationFilterInput>;
};

export type AuditLogSortInput = {
  readonly auditTableEventType?: InputMaybe<SortEnumType>;
  readonly correlationIds?: InputMaybe<SortEnumType>;
  readonly createdUtc?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly textDe?: InputMaybe<SortEnumType>;
  readonly textEn?: InputMaybe<SortEnumType>;
  readonly userId?: InputMaybe<SortEnumType>;
  readonly workArea?: InputMaybe<WorkAreaSortInput>;
  readonly workAreaId?: InputMaybe<SortEnumType>;
};

export const enum AuditTableEventType {
  Device = 'DEVICE',
  EnvironmentStructure = 'ENVIRONMENT_STRUCTURE',
  EvacuationDevice = 'EVACUATION_DEVICE',
  FeatureToggleChange = 'FEATURE_TOGGLE_CHANGE',
  GraphLayer = 'GRAPH_LAYER',
  IpstAlertNowSettings = 'IPST_ALERT_NOW_SETTINGS',
  Lif = 'LIF',
  Map = 'MAP',
  Mapping = 'MAPPING',
  Mission = 'MISSION',
  MissionArchive = 'MISSION_ARCHIVE',
  NavigationLayer = 'NAVIGATION_LAYER',
  NodeBooking = 'NODE_BOOKING',
  NodeGroup = 'NODE_GROUP',
  Operation = 'OPERATION',
  Poi = 'POI',
  Processchain = 'PROCESSCHAIN',
  ProcesschainGroup = 'PROCESSCHAIN_GROUP',
  RolePermissions = 'ROLE_PERMISSIONS',
  RouteConfiguration = 'ROUTE_CONFIGURATION',
  TourChainConfiguration = 'TOUR_CHAIN_CONFIGURATION',
  TourConfiguration = 'TOUR_CONFIGURATION',
  TourExecution = 'TOUR_EXECUTION',
  Unknown = 'UNKNOWN',
  Vehicle = 'VEHICLE',
  Zone = 'ZONE',
  ZoneSet = 'ZONE_SET',
}

export type AuditTableEventTypeOperationFilterInput = {
  readonly eq?: InputMaybe<AuditTableEventType>;
  readonly in?: InputMaybe<ReadonlyArray<AuditTableEventType>>;
  readonly neq?: InputMaybe<AuditTableEventType>;
  readonly nin?: InputMaybe<ReadonlyArray<AuditTableEventType>>;
};

export type BooleanOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Boolean']>;
  readonly neq?: InputMaybe<Scalars['Boolean']>;
};

/** A segment of a collection. */
export type ChangeLogCollectionSegment = {
  readonly __typename?: 'ChangeLogCollectionSegment';
  /** A flattened list of the items. */
  readonly items?: Maybe<ReadonlyArray<AuditLog>>;
  /** Information to aid in pagination. */
  readonly pageInfo: CollectionSegmentInfo;
  readonly totalCount: Scalars['Int'];
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  readonly __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  readonly hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  readonly hasPreviousPage: Scalars['Boolean'];
};

export type DateTimeOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['DateTime']>;
  readonly gt?: InputMaybe<Scalars['DateTime']>;
  readonly gte?: InputMaybe<Scalars['DateTime']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']>>>;
  readonly lt?: InputMaybe<Scalars['DateTime']>;
  readonly lte?: InputMaybe<Scalars['DateTime']>;
  readonly neq?: InputMaybe<Scalars['DateTime']>;
  readonly ngt?: InputMaybe<Scalars['DateTime']>;
  readonly ngte?: InputMaybe<Scalars['DateTime']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['DateTime']>>>;
  readonly nlt?: InputMaybe<Scalars['DateTime']>;
  readonly nlte?: InputMaybe<Scalars['DateTime']>;
};

export type IntOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['Int']>;
  readonly gt?: InputMaybe<Scalars['Int']>;
  readonly gte?: InputMaybe<Scalars['Int']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly lt?: InputMaybe<Scalars['Int']>;
  readonly lte?: InputMaybe<Scalars['Int']>;
  readonly neq?: InputMaybe<Scalars['Int']>;
  readonly ngt?: InputMaybe<Scalars['Int']>;
  readonly ngte?: InputMaybe<Scalars['Int']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['Int']>>>;
  readonly nlt?: InputMaybe<Scalars['Int']>;
  readonly nlte?: InputMaybe<Scalars['Int']>;
};

/** A segment of a collection. */
export type IpstAlertNowAuditLogCollectionSegment = {
  readonly __typename?: 'IpstAlertNowAuditLogCollectionSegment';
  /** A flattened list of the items. */
  readonly items?: Maybe<ReadonlyArray<AuditLog>>;
  /** Information to aid in pagination. */
  readonly pageInfo: CollectionSegmentInfo;
  readonly totalCount: Scalars['Int'];
};

export type ListFilterInputTypeOfWorkAreaFilterInput = {
  readonly all?: InputMaybe<WorkAreaFilterInput>;
  readonly any?: InputMaybe<Scalars['Boolean']>;
  readonly none?: InputMaybe<WorkAreaFilterInput>;
  readonly some?: InputMaybe<WorkAreaFilterInput>;
};

export type Organization = {
  readonly __typename?: 'Organization';
  readonly description: Scalars['String'];
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly workAreas: ReadonlyArray<WorkArea>;
};

export type OrganizationFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<OrganizationFilterInput>>;
  readonly description?: InputMaybe<StringOperationFilterInput>;
  readonly forceDelete?: InputMaybe<BooleanOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<OrganizationFilterInput>>;
  readonly workAreas?: InputMaybe<ListFilterInputTypeOfWorkAreaFilterInput>;
};

export type OrganizationSortInput = {
  readonly description?: InputMaybe<SortEnumType>;
  readonly forceDelete?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
};

export type Query = {
  readonly __typename?: 'Query';
  readonly auditLog?: Maybe<AuditLogCollectionSegment>;
  readonly changeLog?: Maybe<ChangeLogCollectionSegment>;
  readonly ipstAlertNowAuditLog?: Maybe<IpstAlertNowAuditLogCollectionSegment>;
  readonly organizations: ReadonlyArray<Organization>;
};

export type QueryAuditLogArgs = {
  order?: InputMaybe<ReadonlyArray<AuditLogSortInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuditLogFilterInput>;
  workingAreaId?: InputMaybe<Scalars['UUID']>;
};

export type QueryChangeLogArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuditLogFilterInput>;
  workingAreaId: Scalars['UUID'];
};

export type QueryIpstAlertNowAuditLogArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuditLogFilterInput>;
  workingAreaId: Scalars['UUID'];
};

export type QueryOrganizationsArgs = {
  order?: InputMaybe<ReadonlyArray<OrganizationSortInput>>;
  where?: InputMaybe<OrganizationFilterInput>;
};

export const enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StringOperationFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<StringOperationFilterInput>>;
  readonly contains?: InputMaybe<Scalars['String']>;
  readonly endsWith?: InputMaybe<Scalars['String']>;
  readonly eq?: InputMaybe<Scalars['String']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly ncontains?: InputMaybe<Scalars['String']>;
  readonly nendsWith?: InputMaybe<Scalars['String']>;
  readonly neq?: InputMaybe<Scalars['String']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  readonly nstartsWith?: InputMaybe<Scalars['String']>;
  readonly or?: InputMaybe<ReadonlyArray<StringOperationFilterInput>>;
  readonly startsWith?: InputMaybe<Scalars['String']>;
};

export type UuidOperationFilterInput = {
  readonly eq?: InputMaybe<Scalars['UUID']>;
  readonly gt?: InputMaybe<Scalars['UUID']>;
  readonly gte?: InputMaybe<Scalars['UUID']>;
  readonly in?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']>>>;
  readonly lt?: InputMaybe<Scalars['UUID']>;
  readonly lte?: InputMaybe<Scalars['UUID']>;
  readonly neq?: InputMaybe<Scalars['UUID']>;
  readonly ngt?: InputMaybe<Scalars['UUID']>;
  readonly ngte?: InputMaybe<Scalars['UUID']>;
  readonly nin?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['UUID']>>>;
  readonly nlt?: InputMaybe<Scalars['UUID']>;
  readonly nlte?: InputMaybe<Scalars['UUID']>;
};

export type WorkArea = {
  readonly __typename?: 'WorkArea';
  readonly description: Scalars['String'];
  readonly id: Scalars['UUID'];
  readonly name: Scalars['String'];
  readonly organization: Organization;
};

export type WorkAreaFilterInput = {
  readonly and?: InputMaybe<ReadonlyArray<WorkAreaFilterInput>>;
  readonly description?: InputMaybe<StringOperationFilterInput>;
  readonly id?: InputMaybe<UuidOperationFilterInput>;
  readonly name?: InputMaybe<StringOperationFilterInput>;
  readonly or?: InputMaybe<ReadonlyArray<WorkAreaFilterInput>>;
  readonly organization?: InputMaybe<OrganizationFilterInput>;
  readonly organizationId?: InputMaybe<UuidOperationFilterInput>;
};

export type WorkAreaSortInput = {
  readonly description?: InputMaybe<SortEnumType>;
  readonly id?: InputMaybe<SortEnumType>;
  readonly name?: InputMaybe<SortEnumType>;
  readonly organization?: InputMaybe<OrganizationSortInput>;
  readonly organizationId?: InputMaybe<SortEnumType>;
};

export type LogPageQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AuditLogFilterInput>;
  order?: InputMaybe<SortEnumType>;
}>;

export type LogPageQuery = {
  readonly __typename?: 'Query';
  readonly auditLog?: {
    readonly __typename?: 'AuditLogCollectionSegment';
    readonly totalCount: number;
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'AuditLog';
      readonly id: number;
      readonly textEn: string;
      readonly textDe: string;
      readonly userId: string;
      readonly auditTableEventType: AuditTableEventType;
      readonly workAreaId?: GuidString | null;
      readonly createdUtc: DateString;
      readonly workArea?: {
        readonly __typename?: 'WorkArea';
        readonly name: string;
        readonly organization: { readonly __typename?: 'Organization'; readonly name: string };
      } | null;
    }> | null;
  } | null;
};

export type AuditLogEntryFragment = {
  readonly __typename?: 'AuditLog';
  readonly id: number;
  readonly textEn: string;
  readonly textDe: string;
  readonly userId: string;
  readonly auditTableEventType: AuditTableEventType;
  readonly workAreaId?: GuidString | null;
  readonly createdUtc: DateString;
  readonly workArea?: {
    readonly __typename?: 'WorkArea';
    readonly name: string;
    readonly organization: { readonly __typename?: 'Organization'; readonly name: string };
  } | null;
};

export type ChangeLogRequestQueryVariables = Exact<{
  workAreaId: Scalars['UUID'];
  offset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AuditLogFilterInput>;
}>;

export type ChangeLogRequestQuery = {
  readonly __typename?: 'Query';
  readonly changeLog?: {
    readonly __typename?: 'ChangeLogCollectionSegment';
    readonly totalCount: number;
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'AuditLog';
      readonly id: number;
      readonly textEn: string;
      readonly textDe: string;
      readonly createdUtc: DateString;
    }> | null;
  } | null;
};

export type ChangeLogEntryFragment = {
  readonly __typename?: 'AuditLog';
  readonly id: number;
  readonly textEn: string;
  readonly textDe: string;
  readonly createdUtc: DateString;
};

export type IpstAlertNowAuditLogRequestQueryVariables = Exact<{
  workAreaId: Scalars['UUID'];
  offset?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<AuditLogFilterInput>;
}>;

export type IpstAlertNowAuditLogRequestQuery = {
  readonly __typename?: 'Query';
  readonly ipstAlertNowAuditLog?: {
    readonly __typename?: 'IpstAlertNowAuditLogCollectionSegment';
    readonly totalCount: number;
    readonly items?: ReadonlyArray<{
      readonly __typename?: 'AuditLog';
      readonly id: number;
      readonly textEn: string;
      readonly textDe: string;
      readonly createdUtc: DateString;
    }> | null;
  } | null;
};

export type IpstAlertNowAuditLogEntryFragment = {
  readonly __typename?: 'AuditLog';
  readonly id: number;
  readonly textEn: string;
  readonly textDe: string;
  readonly createdUtc: DateString;
};

export const AuditLogEntryFragmentDoc = gql`
  fragment AuditLogEntry on AuditLog {
    id
    textEn
    textDe
    userId
    auditTableEventType
    workAreaId
    workArea {
      name
      organization {
        name
      }
    }
    createdUtc
  }
`;
export const ChangeLogEntryFragmentDoc = gql`
  fragment ChangeLogEntry on AuditLog {
    id
    textEn
    textDe
    createdUtc
  }
`;
export const IpstAlertNowAuditLogEntryFragmentDoc = gql`
  fragment IpstAlertNowAuditLogEntry on AuditLog {
    id
    textEn
    textDe
    createdUtc
  }
`;
export const LogPageDocument = gql`
  query logPage($offset: Int, $pageSize: Int, $filter: AuditLogFilterInput, $order: SortEnumType) {
    auditLog(skip: $offset, take: $pageSize, where: $filter, order: [{ createdUtc: $order }]) {
      totalCount
      items {
        ...AuditLogEntry
      }
    }
  }
  ${AuditLogEntryFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class LogPageGQL extends Apollo.Query<LogPageQuery, LogPageQueryVariables> {
  document = LogPageDocument;
  client = 'authClient';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChangeLogRequestDocument = gql`
  query changeLogRequest(
    $workAreaId: UUID!
    $offset: Int
    $pageSize: Int
    $filter: AuditLogFilterInput
  ) {
    changeLog(workingAreaId: $workAreaId, skip: $offset, take: $pageSize, where: $filter) {
      totalCount
      items {
        ...ChangeLogEntry
      }
    }
  }
  ${ChangeLogEntryFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class ChangeLogRequestGQL extends Apollo.Query<
  ChangeLogRequestQuery,
  ChangeLogRequestQueryVariables
> {
  document = ChangeLogRequestDocument;
  client = 'authClient';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const IpstAlertNowAuditLogRequestDocument = gql`
  query ipstAlertNowAuditLogRequest(
    $workAreaId: UUID!
    $offset: Int
    $pageSize: Int
    $filter: AuditLogFilterInput
  ) {
    ipstAlertNowAuditLog(
      workingAreaId: $workAreaId
      skip: $offset
      take: $pageSize
      where: $filter
    ) {
      totalCount
      items {
        ...IpstAlertNowAuditLogEntry
      }
    }
  }
  ${IpstAlertNowAuditLogEntryFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class IpstAlertNowAuditLogRequestGQL extends Apollo.Query<
  IpstAlertNowAuditLogRequestQuery,
  IpstAlertNowAuditLogRequestQueryVariables
> {
  document = IpstAlertNowAuditLogRequestDocument;
  client = 'authClient';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
