import { Dictionary } from '@ngrx/entity';
import { createSelector, MemoizedSelector } from '@ngrx/store';
import { DISTANT_FUTURE } from 'core/constants';
import { FleetDto, MapDto } from 'core/dtos';
import {
  GuidString,
  MissionFormat,
  MissionPanelListData,
  MissionStatus,
  MissionTrace,
  ProcessTrace,
} from 'core/models';
import * as fromMapStore from 'store-modules/maps-store/selectors/maps.selectors';
import * as fromPoiStore from 'store-modules/pois-store/selectors/pois.selectors';
import * as fromVehiclesStore from 'store-modules/vehicles-store/selectors/fleets.selectors';
import { selectAllActiveVehicles } from 'store-modules/vehicles-store/selectors/vehicles.selectors';
import { getMinutesUntilDelivery } from '../../../modules/jobs/mission-monitoring/helpers/monitoring.helper';
import { selectAllMissionTraces, selectSelectedMissionTraceId } from './mission-trace.selectors';
import { convertToProcessTrace } from './mission-trace.selectors.helpers';

export const selectSelectedMissionTrace = createSelector(
  selectSelectedMissionTraceId,
  selectAllMissionTraces,
  (missionTraceId, missionTraces) => missionTraces.filter(m => m.id === missionTraceId)[0]
);

export const selectSelectedProcessTrace = createSelector(
  selectAllMissionTraces,
  selectSelectedMissionTrace,
  fromMapStore.selectMapsEntities,
  fromVehiclesStore.selectFleetEntities,
  fromPoiStore.selectPoiEntities,
  (allMissionTraces, selectedMissionTrace, mapEntities, fleetEntities) => {
    return selectedMissionTrace
      ? convertToProcessTrace(allMissionTraces, selectedMissionTrace, mapEntities, fleetEntities)
      : undefined;
  }
);

export const selectSelectedProcessTraceWithMissionTrace = (
  selectedMissionTrace: MissionTrace
): MemoizedSelector<object, ProcessTrace | undefined> =>
  createSelector(
    fromMapStore.selectMapsEntities,
    fromVehiclesStore.selectFleetEntities,
    fromPoiStore.selectPoiEntities,
    (mapEntities: Dictionary<MapDto>, fleetEntities: Dictionary<FleetDto>) => {
      return selectedMissionTrace
        ? convertToProcessTrace([], selectedMissionTrace, mapEntities, fleetEntities)
        : undefined;
    }
  );

// TODO SERVATS-38029 Remove old endpoints
export const selectActiveMissions = createSelector(
  selectAllMissionTraces,
  (allActiveMissionTraces: MissionTrace[]) =>
    allActiveMissionTraces.filter(
      mission =>
        mission.status === MissionStatus.InProgress ||
        mission.status === MissionStatus.Failed ||
        mission.status === MissionStatus.Interrupted ||
        mission.status === MissionStatus.InterruptionFailed
    )
);
export const selectActiveMissionsByMapId = (
  mapId: GuidString
): MemoizedSelector<object, MissionTrace[]> =>
  createSelector(selectActiveMissions, (allActiveMissionTraces: MissionTrace[]) => {
    return allActiveMissionTraces.filter(m => m.mapId === mapId);
  });

export const selectMissionTracesByProcessChainTraceId = (
  processChainTraceId: GuidString
): MemoizedSelector<object, MissionTrace[]> =>
  createSelector(selectAllMissionTraces, (allActiveMissionTraces: MissionTrace[]) => {
    return allActiveMissionTraces.filter(m => m.processChainTraceId === processChainTraceId);
  });

export const selectToursByTourChainId = (
  tourChainId: GuidString
): MemoizedSelector<object, MissionTrace[]> =>
  createSelector(selectAllMissionTraces, (allActiveMissionTraces: MissionTrace[]) => {
    return allActiveMissionTraces.filter(m => m.tourChainId === tourChainId);
  });

export const selectFailedMissionTracesCount = createSelector(
  selectActiveMissions,
  activeMissions => {
    return activeMissions.filter(m => m.status === MissionStatus.Failed).length;
  }
);

export const selectMissionPanelData = createSelector(
  selectAllMissionTraces,
  selectAllActiveVehicles,
  fromMapStore.selectSelectedMap,
  (allMissionTraces, vehicles, selectedMap): MissionPanelListData[] => {
    if (selectedMap) {
      const distantFuture = new Date(DISTANT_FUTURE);

      return allMissionTraces
        .filter(m => m.mapId === selectedMap.id || m.missionFormat === MissionFormat.VDA5050)
        .sort((a, b) => {
          const dateA = a.createdDateTime ? new Date(a.createdDateTime) : distantFuture;
          const dateB = b.createdDateTime ? new Date(b.createdDateTime) : distantFuture;
          return dateA.getTime() - dateB.getTime();
        })
        .sort((a, b) => {
          const dateA = a.provisioningTime ? new Date(a.provisioningTime) : distantFuture;
          const dateB = b.provisioningTime ? new Date(b.provisioningTime) : distantFuture;
          return dateA.getTime() - dateB.getTime();
        })
        .sort((a, b) => {
          return (
            Number(b.status === MissionStatus.Failed) - Number(a.status === MissionStatus.Failed)
          );
        })
        .map(missionTrace => {
          const minutesUntilDelivery = getMinutesUntilDelivery(
            missionTrace.provisioningTime?.toString()
          );
          const vehicle = vehicles.find(v => v.id === missionTrace.vehicleId);

          return {
            missionTraceId: missionTrace.id,
            missionStatus: missionTrace.status,
            isFinalState: [
              MissionStatus.Aborted,
              MissionStatus.Completed,
              MissionStatus.CompletedWithSupport,
            ].includes(missionTrace.status),
            missionName: missionTrace.missionName,
            missionId: missionTrace.missionId,
            processChainTraceId: missionTrace.processChainTraceId,
            minutesUntilDelivery: minutesUntilDelivery,
            deliveryStatus: missionTrace.deliveryStatus,
            vehicleId: missionTrace.vehicleId?.toString(),
            vehicleName: missionTrace.vehicleName,
            vehicleStatus: vehicle?.status,
            assignmentDelayEndDateTime: missionTrace.assignmentDelayEndDateTime,
            missionFormat: missionTrace.missionFormat,
            failureComment: missionTrace.failureComment,
          };
        });
    } else {
      return [];
    }
  }
);
