/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import {
  ActionBlockingType,
  ActionDto,
  ActionGroupDto,
  ActionRequirementType,
  EdgeDto,
  EdgeTrajectory,
  LayoutDto,
  LifUpdatePreview,
  NodeActionType,
  NodeActionTypeGroup,
  NodeDto,
  NodeGroupDto,
  NurbDto,
  PsaNodeMappingDto,
  RuleDto,
  SwitchEdgeDto,
  SwitchNodeDto,
  TourConfigurationDto,
  TourStepConfigurationDto,
  TourStepType,
} from 'core/dtos';

import { EMPTY_GUID } from 'core/constants';
import {
  EdgeSegment,
  GraphEdge,
  GraphParkingChargingType,
  GuidString,
  LifValidationStatus,
  MapItem,
  MapItemType,
  NodeGroupType,
  NodeOccupancyStatus,
  RuleType,
  SelectedMapItem,
  VehicleType,
} from 'core/models';
import { map1Id } from './maps-mock-data';
import { NavigationLayerMockData } from './navigation-layers-mock-data';

export const graph = {
  mapId1: 'map1',
  navigationLayerId1: 'nl1',

  node0Id: 'n0',
  node1Id: 'n1',
  node2Id: 'n2',
  node3Id: 'n3',
  node4Id: 'n4',

  edge0Id: 'e0',
  edge1Id: 'e1',
  edge2Id: 'e2',
};

const graphThirdNode: NodeDto = {
  nodeId: graph.node3Id,
  nodeName: 'Node 3',
  map: { id: map1Id, navigationLayerId: EMPTY_GUID },
  nodePosition: { x: 5, y: 5, orientation: 0 },
  parkingChargingType: GraphParkingChargingType.None,
  isReTrackingPoint: false,
  reTrackingOrientation: 0,
};

const nodeGroup: NodeGroupDto = {
  id: 'eca1e06f-4ead-4301-becd-23167dffd522',
  name: 'NodeGroup 1',
  workAreaId: '4cd7dd79-59e6-4222-a1fb-4219540df843',
  nextNodeSequenceId: 2,
  vehicleType: VehicleType.TuggerTrain,
  nodes: ['6b9ec8d7-6c14-493f-b7e6-dac7a1216b46', 'd4fe388a-899b-41f6-aae9-518827e6bd07'],
  nodeGroupType: NodeGroupType.Alternating,
  decisionPoints: ['decisionPoint 1'],
};

const tourStepConfiguration: TourStepConfigurationDto = {
  height: 2,
  sequenceId: 2,
  stepType: TourStepType.Drop,
  raiseEventId: null,
};

const tourConfiguration: TourConfigurationDto = {
  id: '75661940-88fc-45a4-a74f-5a10bcafc84d',
  name: 'Tour Configuration',
  routeConfigurationId: '4a8944b0-9329-4779-a30a-bfd29900aabb',
  steps: [tourStepConfiguration],
  assignableNodeId: '453e42f0-eb0b-4abc-a4dc-d4bfdc3f983b',
  fuelingConfiguration: null,
  pauseConditions: [
    {
      device: 'Device 1',
      nodeName: 'nodeName 1',
      value: 'value 1',
      stepNumber: 3,
    },
  ],
};

const psaNodeMapping: PsaNodeMappingDto = {
  id: 'e77ce840-fef0-41eb-91cc-f808bdff0245',
  psa: 'psa 1',
  nodeId: 'node 1',
  tourConfigurationId: '101ae713-30a3-4f63-bf72-00b88c7fefc5',
  workAreaId: 'd4f4a9fe-88fb-48fd-b060-67d9ad2e937f',
};

const switchEdge: SwitchEdgeDto = {
  edgeId: 'edgeId 1',
};

const switchNode: SwitchNodeDto = {
  nodeId: 'nodeId 1',
  edges: [
    {
      edgeId: 'edgeId 1',
    },
  ],
  previousSegment: undefined,
};

const rule: RuleDto = {
  id: '3d8d1f79-29f3-4454-827b-ca560a2c0638',
  stopNodeId: '91484cfb-f142-4b2a-8a84-e82d7e98c7ac',
  switchNodeId: '9db1a492-c88c-4724-9300-01f52864da9d',
  edgeId: '06161cdc-b5c5-4d34-abd1-09c2ececed2f',
  ruleType: RuleType.Overtaking,
  intersectionZoneId: '99ed1cbd-cef5-4c01-9383-feb688429fca',
};

const node0: NodeDto = {
  nodeId: graph.node0Id,
  nodeName: 'Node 0',
  map: { id: graph.mapId1, navigationLayerId: graph.navigationLayerId1 },
  nodePosition: { x: 0, y: 0, orientation: 0 },
  parkingChargingType: GraphParkingChargingType.None,
  isReTrackingPoint: false,
  reTrackingOrientation: 0,
};

const node1: NodeDto = {
  nodeId: graph.node1Id,
  nodeName: 'Node 1',
  map: { id: graph.mapId1, navigationLayerId: graph.navigationLayerId1 },
  nodePosition: { x: 0, y: 0, orientation: 0 },
  parkingChargingType: GraphParkingChargingType.None,
  isReTrackingPoint: false,
  reTrackingOrientation: 0,
};

const node2: NodeDto = {
  nodeId: graph.node2Id,
  nodeName: 'Node 2',
  map: { id: graph.mapId1, navigationLayerId: graph.navigationLayerId1 },
  nodePosition: { x: 2, y: 2, orientation: 90 },
  parkingChargingType: GraphParkingChargingType.None,
  isReTrackingPoint: false,
  reTrackingOrientation: 0,
};

const node3: NodeDto = {
  nodeId: graph.node3Id,
  nodeName: 'Node 3',
  map: { id: graph.mapId1, navigationLayerId: graph.navigationLayerId1 },
  nodePosition: { x: 2, y: 2, orientation: 180 },
  parkingChargingType: GraphParkingChargingType.None,
  isReTrackingPoint: true,
  reTrackingOrientation: 0,
};

const node4: NodeDto = {
  nodeId: graph.node4Id,
  nodeName: 'Node 4',
  map: { id: graph.mapId1, navigationLayerId: graph.navigationLayerId1 },
  nodePosition: { x: 3, y: 3, orientation: 180 },
  parkingChargingType: GraphParkingChargingType.Charging,
  isReTrackingPoint: true,
  reTrackingOrientation: 0,
  occupancy: {
    status: NodeOccupancyStatus.Occupied,
    vehicleName: 'Vehicle 1',
    statusUpdateTime: '2024-05-12',
    vehicleId: 'vehicleId',
  },
};

const edge0: EdgeDto = {
  edgeId: graph.edge0Id,
  edgeName: 'Edge 0',
  startNodeId: node0.nodeId,
  endNodeId: node1.nodeId,
};

const edge1: EdgeDto = {
  edgeId: graph.edge1Id,
  edgeName: 'Edge 1',
  startNodeId: node1.nodeId,
  endNodeId: node2.nodeId,
};

const edge2: EdgeDto = {
  edgeId: graph.edge2Id,
  edgeName: 'Edge 2',
  startNodeId: graph.node1Id,
  endNodeId: graph.node3Id,
};

const graphLayer1: LayoutDto = {
  id: 'layout1',
  mapId: graph.mapId1,
  navigationLayerId: graph.navigationLayerId1,
  nodes: [node1, node2],
  edges: [edge1],
};

const graphLayer2: LayoutDto = {
  id: 'layout2',
  mapId: graph.mapId1,
  navigationLayerId: NavigationLayerMockData.getNavigationLayer2().id,
  nodes: [node1, node2],
  edges: [edge1],
};

const graphLayer3: LayoutDto = {
  id: 'layout3',
  mapId: graph.mapId1,
  navigationLayerId: graph.navigationLayerId1,
  nodes: [node1, node2, node3],
  edges: [edge1, edge2],
};

const graphLayer4: LayoutDto = {
  id: 'layout4',
  mapId: graph.mapId1,
  navigationLayerId: graph.navigationLayerId1,
  nodes: [node1, node2, node3, node4],
  edges: [edge1, edge2],
};

const nurbEdge: EdgeDto = {
  edgeId: 'n_e1',
  edgeName: 'NurbEdge 1',
  startNodeId: graph.node1Id,
  endNodeId: graph.node2Id,
  trajectory: {
    controlPoints: [
      { x: 19, y: 19, weight: 0 },
      { x: 18, y: 18, weight: 0 },
    ],
    degree: 1,
    knotVector: [1, 9, 1, 9],
  },
};

const trajectory: EdgeTrajectory = {
  controlPoints: [
    { x: 19, y: 19, weight: 0 },
    { x: 18, y: 18, weight: 0 },
  ],
  degree: 1,
  knotVector: [1, 9, 1, 9],
};

const nurb: NurbDto = {
  startNode: {
    nodeId: 'n1',
    nodeName: 'Node 1',
    map: {
      id: 'bb2b55bc-dd94-4262-a5af-966431d57b31',
      navigationLayerId: '00000000-0000-0000-0000-000000000000',
    },
    nodePosition: {
      x: 0,
      y: 0,
      orientation: 0,
    },
    parkingChargingType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
  },
  endNode: {
    nodeId: 'n2',
    nodeName: 'Node 2',
    map: {
      id: 'bb2b55bc-dd94-4262-a5af-966431d57b31',
      navigationLayerId: '00000000-0000-0000-0000-000000000000',
    },
    nodePosition: {
      x: 2,
      y: 2,
      orientation: 0,
    },
    parkingChargingType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
  },
  // edgeDto: {
  //   edgeId: 'e1',
  //   edgeName: 'Edge 1',
  //   startNodeId: 'n1',
  //   endNodeId: 'n2',
  //   trajectory: {
  //     controlPoints: [
  //       {
  //         x: 19,
  //         y: 19,
  //         weight: 0,
  //       },
  //       {
  //         x: 18,
  //         y: 18,
  //         weight: 0,
  //       },
  //     ],
  //     degree: 1,
  //     knotVector: [1, 9, 1, 9],
  //   },
  // },
  points: [
    [19, 19],
    [18, 18],
  ],
  knots: [1, 9, 1, 9],
  weights: [0, 0],
  degree: 1,
};

const nurb2: NurbDto = {
  startNode: {
    nodeId: 'N_6_84DFD2A8-0DA9-4B2B-9F8C-119C9F0670A4',
    nodeName: 'Node_6',
    map: {
      id: '1dfb48b5-725b-4e70-a024-9bea579b596a',
      navigationLayerId: '5dc1d7ad-353c-4d39-8f1b-50e2bfd0d872',
    },
    parkingChargingType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,

    nodePosition: {
      x: 671.08024,
      y: 130.70173999999997,
      orientation: 0,
    },
    hasRule: false,
  },
  endNode: {
    nodeId: 'N_443_84DFD2A8-0DA9-4B2B-9F8C-119C9F0670A4',
    nodeName: 'Node_443',
    map: {
      id: '1dfb48b5-725b-4e70-a024-9bea579b596a',
      navigationLayerId: '5dc1d7ad-353c-4d39-8f1b-50e2bfd0d872',
    },
    parkingChargingType: 0,
    isReTrackingPoint: false,
    reTrackingOrientation: 0,
    nodePosition: {
      x: 671.08024,
      y: 131.2757200000001,
      orientation: 0,
    },
    hasRule: false,
  },
  // edgeDto: {
  //   startNodeId: 'N_6_84DFD2A8-0DA9-4B2B-9F8C-119C9F0670A4',
  //   endNodeId: 'N_443_84DFD2A8-0DA9-4B2B-9F8C-119C9F0670A4',
  //   edgeId: 'E_9_84DFD2A8-0DA9-4B2B-9F8C-119C9F0670A4',
  //   edgeName: 'Edge_9',
  //   trajectory: {
  //     degree: 1,
  //     knotVector: [0, 0, 1, 1],
  //     controlPoints: [
  //       {
  //         x: 671.08024,
  //         y: 130.70173999999997,
  //         weight: 0,
  //       },
  //       {
  //         x: 671.08024,
  //         y: 131.2757200000001,
  //         weight: 0,
  //       },
  //     ],
  //   },
  // },
  points: [
    [671.08024, 130.70173999999997],
    [671.08024, 131.2757200000001],
  ],
  knots: [0, 0, 1, 1],
  weights: [1, 1],
  degree: 1,
};

const groupedActions: ActionGroupDto[] = [
  {
    actionType: NodeActionType.Drop,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Hard,
    requirementType: ActionRequirementType.Conditional,
    actionParameters: [],
    isGrouped: true,
    group: NodeActionTypeGroup.PickDrop,
  },
  {
    actionType: NodeActionType.WaitForTrigger,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Soft,
    requirementType: ActionRequirementType.Required,
    actionParameters: [],
    isGrouped: false,
    group: NodeActionTypeGroup.WaitForTrigger,
  },
];

const unsortedGroupedActions: ActionGroupDto[] = [
  {
    actionType: NodeActionType.WaitForTrigger,
    actionDescription: 'description',
    blockingType: 1,
    requirementType: 0,
    actionParameters: [],
    group: NodeActionTypeGroup.WaitForTrigger,
    isGrouped: false,
  },
  {
    actionType: NodeActionType.Drop,
    actionDescription: 'description',
    blockingType: 2,
    requirementType: 1,
    actionParameters: [],
    group: NodeActionTypeGroup.PickDrop,
    isGrouped: true,
  },
];

const pickDropWaitActions: ActionDto[] = [
  {
    actionType: NodeActionType.WaitForTrigger,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Soft,
    requirementType: ActionRequirementType.Required,
    actionParameters: [],
  },
  {
    actionType: NodeActionType.Drop,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Hard,
    requirementType: ActionRequirementType.Conditional,
    actionParameters: [],
  },
  {
    actionType: NodeActionType.Pick,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Hard,
    requirementType: ActionRequirementType.Conditional,
    actionParameters: [],
  },
  {
    actionType: NodeActionType.Pick,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Hard,
    requirementType: ActionRequirementType.Conditional,
    actionParameters: [],
  },
];

const pickDropActions: ActionDto[] = [
  {
    actionType: NodeActionType.Drop,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Hard,
    requirementType: ActionRequirementType.Conditional,
    actionParameters: [],
  },
  {
    actionType: NodeActionType.Pick,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Hard,
    requirementType: ActionRequirementType.Optional,
    actionParameters: [],
  },
];

const dropWaitActions: ActionDto[] = [
  {
    actionType: NodeActionType.Drop,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Hard,
    requirementType: ActionRequirementType.Conditional,
    actionParameters: [],
  },
  {
    actionType: NodeActionType.WaitForTrigger,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Hard,
    requirementType: ActionRequirementType.Conditional,
    actionParameters: [],
  },
];

const dropAction: ActionDto[] = [
  {
    actionType: NodeActionType.Drop,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Hard,
    requirementType: ActionRequirementType.Conditional,
    actionParameters: [],
  },
];

const startStopChargingActions: ActionDto[] = [
  {
    actionType: NodeActionType.StartCharging,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Hard,
    requirementType: ActionRequirementType.Conditional,
    actionParameters: [],
  },
  {
    actionType: NodeActionType.StopCharging,
    actionDescription: 'description',
    blockingType: ActionBlockingType.Hard,
    requirementType: ActionRequirementType.Conditional,
    actionParameters: [],
  },
];

const sortedActions: NodeActionTypeGroup[] = [
  NodeActionTypeGroup.PickDrop,
  NodeActionTypeGroup.WaitForTrigger,
];

const singleActionTypeGroup: NodeActionTypeGroup[] = [NodeActionTypeGroup.PickDrop];

const singleChargingActionTypeGroup: NodeActionTypeGroup[] = [
  NodeActionTypeGroup.StartStopCharging,
];

const multiActionTypeGroup: NodeActionTypeGroup[] = [
  NodeActionTypeGroup.PickDrop,
  NodeActionTypeGroup.WaitForTrigger,
];

export class LifMockData {
  static getMockData(mapId: GuidString): LayoutDto[] {
    const nodes: NodeDto[] = [];
    let edges: EdgeDto[] = [];
    const xFactor = 35;
    const yFactor = 40;

    for (let i = 0; i < 11; i++) {
      nodes.push({
        nodeId: `n_${i.toString() as GuidString}`,
        nodeName: `Node ${i}`,
        nodeDescription: '',
        map: { id: mapId, navigationLayerId: EMPTY_GUID },
        nodePosition: { x: i * xFactor, y: i * yFactor, orientation: 0 },
        parkingChargingType: GraphParkingChargingType.None,
        isReTrackingPoint: false,
        reTrackingOrientation: 0,
      });
    }

    for (let i = 1; i < nodes.length; i++) {
      edges.push({
        edgeId: `e_${i.toString() as GuidString}`,
        edgeName: `Edge ${i}`,
        startNodeId: nodes[i - 1].nodeId,
        endNodeId: nodes[i].nodeId,
      });
    }

    // Randomize order
    edges = edges.sort(() => Math.random() - 0.5);

    // Add split segment on same Map
    const segmentNode1 = {
      nodeId: 'n_segment_1',
      nodeName: 'Node Segment 1',
      nodeDescription: '',
      map: { id: mapId, navigationLayerId: EMPTY_GUID },
      nodePosition: { x: 2 * xFactor, y: 4 * yFactor, orientation: 0 },
      parkingChargingType: GraphParkingChargingType.None,
      isReTrackingPoint: false,
      reTrackingOrientation: 0,
    };
    const segmentNode2 = {
      nodeId: 'n_segment_1',
      nodeName: 'Node Segment 1',
      nodeDescription: '',
      map: { id: mapId, navigationLayerId: EMPTY_GUID },
      nodePosition: { x: 3 * xFactor, y: 6 * yFactor, orientation: 0 },
      parkingChargingType: GraphParkingChargingType.None,
      isReTrackingPoint: false,
      reTrackingOrientation: 0,
    };

    nodes.push(...[segmentNode1, segmentNode2]);

    edges.push(
      ...[
        {
          edgeId: 'e_split_1',
          edgeName: 'Edge Segment 1',
          startNodeId: nodes[3].nodeId,
          endNodeId: segmentNode1.nodeId,
        },
        {
          edgeId: 'e_split_2',
          edgeName: 'Edge Segment 2',
          startNodeId: segmentNode1.nodeId,
          endNodeId: nodes[6].nodeId,
        },
      ]
    );

    // Add return to previous node
    const roundtripNode1 = {
      nodeId: 'n_round_1',
      nodeName: 'Node Round 1',
      nodeDescription: '',
      map: { id: mapId, navigationLayerId: EMPTY_GUID },
      nodePosition: { x: 10 * xFactor, y: 3 * yFactor, orientation: 0 },
      parkingChargingType: GraphParkingChargingType.None,
      isReTrackingPoint: false,
      reTrackingOrientation: 0,
    };
    nodes.push(...[roundtripNode1]);
    edges.push(
      ...[
        {
          edgeId: 'e_round_1',
          edgeName: 'Edge Round 1',
          startNodeId: nodes[9].nodeId,
          endNodeId: roundtripNode1.nodeId,
        },
        {
          edgeId: 'e_round_2',
          edgeName: 'Edge Round 2',
          startNodeId: roundtripNode1.nodeId,
          endNodeId: nodes[2].nodeId,
        },
      ]
    );

    return [
      {
        id: 'layout123',
        mapId: EMPTY_GUID,
        navigationLayerId: EMPTY_GUID,
        nodes,
        edges,
      },
    ];
  }

  static getSingleMockData(mapId: GuidString): LayoutDto {
    return this.getMockData(mapId)[0];
  }

  static getGraphLayout(): LayoutDto {
    return graphLayer1;
  }

  static getGraphLayout2(): LayoutDto {
    return graphLayer2;
  }

  static getGraphLayers(): LayoutDto[] {
    return [graphLayer1];
  }

  static getMultiGraphLayers(): LayoutDto[] {
    return [graphLayer1, graphLayer3, graphLayer4];
  }

  static getLayoutWithSwitchNodes(): LayoutDto {
    return graphLayer3;
  }

  static getLayoutWithChargingNode(): LayoutDto {
    return graphLayer4;
  }

  static getLayoutsWithChargingNode(): LayoutDto[] {
    return [graphLayer4];
  }

  static getNodes(): NodeDto[] {
    return graphLayer1.nodes;
  }

  static getNode1(): NodeDto {
    return node1;
  }

  static getNode2(): NodeDto {
    return node2;
  }

  static getNode3(): NodeDto {
    return node3;
  }

  static getNodeList(): NodeDto[] {
    return [node1, node2, node3];
  }

  static getEdgeList(): EdgeDto[] {
    return [edge1, edge2];
  }

  static getEdge1(): EdgeDto {
    return edge1;
  }

  static getEdge2(): EdgeDto {
    return edge2;
  }

  static getNurbEdge(): EdgeDto {
    return nurbEdge;
  }

  static getNurb(): NurbDto {
    return nurb;
  }

  static getNurb2(): NurbDto {
    return nurb2;
  }

  static getTrajectory(): EdgeTrajectory {
    return trajectory;
  }

  /*
      n1 ->--- n2
      |
      e2
      |
      n3
  */
  static getGraphLayersWithSplit(): LayoutDto[] {
    const layer = { ...graphLayer1 };

    return [{ ...layer, nodes: [...layer.nodes, node3], edges: [...layer.edges, edge2] }];
  }

  /*
    n0 ->-- n1 -->-- n2
            |
            |
            n3
  */
  static getGraphLayerWithSwitchPoint(): LayoutDto[] {
    const layout = this.getGraphLayersWithSplit()[0];

    return [{ ...layout, nodes: [...layout.nodes, node0], edges: [...layout.edges, edge0] }];
  }

  static getGraphLayersWithMultiEdges(): LayoutDto[] {
    const layer = { ...graphLayer1 };

    const secondEdge: EdgeDto = {
      edgeId: graph.edge2Id,
      edgeName: 'Split Edge',
      startNodeId: graph.node2Id,
      endNodeId: graphThirdNode.nodeId,
    };

    return [
      { ...layer, nodes: [...layer.nodes, graphThirdNode], edges: [...layer.edges, secondEdge] },
    ];
  }

  static getEdgeSegment(): EdgeSegment {
    const edgeSegment: EdgeSegment = {
      startEdgeId: 'startEdgeId',
      positions: [{ x: 5, y: 5, orientation: 0 }],
      startNodeId: graph.node2Id,
      endNodeId: graphThirdNode.nodeId,
    };

    return edgeSegment;
  }

  static getNode(): NodeDto {
    return node1;
  }

  static getGraphEdge(): GraphEdge {
    const edge = graphLayer1.edges[0];
    const startNode = graphLayer1.nodes[0];
    const endNode = graphLayer1.nodes[1];

    return {
      ...edge,
      startPosition: startNode.nodePosition,
      endPosition: endNode.nodePosition,
      isIncluded: true,
    };
  }

  static getEdgeTrajectory(): EdgeTrajectory {
    return {
      controlPoints: [
        { x: 1.2, y: 1.2, weight: 1 },
        { x: 1.8, y: 3, weight: 1 },
        { x: 2.2, y: 0.7, weight: 1 },
        { x: 4, y: 4, weight: 1 },
      ],
      degree: 3,
      knotVector: [0, 0, 0, 0, 1, 1, 1, 1],
    };
  }

  static getNodeAsSelectedMapItem(): SelectedMapItem<MapItem> {
    return {
      isFocused: false,
      item: this.getNodeMapItem(),
    };
  }

  static getNodeMapItem(): MapItem {
    const node = LifMockData.getNode();
    return {
      id: node.nodeId.toString(),
      position: node.nodePosition,
      type: MapItemType.Node,
    };
  }

  static getUpdateLifPreview(): LifUpdatePreview {
    return {
      addedNodes: this.getNodeList(),
      addedEdges: this.getEdgeList(),
      affectedNodeGroups: [nodeGroup],
      affectedTours: [tourConfiguration],
      affectedPsaMappings: [psaNodeMapping],
      affectedRoutes: [
        {
          affectedSwitchEdges: [switchEdge],
          affectedSwitchNodes: [switchNode],
          affectedRuleNodes: [rule],
        },
      ],
      validationStatus: LifValidationStatus.NoConflicts,
      removedEdges: [],
      removedNodes: [],
      updatedNodes: [],
      updatedEdges: [],
      navigationLayerId: 'ddad0ef8-dbc3-48b7-be45-f5f323892898',
    };
  }

  static getGroupedActions(): ActionGroupDto[] {
    return [...groupedActions];
  }

  static getUnsortedGroupedActions(): ActionGroupDto[] {
    return [...unsortedGroupedActions];
  }

  static getPickDropWaitActions(): ActionDto[] {
    return [...pickDropWaitActions];
  }

  static getPickDropActions(): ActionDto[] {
    return [...pickDropActions];
  }

  static getDropAction(): ActionDto[] {
    return [...dropAction];
  }

  static getDropWaitActions(): ActionDto[] {
    return [...dropWaitActions];
  }

  static getStartStopChargingActions(): ActionDto[] {
    return [...startStopChargingActions];
  }

  static getSortedActions(): NodeActionTypeGroup[] {
    return [...sortedActions];
  }

  static getSingleActionGroup(): NodeActionTypeGroup[] {
    return [...singleActionTypeGroup];
  }

  static getSingleChargingActionGroup(): NodeActionTypeGroup[] {
    return [...singleChargingActionTypeGroup];
  }

  static getMultiActionGroup(): NodeActionTypeGroup[] {
    return [...multiActionTypeGroup];
  }
}
