import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngrx/store';
import { API_SERVICES } from 'core/constants';
import { ServiceZoneWithPoisDto } from 'core/dtos';
import { TenantHttpClient } from 'core/http/tenant-http-client';
import { GuidString } from 'core/models';
import { firstValueFrom } from 'rxjs';
import { RootState } from 'store/reducers';

@Injectable({
  providedIn: 'root',
})
export class ServiceZoneService extends TenantHttpClient {
  protected apiUrl = environment.Services.JobManager;
  private readonly servicePath = API_SERVICES.ServiceZones;

  constructor(httpClient: HttpClient, store: Store<RootState>) {
    super(httpClient, store);
  }

  async getServiceZonesByMapId(mapId: GuidString): Promise<ServiceZoneWithPoisDto[]> {
    return firstValueFrom(this.get<ServiceZoneWithPoisDto[]>(`${this.servicePath}/map/${mapId}`));
  }
}
