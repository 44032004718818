export enum CollisionCase {
  Undefined = 0,
  DrivingBackwards = 2,
  OnlyOneVehicleIsCrossingStoppingAreaOfAnotherVehicle = 3,
  DistanceToCollision = 4,
  Random = 5,
  BothVehiclesInsideInteractionZone = 6,
  SimpleSwap = 7,
  ComplexSwap = 8,
  ConvoySituation = 9,
  Ignore = 10,
  PreferredDirectionViolation = 11,
  WaitingForEvent = 12,
  PathEndsInPose = 13,
}

export enum ReleaseType {
  NotReleased = 0,
  Obsolete = 1,
  HeadsOnCollision = 2,
  ResumedVehicleInErrorState = 3,
  ResumedVehicleDisconnected = 4,
  PausedVehicleIsBlockingInteractionZone = 5,
  ResumedVehicleIsStationary = 6,
  ResumedVehicleIsInManualMode = 7,
  BothVehiclesAreInNoReplanningZone = 8,
  StaleStartupCollision = 9,
  StaleDistanceCollision = 10,
}
