import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { API_SERVICES } from 'core/constants';
import { BaseHttpClient } from 'core/http/base-http-client';
import { GuidString } from 'core/models';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssignableWaypointService extends BaseHttpClient {
  private readonly servicePath = API_SERVICES.AssignableWaypoints;

  protected apiUrl = environment.Services.JobManager;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  async getAssignableWaypointLogs(poiId: GuidString): Promise<{ preformattedResult: string[] }> {
    return firstValueFrom(
      this.get<{ preformattedResult: string[] }>(`${this.servicePath}/${poiId}`)
    );
  }

  async forceAssignment(poiId: GuidString): Promise<void> {
    return firstValueFrom(this.get<void>(`${this.servicePath}/ForceAssignment/${poiId}`));
  }
}
